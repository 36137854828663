import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import TopBar from '../../components/TopBar';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Cookies } from 'react-cookie';
import imageConfigEventos from "../../images/configuracoes-eventos.png"
import imageConfigEventosGrec from "../../images/configuracoes-eventos-grec.png"
import imageConfigAssociacaoBancos from "../../images/configuracoes-associacoes-de-bancos.png"
import imageConfigAssociacaoContas from "../../images/configuracoes-associacoes-de-contas.png"
import 'react-toastify/dist/ReactToastify.css';

// const cookies = new Cookies()
// const tknAccess = cookies.get('tkn-access')

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // height: '80vh',
    // display: 'flex',
    // alignItems: 'center',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: '25%',
    overflow: 'scroll',
    width: '90vw',
    display: 'block',
  },
  fixedHeight: {
    height: '300px',
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#65c4d5',
  },
  rootform: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  btnprint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  btnpdf: {
    marginRight: '10px',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '10px',
    width: '25ch',
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  positive: {
    color: '#00ff00',
  },
  grid: {
    // marginBottom: '20px',
    // marginTop: '180px'
    // marginTop: "10%"
  },
  imagem: {
    width: '100%',
  },
  corMonitor: {
    background: theme.palette.type === 'dark'
    ? theme.palette.background.dark
      : theme.palette.background.default,
    borderRadius: '20px',
    transition: '0.5s ease',
    '&:hover': {
      background: theme.palette.primary.light,
    },
    boxShadow: theme.palette.type === "dark" ? '0 22.8px 22.2px rgba(255, 255, 255, 0.034)':
    '0 22.8px 22.2px rgba(0, 0, 0, 0.034)',
  },
  corMonitorDisabled: {
    background:  theme.palette.type === 'dark'
    ? theme.palette.background.dark
      : theme.palette.background.default,
    boxShadow: theme.palette.type === "dark" ? '0 22.8px 22.2px rgba(255, 255, 255, 0.034)':
    '0 22.8px 22.2px rgba(0, 0, 0, 0.034)',
  },
}));

export default function Backoffice() {
  const classes = useStyles();
  const cookies = new Cookies();

  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput.id);
  const permissionUser = cookies.get('roles');
  const date = new Date();

  function mudarCorMonitor() {
    document.getElementById('cardMonitor').addClass = 'novaCorMonitor';
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.bread}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/backoffice/new-backoffice">
              Home
            </Link>
            <Typography color="textPrimary">Configurações</Typography>
          </Breadcrumbs>
        </div>
        <Container maxWidth="lg" className={classes.container}>
          {/* <ToastContainer/> */}
          <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} md={2}></Grid>
            {permissionUser == 'backoffice-admin' ? (
              <Grid item xs={12} md={4}>
                <Link color="inherit" href="/backoffice/eventos-lista">
                  <Card className={classes.corMonitor}>
                    <CardContent style={{ textAlign: 'center' }}>
                      <img
                        style={{ width: '128px' }}
                        src={imageConfigEventos}
                        alt="monitor512"
                        border="0"
                      />
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        Eventos
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ) : (
              <Grid item xs={12} md={4}>
                <Card className={classes.corMonitorDisabled}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <img
                      style={{ width: '128px', opacity: '0.4' }}
                      src={imageConfigEventosGrec}
                      alt="monitor512"
                      border="0"
                    />
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        opacity: '0.4',
                      }}
                    >
                      Eventos
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <Link color="inherit" href="/backoffice/bancos-lista">
                <Card className={classes.corMonitor}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <img
                      style={{ width: '128px' }}
                      src={imageConfigEventosGrec}
                      className="imagem"
                      alt="esteira512"
                      border="0"
                    />

                    <Typography
                      variant="h5"
                      color="textSecondary"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      Bancos
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} md={2}></Grid>
          </Grid>
          </Container>

          <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={4}>
                <Link color="inherit" href="/backoffice/associacao-contas-lista">
                  <Card className={classes.corMonitor}>
                    <CardContent style={{ textAlign: 'center' }}>
                      <img
                        style={{ width: '128px' }}
                        src={imageConfigAssociacaoContas}
                        alt="monitor512"
                        border="0"
                      />
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        Associação de Contas
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>

              <Grid item xs={12} md={4}>
                <Link color="inherit" href="/backoffice/associacao-bancos-lista">
                  <Card className={classes.corMonitor}>
                    <CardContent style={{ textAlign: 'center' }}>
                      <img
                        style={{ width: '128px' }}
                        src={imageConfigAssociacaoBancos}
                        alt="monitor512"
                        border="0"
                      />
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        Associação de Bancos
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
          </Grid>
          </Container>
      </main>
    </div>
  );
}
