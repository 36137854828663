import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DataGridWrapper from './../../components/DataGrid/DataGridWrapper';
import { Box, Button } from '@material-ui/core';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { deleteService, getServices } from './services';
import { CheckCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalConfirm from '../../components/ModalConfirm';
import Loading from '../../components/Loading';

export default function Servicos() {
  const history = useHistory();
  const theme = useTheme();
  const [openModalDeleteService, setOpenModalDeleteService] = useState({
    open: false,
    id: '',
    service: '',
  });

  const [rows, setRows] = useState([]);
  const [loadButton, setLoadButton] = useState(true);
  const [loadButtonDelete, setLoadButtonDelete] = useState(false);

  const columns = [
    { field: 'id', headerName: 'Código', flex: 1, minWidth: 100 },
    { field: 'descricao', headerName: 'Descrição', flex: 3, minWidth: 200 },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Ações',
      type: 'text',
      flex: 1,
      renderCell: event => {
        return (
          <Box display={'flex'} justifyContent={'space-between'}>
            <Button
              variant="outlined"
              style={{ marginRight: 5, borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                handleEditar(event.row);
              }}
            >
              <EditIcon color={'primary'} size={18} />
            </Button>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                setOpenModalDeleteService({
                  open: true,
                  id: event.row.id,
                  service: event.row.descricao,
                });
              }}
            >
              <DeleteIcon color={'primary'} size={18} />
            </Button>
          </Box>
        );
      },
    },
  ];

  function novoRegistro() {
    history.push('/backoffice/servicos-cadastro');
  }

  const handleEditar = value => {
    localStorage.setItem('servicos', JSON.stringify(value));
    history.push('/backoffice/servicos-editar');
  };

  const fetchServices = () => {
    setLoadButton(true);
    getServices()
      .then(data => {
        setLoadButton(false);
        if (!!data) {
          const aquiTable = data.map(data => ({
            ...data,
            id: data.id,
            descricao: data.descricao,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const featchDeleteService = async () => {
    setLoadButtonDelete(true);

    await deleteService(openModalDeleteService.id)
      .then(() => {
        setLoadButtonDelete(false);
        setOpenModalDeleteService({ open: null, id: '', service: '' });
        toastComponentSuccess('Serviço deletado com sucesso!', theme);
        fetchServices();
      })
      .catch(error => {
        console.log(error);
        setOpenModalDeleteService({ open: null, id: '', service: '' });
        setLoadButtonDelete(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <>
      <ModalConfirm
        confirmLabel="Deletar"
        descricao={
          <>
            Deseja deletar o serviço <b>{openModalDeleteService.service}?</b>
          </>
        }
        open={!!openModalDeleteService.open}
        onClose={() =>
          setOpenModalDeleteService({ open: null, id: '', service: '' })
        }
        onConfirm={() => featchDeleteService()}
      />
      <DataGridWrapper
        minHeight="600px"
        rows={rows}
        columns={columns}
        loading={loadButton}
        pageSize={10}
        title="Serviços"
        crumb={[
          { link: '/backoffice', name: 'Home' },
          { name: 'Configurações' },
        ]}
        newRegister={novoRegistro}
      />
      <Loading show={loadButtonDelete} />
    </>
  );
}
