export const handleDownloadFile = async (idFile) => {
    const getCodeDocumentoUrl = `${BASE_URL}${ENDPOINTS.GATEWAY_DOCUMENTOS}/obterTokenCode/${idFile}`;
    const { data } = await client.get(getCodeDocumentoUrl);
    const downloadUrl = `${BASE_URL}${ENDPOINTS.GATEWAY_DOCUMENTOS}/preview?idArquivo=${idFile}&code=${data}`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
    return;
};

export const downloadCSV = async (dataBlob, fileName) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        const anchor = document.createElement("a");
        document.body.appendChild(anchor);
        const blob = new Blob([dataBlob]);
        const objectUrl = window.URL.createObjectURL(blob);
        anchor.href = objectUrl;
        anchor.download = fileName || "document.csv";
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
        document.body.removeChild(anchor);
        resolve(fileName);
      }, 100);
    });
};