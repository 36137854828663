import React, { useState, createContext, useContext } from 'react';

const UserContext = createContext([]);

export function UserProvider({ children }) {

  const [ user, setUser] = useState([
    {
      nome: 'teste',
      id: 1,
      contaId: 3
    }
  ])


  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {

  const context = useContext(UserContext);
  return context;

}
