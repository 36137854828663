import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import DataGridWrapper from './../../../components/DataGrid/DataGridWrapper';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { toastComponentError } from './../../../components/Toast';
import { getContas, getMonitorTerms, getServices, getTerms } from '../services';
import { MdRemoveRedEye } from 'react-icons/md';
import { useStyles } from '../styles';
import { Autocomplete } from '@material-ui/lab';
import { TextEditor } from '../../../components/Inputs/TextEditor';

export default function Termos() {
  const theme = useTheme();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loadButton, setLoadButton] = useState(true);
  const [optionsTermos, setOptionsTermos] = useState([]);
  const [optionsClientes, setOptionsClientes] = useState([]);
  const [optionsServicos, setOptionsServicos] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [correntistaDetails, setCorrentistaDetails] = React.useState([]);
  const paramsInicial = {
    idTermo: '',
    situacao: '',
    idClienteConta: '',
    idServico: '',
    dataFimVigencia: '',
    dataInicioVigencia: '',
  };
  const [params, setParams] = React.useState(paramsInicial);
  const [clean, setClean] = React.useState(false);

  const columns = [
    { field: 'id', headerName: 'Código', minWidth: 100 },
    { field: 'nome', headerName: 'Nome', flex: 1, minWidth: 100 },
    { field: 'assunto', headerName: 'Assunto', minWidth: 180 },
    {
      field: 'dataInicioVigencia',
      headerName: 'Data Inicio Vigência',
      minWidth: 180,
    },
    {
      field: 'dataFimVigencia',
      headerName: 'Data Fim Vigência',
      minWidth: 180,
    },
    { field: 'situacao', headerName: 'Situação', minWidth: 150 },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Ações',
      type: 'text',
      renderCell: event => {
        return (
          <>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                setOpenModal(true);
                setCorrentistaDetails(event.row.correntistas);
              }}
            >
              <MdRemoveRedEye color={'primary'} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const getTermos = () => {
    getTerms().then(termos => {
      const options = termos.map(item => ({
        label: item.assunto,
        value: item.id,
      }));
      setOptionsTermos(options);
    });
  };

  const getClientes = () => {
    getContas().then(termos => {
      const options = termos.map(item => ({
        label: item.cliente.nome,
        value: item.id,
      }));
      setOptionsClientes(options);
    });
  };

  const getServicos = () => {
    getServices().then(termos => {
      const options = termos.map(item => ({
        label: item.descricao,
        value: item.id,
      }));
      setOptionsServicos(options);
    });
  };

  React.useEffect(() => {
    getTermos();
    getClientes();
    getServicos();
  }, []);

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <Autocomplete
            id="combo-identificador"
            size="small"
            options={optionsTermos}
            value={
              optionsTermos.find(option => option?.value === params?.idTermo) ||
              null
            }
            getOptionLabel={option => option.label}
            onChange={(_, option) => {
              setParams(old => ({ ...old, idTermo: option?.value }));
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Termo"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 4 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-identificador"
            size="small"
            options={optionsClientes}
            value={
              optionsClientes.find(
                option => option?.value === params?.idClienteConta,
              ) || null
            }
            getOptionLabel={option => option.label}
            onChange={(_, option) => {
              setParams(old => ({ ...old, idClienteConta: option?.value }));
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Cliente"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 4 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-identificador"
            size="small"
            value={
              optionsServicos.find(
                option => option?.value === params?.idServico,
              ) || null
            }
            options={optionsServicos}
            getOptionLabel={option => option.label}
            onChange={(_, option) => {
              setParams(old => ({ ...old, idServico: option?.value }));
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Serviço"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 4 },
      },
      {
        html: () => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          >
            <InputLabel
              className={classes.labelSelect}
              shrink
              htmlFor="outlined-age-native-simple"
            >
              Situação
            </InputLabel>
            <Select
              fullWidth
              size="small"
              value={params?.situacao}
              onChange={event => {
                setParams(old => ({ ...old, situacao: event.target.value }));
              }}
              label="Controle"
            >
              <MenuItem value="PENDENTE">Pendente </MenuItem>
              <MenuItem value="APROVADO">Aprovado </MenuItem>
              <MenuItem value="REPROVADO">Reprovado</MenuItem>
              <MenuItem value="EXPIRADO">Expirado</MenuItem>
            </Select>
          </FormControl>
        ),
        grid: { xs: 12, sm: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Início Vigência"
            id="outlined-size-small"
            value={params?.dataInicioVigencia}
            onChange={event => {
              setParams(old => ({
                ...old,
                dataInicioVigencia: event.target.value,
              }));
            }}
            variant="outlined"
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Fim Vigência"
            id="outlined-size-small"
            value={params?.dataFimVigencia}
            onChange={event => {
              setParams(old => ({
                ...old,
                dataFimVigencia: event.target.value,
              }));
            }}
            variant="outlined"
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2 },
      },
    ],
    searchButton: {
      searchButton: () => handleMonitorTerms(),
    },
    cleanButton: {
      cleanButton: () => {
        setParams(paramsInicial);
        setClean(true);
      },
    },
  };

  const handleMonitorTerms = () => {
    setLoadButton(true);
    getMonitorTerms(params)
      .then(data => {
        setLoadButton(false);
        const aquiTable = data.map(data => ({
          ...data,
          id: data.id,
          dataInicioVigencia: moment(new Date(data.dataInicioVigencia)).format(
            'DD/MM/YYYY',
          ),
          dataFimVigencia: moment(new Date(data.dataFimVigencia)).format(
            'DD/MM/YYYY',
          ),
          assunto: data.assunto,
          idClienteConta: data.idClienteConta,
          situacao: data.situacao,
        }));
        setClean(false);
        setRows(aquiTable);
      })
      .catch(error => {
        setClean(false);
        setLoadButton(false);
        setRows([]);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  useEffect(() => {
    setParams(paramsInicial);
    handleMonitorTerms();
  }, []);

  useEffect(() => {
    if (clean) {
      handleMonitorTerms();
    }
  }, [clean]);

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: '8px',
          },
        }}
        fullWidth
        maxWidth={'md'}
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <Box style={{ padding: 5, marginBottom: 15 }}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="inherit" component="h5">
              Termos dos Correntistas
            </Typography>
            <Tooltip title="Fechar">
              <IconButton
                onClick={() => setOpenModal(false)}
                style={{
                  position: 'absolute',
                  top: '12px',
                  right: '16px',
                }}
              >
                <MdClose size={18} />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          <DialogContent dividers>
            {correntistaDetails.map((item, index) => (
              <Box key={item.id} ml={2} mr={2}>
                <Typography variant="body2" style={{ lineHeight: '200%' }}>
                  <strong style={{ marginRight: 5 }}>
                    Cliente Correntista:
                  </strong>
                  {item.nome}
                </Typography>
                <Typography variant="body2" style={{ lineHeight: '200%' }}>
                  <strong style={{ marginRight: 5 }}>
                    Situação Correntista:
                  </strong>

                  {item.situacaoCorrentista}
                </Typography>
                <div
                  style={{
                    marginBottom: '20px',
                    paddingBottom:
                      index !== correntistaDetails?.length - 1 ? 20 : 5,
                    borderBottom:
                      index !== correntistaDetails?.length - 1
                        ? `1px solid ${theme.palette.divider}`
                        : 'none',
                  }}
                >
                  <TextEditor
                    name="template"
                    modulesProps={{
                      toolbar: [{ align: [] }],
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    label=""
                    readOnly
                    value={item.template}
                  />
                </div>
              </Box>
            ))}
          </DialogContent>
        </Box>
      </Dialog>
      <DataGridWrapper
        minHeight="600px"
        rows={rows}
        formData={filterInputs}
        columns={columns}
        loading={loadButton}
        pageSize={10}
        title="Monitor Termos"
        crumb={[{ link: '/backoffice', name: 'Home' }, { name: 'Termos' }]}
      />
    </>
  );
}
