import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Typography,
  Tooltip,
  Box as MuiBox,
  makeStyles,
  alpha,
  Button,
} from '@material-ui/core';
import LoadingBtn from '../../../../components/Buttons/ConfirmButton';
import { MdAdd, MdArrowBack, MdSave } from 'react-icons/md';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { api_multipague_configuracao } from '../../../../services/api';

import Wrapper from '../../../../components/Wrapper';
import Box from '../../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../../components/Toast';
import DataGrid from '../../../../components/DataGrid';
import formattedMoney from '../../../../util/FormatteMoney';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { Autocomplete } from '@material-ui/lab';
import ModalConfirm from '../../../../components/ModalConfirm';
import Loading from '../../../../components/Loading';
import PlusButton from '../../../../components/Buttons/PlusButton';

export default function EdicaoSegmentoTarifador() {
  const history = useHistory();
  const historyState = JSON.parse(localStorage.getItem('segmento-tarifador'));
  const theme = useTheme();
  const useStyles = makeStyles(theme => ({
    btnAdd: {
      width: '25px',
      height: '25px',
      borderRadius: '20px',
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.dark, 0.8),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: 'none',
      backgroundColor: alpha(
        theme.palette.appBar[theme.palette.type].bgMenu,
        0.7,
      ),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
    },
    buttons: {
      marginTop: '1rem',
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    boxContainer: {
      borderRadius: '20px',
      padding: 30,
    },
  }));
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const [modalApagarFaixa, setModalApagarFaixa] = useState(false);

  const [loadingDatagrid, setLoadingDatagrid] = useState(false);
  const [segmentoTarifadorDefault, setSegmentoTarifadorDefault] = useState({
    id: historyState?.id,
    segmentoTarifador: historyState?.segmentoTarifador?.descricao,
    evento: historyState?.evento?.descricao,
    idEvento: historyState?.evento?.id,
    apuracaoTarifa: historyState?.apuracaoTarifa,
    valorTarifa: historyState?.valorTarifa,
    valorCusto: historyState?.valorCusto,
  });
  const [eventoOptions, setEventoOptions] = useState([]);
  const [rows, setRows] = useState([]);

  const columns = [
    { field: 'id', headerName: 'Código', minWidth: 50 },
    {
      field: 'idSegmentoTarifadorEvento',
      headerName: 'Segmento Tarifador Evento',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      minWidth: 150,
    },
    {
      field: 'faixaInicial',
      headerName: 'Faixa Inicial',
      minWidth: 120,
    },
    { field: 'faixaFinal', headerName: 'Faixa Final', minWidth: 120 },
    {
      field: 'valorTarifa',
      headerName: 'Valor Tarifa',
      minWidth: 120,
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.valorTarifa))} </p>;
      },
    },
    {
      field: 'valorCusto',
      headerName: 'Valor Custo',
      minWidth: 120,
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.valorCusto))} </p>;
      },
    },
    {
      field: 'editar',
      minWidth: 150,
      headerName: 'Ação',
      type: 'text',
      renderCell: value => {
        return (
          <>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                localStorage.setItem(
                  'faixa-tarifador',
                  JSON.stringify(value.row),
                );
                history.push('/backoffice/faixa-tarifador-editar');
              }}
            >
              <EditIcon color={theme.palette.primary.main} size={20} />
            </Button>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={e => {
                e.stopPropagation();
                setModalApagarFaixa(value.row);
              }}
            >
              <DeleteIcon color={theme.palette.error.main} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    api_multipague_configuracao
      .get('/evento')
      .then(response => {
        setEventoOptions(response.data);
      })
      .catch(error => {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });

    setLoadingDatagrid(true);
    api_multipague_configuracao
      .get(
        `/segmento/%7BidSegmento%7D/evento/${segmentoTarifadorDefault.id}/faixa`,
      )
      .then(response => {
        const monitorFilter = response.data;
        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            idSegmentoTarifadorEvento:
              data.segmentoTarifadorEvento.evento.descricao,
            descricao: data.descricao,
            faixaInicial: data.faixaInicial,
            faixaFinal: data.faixaFinal,
            valorTarifa: data.valorTarifa,
            valorCusto: data.valorCusto,
          }));
          setLoadingDatagrid(false);
          setRows(aquiTable);
        } else {
          setLoadingDatagrid(false);
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        if (error.response?.status === 404) {
          setRows([]);
          return;
        }
        toastComponentError(
          error.response?.data?.mensagem ||
            'Não foi possível se comunicar com o servidor',
          theme,
        );
      })
      .finally(() => {
        setLoadingDatagrid(false);
      });

    const rows2 = rows
      .filter(
        item => item.idSegmentoTarifadorEvento === segmentoTarifadorDefault?.id,
      )
      .map(data => ({
        ...data,
        id: data.id,
        valorTarifa: formattedMoney(parseFloat(data?.valorTarifa)),
        valorCusto: formattedMoney(parseFloat(data?.valorCusto)),
      }));

    setRows(rows2);
  }, []);

  const handleSalvar = () => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(
        `/segmento/${historyState?.segmentoTarifador?.id}/evento/${segmentoTarifadorDefault?.id}`,
        {
          apuracaoTarifa: segmentoTarifadorDefault?.apuracaoTarifa,
          idEvento: segmentoTarifadorDefault?.idEvento,
          valorCusto: Number(segmentoTarifadorDefault?.valorCusto),
          valorTarifa: Number(segmentoTarifadorDefault?.valorTarifa),
        },
      )
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Informação editada com sucesso!', theme);
        history.push('/backoffice/tarifador-editar');
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleApagarFaixa = row => {
    console.log('apaga', row);
    setLoadingPage(true);
    setModalApagarFaixa(null);
    api_multipague_configuracao
      .delete(
        `/segmento/${historyState?.segmentoTarifador?.id}/evento/${segmentoTarifadorDefault?.id}/faixa/${row?.id}`,
      )
      .then(function (response) {
        setLoadingPage(false);
        toastComponentSuccess('Faixa apagada com sucesso!', theme);
        setRows(old => old.filter(item => item.id !== row?.id));
      })
      .catch(function (error) {
        setLoadingPage(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      title={`Editar Evento Tarifador`}
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/tarifador', name: 'Tarifadores' },
        { link: '/backoffice/tarifador-editar', name: 'Eventos Tarifadores' },
      ]}
    >
      <Box className={classes.boxContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              disabled
              label="Código"
              id="outlined-size-small"
              value={segmentoTarifadorDefault.id}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <TextField
              disabled
              fullWidth
              label="Segmento Tarifador"
              id="outlined-size-small"
              value={segmentoTarifadorDefault.segmentoTarifador}
              onChange={event =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  segmentoTarifador: event?.target?.value,
                }))
              }
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={eventoOptions}
              value={
                eventoOptions.find(
                  option => option.id === segmentoTarifadorDefault.idEvento,
                ) || null
              }
              getOptionLabel={option => option.descricao}
              onChange={(_, option) => {
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  idEvento: option ? option.id : null,
                }));
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Evento"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Apuração Tarifa"
              id="outlined-size-small"
              value={segmentoTarifadorDefault.apuracaoTarifa}
              onChange={event =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  apuracaoTarifa: event?.target?.value,
                }))
              }
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              label="Valor Tarifa"
              value={segmentoTarifadorDefault.valorTarifa}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  valorTarifa: value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              label="Valor Custo"
              decimalCharacter=","
              digitGroupSeparator="."
              InputLabelProps={{
                shrink: true,
              }}
              currencySymbol="R$"
              outputFormat="string"
              value={segmentoTarifadorDefault.valorCusto}
              onChange={(event, value) =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  valorCusto: value,
                }))
              }
            />
          </Grid>
        </Grid>
        <Grid item lg={12} style={{ paddingTop: 50 }}>
          <MuiBox display="flex" alignItems={'center'} pb={1}>
            <Typography
              variant="body1"
              component="h3"
              style={{ marginRight: 5 }}
            >
              Faixas Segmento Tarifador
            </Typography>

            <PlusButton
              title={'Adicionar Faixas Tarifador'}
              startIcon={<MdAdd color="background" />}
              onClick={() =>
                history.push('/backoffice/faixa-tarifador-cadastro')
              }
            />
          </MuiBox>
          <DataGrid
            columns={columns}
            rows={rows}
            loading={loadingDatagrid}
            autoHeight
            pageSize={10}
          />
        </Grid>
        <div className={classes.buttons}>
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/tarifador-editar');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
      <ModalConfirm
        descricao={
          <>
            Deseja apagar a faixa <b>{modalApagarFaixa?.descricao}?</b>
          </>
        }
        open={!!modalApagarFaixa}
        onClose={() => setModalApagarFaixa(null)}
        onConfirm={() => handleApagarFaixa(modalApagarFaixa)}
      />
      <Loading show={loadingPage} />
    </Wrapper>
  );
}
