export default function FormattedErrorRequest(error) {
  console.log('ERRO - FormattedErrorRequest', error);
  let message = `${error.response.data.status} - `;
  if (error.response.data.message) {
    message += error.response.data.message;
  } else if (error.response.data.mensagem) {
    message += error.response.data.mensagem;
  } else if (error.response.data.error) {
    message += error.response.data.error;
  }
  console.log('message request', message);
  return message;
}
