import { Backdrop, Button, Fade, Modal, Typography, makeStyles } from "@material-ui/core";
import { Cancel,Delete } from "@material-ui/icons";
import React from "react";


const useStyles = makeStyles(theme => ({

    modal: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '20px',
      justifyContent: 'center',
    },
    paper: (classContainer)=>({
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
      boxShadow: theme.shadows[5],
      padding: 0,
      ...classContainer
    }),
    content:{
      minWidth:"300px",
      display:"flex",
      flexDirection:"column",
      padding:"1.2rem",
      textAlign:"center",
      gap:"5px"
    },
    boxActions:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      paddingTop:"10px",
      borderTop:"solid 1px #e1e1e1",
      padding:"1rem",
      gap:"1rem",
      "& button":{
        borderRadius:"30px",
        flex: 1,
        fontSize: "13px !important",
        textTransform: "capitalize",
      }
    }

  }));


function ModalConfirm({
  open,
  onClose,
  title="",
  descricao,
  onConfirm,
  cancelLabel=  "Cancelar" ,
  confirmLabel= "Apagar" ,
  color="primary",
  actionsButton=true,
  classContainer={}
}) {
  const classes = useStyles(classContainer);

  return <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={!!open}
    className={classes.modal}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={!!open}>
      <div className={classes.paper} >
        <div className={`${classes.content} content`}>
          {!!title && <Typography  variant="body1" style={{fontWeight:"bold"}}>
            {title}
          </Typography>}


          {!!descricao && <Typography  variant="caption">
            {descricao}
          </Typography>}


        </div>
        {!!actionsButton &&
        <div className={`${classes.boxActions} boxActions` }>
          <Button
            size='small'
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            {cancelLabel}
          </Button>

          <Button
            size='small'
            color={color}
            variant="outlined"
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        </div>}
      </div>
    </Fade>
  </Modal>;
}

export default ModalConfirm
