/* eslint-disable */
import React from 'react';
import './index.css';
import Routes from './routes/index';
import { ToastContainer, toast } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

import { UserProvider } from './hooks/useUser';
import {
  createTheme,
  ThemeProvider,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale/';
import { getTheme } from './theme/api.theme';
import store from './store';
import { Provider, useSelector } from 'react-redux';
import { logoutBackoffice } from './util/Auth';

const queryClient = new QueryClient();

const generateClassName = createGenerateClassName({
  productionPrefix: 'solucoes',
  seed: 'backOffice',
});

const Main = () => {
  const [themeApp, setThemeApp] = React.useState(null);
  const { modeTheme, previewTheme } = useSelector(state => state.app);

  React.useEffect(() => {
    getTheme()
      .then(theme => {
        theme.palette.type = modeTheme;
        localStorage.setItem('app-config', JSON.stringify(theme));
        const customTheme = createTheme({ ...theme }, ptBR);
        setThemeApp(customTheme);
      })
      .catch(erro => {
        console.log('erro para pegar tema', erro);
        logoutBackoffice();
      });
  }, [modeTheme]);

  React.useEffect(() => {
    console.log('mudou o tema', previewTheme);
    if (!!previewTheme) {
      const customTheme = createTheme({ ...previewTheme }, ptBR);
      setThemeApp(customTheme);
    }
  }, [previewTheme]);

  return (
    <React.StrictMode>
      <StylesProvider generateClassName={generateClassName}>
        {themeApp && (
          <ThemeProvider theme={themeApp}>
            <UserProvider>
              <ToastContainer />
              <Routes />
            </UserProvider>
          </ThemeProvider>
        )}
      </StylesProvider>
    </React.StrictMode>
  );
};

function Index() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </Provider>
  );
}

export default Index;
