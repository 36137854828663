import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import { Cookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';

import { api_multipague_configuracao } from '../../../services/api';
import { MdArrowBack, MdSave } from 'react-icons/md';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import { toastComponentError, toastComponentSuccess } from '../../../components/Toast';

export default function CadastroEventos() {
  const history = useHistory();
  const cookies = new Cookies();
  const theme = useTheme();
  const [loadButton, setLoadButton] = useState(false);
  const [selecionarConta, setSelecionarConta] = useState([]);

  const [descricao, setDescricao] = useState('');
  const [selectContas, setSelectContas] = useState('');
  const [codigo, setCodigo] = useState('');
  const [combo, setCombo] = useState([
    {
      nome: 'Débito',
      value: 'DEBITO',
    },
    {
      nome: 'Crédito',
      value: 'CREDITO',
    },
    {
      nome: 'Não Aplicável',
      value: 'NAO_APLICAVEL',
    },
  ]);
  const [esteira, setEsteira] = React.useState({
    checkedA: false,
  });
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const [active, setActive] = React.useState({
    checkedA: false,
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const novoCadastro = {
    ativo: active.checkedA,
    descricao: descricao,
    identificadorEvento: selectContas.toString(),
    id: parseInt(codigo),
    utilizaEsteira: esteira.checkedA,
    utilizaSenha: state.checkedA,
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(`/identificador-evento`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .post(`/evento`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Conta aprovada com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/eventos-lista');
          localStorage.clear();
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
          //   setTimeout(function () {
          //     location.reload();
          //   }, 5000);
        }
      });
  };

  const handleCodigo = event => {
    setCodigo(event.target.value);
  };
  const handleEsteira = event => {
    setEsteira({ ...esteira, [event.target.name]: event.target.checked });
  };
  const handleSenha = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleAtivo = event => {
    setActive({ ...active, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    console.log('Senha: ', state.checkedA);
    console.log('Ativo: ', active.checkedA);
  }, [state, active]);

  const handleDescricao = event => {
    setDescricao(event.target.value);
  };

  return (
    <Wrapper
      title="Cadastrar evento"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/eventos-lista', name: 'Eventos' },
      ]}
      crumbActive="Cadastro"
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="Código"
              id="outlined-size-small"
              onChange={handleCodigo}
              // defaultValue="Small"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              // defaultValue="Small"
              onChange={handleDescricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={selecionarConta}
              getOptionLabel={option => option.descricao}
              onChange={(event, option) => {
                setSelectContas(option.valor);
              }}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Identificador do Evento"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <FormLabel component="legend">Esteira</FormLabel>
            <CustomSwitch
              checked={esteira.checkedA}
              onChange={handleEsteira}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <FormLabel component="legend">Senha</FormLabel>
            <CustomSwitch
              checked={state.checkedA}
              onChange={handleSenha}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <FormLabel component="legend">Ativo</FormLabel>
            <CustomSwitch
              checked={active.checkedA}
              onChange={handleAtivo}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/eventos-lista');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
