import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DataGridWrapper from './../../components/DataGrid/DataGridWrapper';
import { Box, Button } from '@material-ui/core';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { deleteTerm, getTerms } from './services';
import ModalConfirm from '../../components/ModalConfirm';
import Loading from '../../components/Loading';
import DeleteIcon from '@material-ui/icons/Delete';

export default function Termos() {
  const history = useHistory();
  const theme = useTheme();
  const [loadButtonDelete, setLoadButtonDelete] = useState(false);
  const [openModalDeleteTerm, setOpenModalDeleteTerm] = useState({
    open: false,
    id: '',
    term: '',
  });
  const [rows, setRows] = useState([]);
  const [loadButton, setLoadButton] = useState(true);

  const columns = [
    { field: 'id', headerName: 'Código', flex: 1, minWidth: 100 },
    { field: 'assunto', headerName: 'Assunto', flex: 1, minWidth: 100 },
    { field: 'observacao', headerName: 'Observação', flex: 1, minWidth: 100 },
    { field: 'template', headerName: 'Template', flex: 3, minWidth: 200 },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Ações',
      type: 'text',
      flex: 1,
      renderCell: event => {
        return (
          <Box display={'flex'} justifyContent={'space-between'}>
            <Button
              variant="outlined"
              style={{ marginRight: 5, borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                handleEditar(event.row);
              }}
            >
              <EditIcon color={'primary'} size={20} />
            </Button>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                setOpenModalDeleteTerm({
                  open: true,
                  id: event.row.id,
                  term: event.row.assunto,
                });
              }}
            >
              <DeleteIcon color={'primary'} size={18} />
            </Button>
          </Box>
        );
      },
    },
  ];

  function novoRegistro() {
    history.push('/backoffice/termos-cadastro');
  }

  const handleEditar = value => {
    history.push('/backoffice/termos-editar', { value });
  };

  const fetchTerms = () => {
    setLoadButton(true);
    getTerms()
      .then(data => {
        setLoadButton(false);
        if (!!data) {
          const aquiTable = data.map(data => ({
            id: data.id,
            assunto: data.assunto,
            observacao: data.observacao,
            template: data.template,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const featchDeleteTerm = async () => {
    setLoadButtonDelete(true);

    await deleteTerm(openModalDeleteTerm.id)
      .then(() => {
        setLoadButtonDelete(false);
        setOpenModalDeleteTerm({ open: null, id: '', term: '' });
        toastComponentSuccess('Termo deletado com sucesso!', theme);
        fetchTerms();
      })
      .catch(error => {
        console.log(error);
        setOpenModalDeleteTerm({ open: null, id: '', term: '' });
        setLoadButtonDelete(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  useEffect(() => {
    fetchTerms();
  }, []);

  return (
    <>
      <ModalConfirm
        confirmLabel="Deletar"
        descricao={
          <>
            Deseja deletar o termo <b>{openModalDeleteTerm.term}?</b>
          </>
        }
        open={!!openModalDeleteTerm.open}
        onClose={() => setOpenModalDeleteTerm({ open: null, id: '', term: '' })}
        onConfirm={() => featchDeleteTerm()}
      />
      <DataGridWrapper
        minHeight="600px"
        rows={rows}
        columns={columns}
        loading={loadButton}
        pageSize={10}
        title="Termos"
        crumb={[{ link: '/backoffice', name: 'Home' }, { name: 'Termos' }]}
        newRegister={novoRegistro}
      />
      <Loading show={loadButtonDelete} />
    </>
  );
}
