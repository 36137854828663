import { styled, Box } from '@material-ui/core';

export const Main = styled(Box)(({ theme }) => {
  return {
    '&::-webkit-scrollbar': {
      width: '8px',
      maxHeight: '5px',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: '#fff',
      '-webkitBorderRadius': '6px',
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      backgroundColor: theme.palette.primary.main,
      '-webkit-border-radius': '6px',
    },
    scrollbarColor: `${theme.palette.primary.main} #fff`,
    scrollbarWidth: 'thin !important',
  };
});
