import React, { useEffect } from 'react';
import UseMonitor from './useMonitor';

import Loading from '../../components/Loading';
import Modals from './components/Modals';
import { ListMonitor } from './components/ListMonitor';

import { Container } from './styles';

const MonitorAprovacaoContent = () => {
  const { onFilter, loading, filter } = UseMonitor();

  useEffect(() => {
    onFilter(filter);
  }, []);

  return (
    <Container>
      <Loading show={loading} />
      <Modals />
      <ListMonitor />
    </Container>
  );
};

export default MonitorAprovacaoContent;
