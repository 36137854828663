import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Wrapper from '../../components/Wrapper';
import Button from '../../components/Buttons/ConfirmButton';
import { api_multipague_configuracao } from '../../services/api';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Select,
  Tooltip,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { MdFilterList, MdSave, MdSearch } from 'react-icons/md';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '50px',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 450,
    height: 230,
    maxHeight: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export const VinculoContaEdit = () => {
  const history = useHistory();
  const theme = useTheme();
  const editingInfo = JSON.parse(
    localStorage.getItem('edicao_usuario_vinculo'),
  );

  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [desvinculados, setDesvinculados] = React.useState([]);
  const [vinculados, setVinculados] = React.useState([]);
  const [leftValue, setLeftValue] = React.useState('');
  const [rigthValue, setRigthValue] = React.useState('');
  const [showLeftFilters, setShowLeftFilters] = React.useState(false);
  const [showRightFilters, setShowRightFilters] = React.useState(false);
  const [grupo, setGrupo] = React.useState({
    desvinculados: '',
    vinculados: '',
  });
  const [grupos, setGrupos] = useState([
    {
      label: '',
      value: '',
    },
  ]);

  const DesvinculadosChecked = intersection(checked, desvinculados);
  const vinculadosChecked = intersection(checked, vinculados);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = filteredItems => () => {
    const filteredChecked = intersection(checked, filteredItems);

    if (filteredChecked.length === filteredItems.length) {
      setChecked(not(checked, filteredItems));
    } else {
      setChecked(union(checked, filteredItems));
    }
  };

  const handleCheckedRight = () => {
    setVinculados(vinculados.concat(DesvinculadosChecked));
    setDesvinculados(not(desvinculados, DesvinculadosChecked));
    setChecked(not(checked, DesvinculadosChecked));
  };

  const handleCheckedLeft = () => {
    setDesvinculados(desvinculados.concat(vinculadosChecked));
    setVinculados(not(vinculados, vinculadosChecked));
    setChecked(not(checked, vinculadosChecked));
  };

  const customList = (title, items) => {
    const isLeftColumn = title === 'Usuários NÃO vinculados';
    const columnValue = isLeftColumn ? leftValue : rigthValue;
    const grupoValue = isLeftColumn ? grupo.desvinculados : grupo.vinculados;
    const setColumnValue = isLeftColumn ? setLeftValue : setRigthValue;
    const showFilters = isLeftColumn ? showLeftFilters : showRightFilters;

    const filteredItems = items.filter(
      item =>
        item?.nome?.toLowerCase().includes(columnValue?.toLowerCase()) &&
        (!grupoValue || item?.grupo === grupoValue),
    );

    const isChecked =
      numberOfChecked(filteredItems) === filteredItems.length &&
      items.length !== 0;
    const isIndeterminate =
      numberOfChecked(filteredItems) !== filteredItems.length &&
      numberOfChecked(filteredItems) !== 0;

    return (
      <Card
        style={{
          border: `solid 1px ${theme.palette.text.disabled}`,
          borderRadius: '20px',
        }}
      >
        <MuiButton
          style={{
            borderRadius: '20px',
            margin: 5,
            padding: '6px 10px',
            textTransform: 'capitalize',
            backgroundColor: isLeftColumn
              ? showLeftFilters
                ? theme.palette.type === 'dark'
                  ? theme.palette.divider
                  : theme.palette.background.default
                : ''
              : showRightFilters
              ? theme.palette.type === 'dark'
                ? theme.palette.divider
                : theme.palette.background.default
              : '',
          }}
          onClick={() =>
            isLeftColumn
              ? setShowLeftFilters(!showLeftFilters)
              : setShowRightFilters(!showRightFilters)
          }
        >
          <MdFilterList size={25} style={{ padding: 2 }} /> Filtros
        </MuiButton>
        {showFilters && (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'baseline'}
          >
            <TextField
              value={columnValue}
              onChange={e => setColumnValue(e.target.value)}
              id="nomeCliente"
              style={{
                width: '46%',
                margin: '0 2% 2%',
              }}
              fullWidth
              label="Nome"
              InputLabelProps={{ shrink: true }}
              size="small"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
            <FormControl
              size="small"
              style={{ width: '46%', margin: '0 2% 2%' }}
            >
              <InputLabel shrink id="demo-simple-select-outlined-label">
                Grupo
              </InputLabel>
              <Select
                fullWidth
                label="Situação"
                id="outlined-size-small"
                style={{ borderRadius: '20px' }}
                size="small"
                onChange={event =>
                  isLeftColumn
                    ? setGrupo(prev => ({
                        ...prev,
                        desvinculados: event.target.value,
                      }))
                    : setGrupo(prev => ({
                        ...prev,
                        vinculados: event.target.value,
                      }))
                }
              >
                <MenuItem value={''}>{'Nenhum'}</MenuItem>
                {grupos.map(item => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        )}
        <Divider />
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(filteredItems)}
              checked={isChecked}
              indeterminate={isIndeterminate}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'todos os itens selecionados' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(filteredItems)}/${
            items.length
          } selecionado(s)`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {filteredItems.map(value => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.nome} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  useEffect(() => {
    const fetchGrupo = async () => {
      try {
        const response = await api_multipague_configuracao.get(
          '/keycloak/groups',
        );
        setGrupos(
          response.data?.map(item => {
            return { label: item.name, value: item.name };
          }) || [],
        );
      } catch (error) {
        toastComponentError(
          error.response.data.mensagem
            ? `Código: ${error.response.data.codigo} - ${error.response.data.mensagem}`
            : `Não foi possível buscar grupos`,
          theme,
        );
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchGrupo();

    api_multipague_configuracao
      .get(`/contas/${editingInfo.id}/usuarios-desvinculados-por-conta`)
      .then(response => {
        const formatted = response.data.map(usr => ({
          id: usr.id,
          login: usr.login,
          nome: usr.nome,
          grupo: usr?.grupo?.name,
        }));
        setDesvinculados(formatted);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    api_multipague_configuracao
      .get(`/contas/${editingInfo.id}/usuarios-vinculados-por-conta`)
      .then(response => {
        const formattedUser = response.data.map(usr => ({
          id: usr.id,
          login: usr.login,
          nome: usr.nome,
          grupo: usr?.grupo?.name,
        }));
        setVinculados(formattedUser);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  function handleVincular() {
    api_multipague_configuracao
      .put(
        `/contas/${editingInfo.id}/multipla-associacao`,
        vinculados.map(vinculado => ({
          id: vinculado.id,
          login: vinculado.login,
          nome: vinculado.nome,
        })),
      )
      .then(function (response) {
        setLoadButton(false);
        toastComponentSuccess('Vínculo feito com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/vinculo-conta');
        }, 3000);
      })
      .catch(function (error) {
        toastComponentError(error.response.data.mensagem, theme);
      });
  }

  return (
    <Wrapper
      title="Vincular Contas"
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        { link: '/backoffice/vinculo-conta', name: 'Vinculo de Contas' },
      ]}
    >
      <Paper
        style={{
          padding: '1rem',
          marginTop: '1rem',
          paddingTop: '1.5rem',
          borderRadius: '20px',
        }}
        elevation={0}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              id="nome"
              label="Nome Completo"
              variant="outlined"
              value={editingInfo.cliente.nome}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              id="usuario"
              variant="outlined"
              label="CPF/CNPJ"
              value={editingInfo.cliente.cnpjCpf}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              id="tipocontrole"
              label="Tipo Controle"
              variant="outlined"
              value={editingInfo.tipoControle}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled
              size="small"
              variant="outlined"
              required
              id="banco"
              name="banco"
              label="Banco"
              InputLabelProps={{
                shrink: true,
              }}
              value={editingInfo.banco.nome}
              fullWidth
              autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              disabled
              variant="outlined"
              size="small"
              required
              id="agencia"
              name="agencia"
              label="Agencia"
              value={editingInfo.agencia}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              disabled
              variant="outlined"
              size="small"
              required
              id="conta"
              name="conta"
              label="Conta"
              value={editingInfo.conta}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              disabled
              variant="outlined"
              size="small"
              required
              id="dgconta"
              name="dgconta"
              label="Digito Conta"
              value={editingInfo.digitoConta}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              autoComplete="shipping postal-code"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{
            marginTop: '30px',
          }}
        >
          <Grid item>
            {customList('Usuários NÃO vinculados', desvinculados)}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Tooltip title={`Vincular contas selecionados`}>
                <MuiButton
                  variant="contained"
                  color={'secondary'}
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={DesvinculadosChecked.length === 0}
                  aria-label="move selected vinculados"
                >
                  &gt;
                </MuiButton>
              </Tooltip>
              <Tooltip title={`Desvincular usuários selecionados`}>
                <MuiButton
                  variant="contained"
                  size="small"
                  color={'secondary'}
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={vinculadosChecked.length === 0}
                  aria-label="move selected desvinculados"
                >
                  &lt; {vinculadosChecked.length}
                </MuiButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item>{customList('Usuários vinculados', vinculados)}</Grid>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            marginBottom: '1rem',
            gap: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            title={'Confirmar'}
            disabled={false}
            onClick={() => handleVincular()}
            width={115}
            startIcon={<MdSave color="background" />}
            loading={loadButton}
          >
            Confirmar
          </Button>
        </Grid>
      </Paper>
    </Wrapper>
  );
};
