import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Main = styled.div``;

// Senha de acesso

export const ContainerAccessPassword = styled.div`
  width: 600px;
  min-height: 500px;
  border-radius: 8px;
  box-shadow: 10px 15px 30px rgb(0 0 0 / 50%);
  padding: 20px;
  display: grid;
  row-gap: 30px;
  grid-auto-rows: min-content;
`;
export const Header = styled.div`
  display: grid;
  row-gap: 20px;
`;
export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InputsContainer = styled.div`
  display: grid;
  row-gap: 20px;
`;
export const Label = styled.div``;
export const ContainerIcon = styled.div`
  padding: 20px;
  border-radius: 50%;
  background-color: lightblue;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;
export const Form = styled.div`
  display: grid;
  row-gap: 20px;
  grid-auto-rows: min-content;
`;
export const ContainerButtons = styled.div``;

// Senha de acesso

export const Copyright = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
