import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { MdCheck, MdError } from 'react-icons/md';

export const ToastComponent = textSucces => {
  return toast.success(textSucces, {
    position: 'top-right',
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const toastComponentError = (error, theme) => {
  const CustomToast = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <MdError
        size={23}
        style={{
          color: theme?.palette.error.main,
          marginRight: 5,
          flexShrink: 0,
        }}
      />
      <span>{error}</span>
    </div>
  );

  return toast.error(<CustomToast />, {
    position: 'top-right',
    autoClose: 5000,
    closeOnClick: true,
    progress: undefined,
    hideProgressBar: false,
    draggable: true,
    theme: 'light',
    pauseOnHover: true,
    style: {
      backgroundColor: theme?.palette?.background?.paper,
      color:
        theme.palette.type === 'dark'
          ? theme.palette.text.primary
          : theme.palette.error.dark,
    },
    toastComponent: CustomToast,
    progressStyle: {
      backgroundColor: theme.palette.error.dark,
    },
  });
};

export const toastComponentSuccess = (text, theme) => {
  const CustomToast = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <MdCheck
        size={25}
        style={{
          color:
            theme?.palette.type === 'dark'
              ? theme?.palette.primary.main
              : theme?.palette.appBar[theme?.palette.type].bgMenu,
          marginRight: 5,
          flexShrink: 0,
        }}
      />
      <span>{text}</span>
    </div>
  );

  return toast.success(<CustomToast />, {
    position: 'top-right',
    autoClose: 3000,
    progress: undefined,
    hideProgressBar: false,
    draggable: true,
    theme: 'light',
    pauseOnHover: true,
    style: {
      backgroundColor: theme.palette.background.paper || 'Background',
      color:
        theme.palette.type === 'dark'
          ? theme.palette.text.primary
          : theme.palette.appBar[theme.palette.type].bgMenu || 'ButtonText',
    },
    toastComponent: CustomToast,
    progressStyle: {
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.primary.main
          : theme.palette.appBar[theme.palette.type].bgMenu,
    },
  });
};
