import { useQuery } from 'react-query';
import { api_multipague_conta } from '../../services/api';
import { maskCpfCnpj } from '../../util/mask';
import { toastComponentError } from '../../components/Toast';
import { useTheme } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import formattedMoney from '../../util/FormatteMoney';

export const getHistoricoSaldos = (params, page, size) => {
  const theme = useTheme();
  return useQuery(
    ['getHistoricoSaldos', page, size],
    async () => {
      const { data } = await api_multipague_conta.get(`/saldo/diario`, {
        params,
      });
      const dataSaldoDiario = {
        totalElements: data.totalElements,
        data: data?.content?.map(item => ({
          ...item,
          id: uuidv4(),
          conta: `${item?.conta || ''}  ${item?.agencia || ''} - ${
            item?.digitoConta || ''
          }`,
          cnpjCpf: maskCpfCnpj(item.cpfCnpj),
          idClienteConta: null || '-',
          data: moment(item?.data).format('DD/MM/YYYY') || '-',
          saldoInicial: formattedMoney(item?.saldoInicial || 0) || '-',
          horaSaldoInicial: item?.horaSaldoInicial || '-',
          saldoFinal: formattedMoney(item?.saldoFinal || 0) || '-',
          horaSaldoFinal: item?.horaSaldoFinal || '-',
        })),
      };
      return dataSaldoDiario;
    },
    {
      retry: false,
      onError: err => {
        toastComponentError(
          `Codigo: ${err?.response?.data?.codigo} - ${err?.response?.data?.mensagem}`,
          theme,
        );
        console.error('Erro na requisição getHistoricoSaldos:', err);
      },
    },
  );
};
