import React, { useEffect } from 'react';
import Wrapper from '../../components/Wrapper';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineOppositeContent,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import moment from 'moment';
import { Add, HistoryOutlined } from '@material-ui/icons';
import { api_multipague_transacao } from '../../services/api';
import { toastComponentError } from '../../components/Toast';

const HistoricoTeds = () => {
  const history = useHistory();
  const classes = useStyles();
  const timelineRef = React.useRef(Array(historico?.length).fill(false));
  const [timelineVisivel, setTimelineVisivel] = React.useState(
    timelineRef.current,
  );
  const [dataCadastroeStatus, setDataCadastroeStatus] = React.useState({
    usuarioCadastro: '',
    dataCadastro: '',
    status: '',
    descricaoHistorico: '',
  });

  function handleClick(i) {
    timelineRef.current[i] = !timelineRef.current[i];
    setTimelineVisivel([...timelineRef.current]);
  }
  const historico = history.location?.state?.historic;
  const formInfo = history.location?.state?.formInfo;

  useEffect(() => {
    if (
      historico[0]?.evento.identificador == 'TRANSFERENCIA_TED' ||
      historico[0]?.evento.identificador == 'TRANSFERENCIA_TED_AGENDADA' ||
      historico[0]?.evento.identificador == 'TRANSFERENCIA_PIX' ||
      historico[0]?.evento.identificador == 'TRANSFERENCIA_PIX_AGENDADA'
    ) {
      api_multipague_transacao
        .get(`/transferencia/${historico[0].codigoTransferencia}`)
        .then(response => {
          setDataCadastroeStatus({
            usuarioCadastro: response.data.loginUsuario,
            dataCadastro: response.data.dataCadastro,
            status: response.data.status,
            descricaoHistorico: response.data.descricaoHistorico,
          });
        })
        .catch(err => {
          toastComponentError(err.response?.data?.mensagem, useTheme());
        });
    } else {
      setDataCadastroeStatus({
        usuarioCadastro: historico[0]?.loginUsuario,
        dataCadastro: historico[0]?.dataCadastro,
        status: historico[0]?.statusObjetoTransacao,
      });
    }
  }, []);

  return (
    <Wrapper
      goBack={() =>
        history.push('/backoffice/esteira-back', { formInfo: formInfo })
      }
      arrowBack
      title={'Histórico de ' + historico[0].evento.descricao}
      crumb={[
        { name: 'Home', link: '/backoffice' },
        { name: 'Esteira', link: '/backoffice/esteira-back' },
      ]}
    >
      <Paper className={classes.paperTimeline}>
        <Box className={classes.boxTimeline}>
          {historico[0]?.beneficiarioFavorecido && (
            <>
              <Typography
                variant="body2"
                className={classes.stateHistoricoFavorecico}
              >
                <span> Favorecido: </span>{' '}
                {historico[0]?.beneficiarioFavorecido?.nome}
              </Typography>
              <Typography variant="body2" className={classes.stateHistorico}>
                <span>
                  {historico[0]?.beneficiarioFavorecido?.conta
                    ? 'Conta:'
                    : 'Banco'}{' '}
                </span>
                {historico[0]?.beneficiarioFavorecido.banco.nome}
                {historico[0]?.beneficiarioFavorecido?.agencia
                  ? ' - ' + historico[0]?.beneficiarioFavorecido?.agencia
                  : ''}
                {historico[0]?.beneficiarioFavorecido?.conta
                  ? ' - ' + historico[0]?.beneficiarioFavorecido?.conta
                  : ''}
                {historico[0]?.beneficiarioFavorecido?.digitoConta
                  ? ' - ' + historico[0]?.beneficiarioFavorecido?.digitoConta
                  : ''}
              </Typography>
            </>
          )}
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Valor: </span>
            {historico[0]?.valor?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Cadastrado por: </span>
            {dataCadastroeStatus?.usuarioCadastro}
          </Typography>
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Data do Cadastro: </span>
            {moment(dataCadastroeStatus?.dataCadastro).format(
              'DD/MM/YYYY HH:mm:ss',
            )}
          </Typography>
          <Typography variant="body2" className={classes.stateHistorico}>
            <span>Data do Pagamento: </span>
            {moment(historico[0]?.dataPagamento).format('DD/MM/YYYY')}
          </Typography>
          <Typography
            variant="body2"
            className={classes.stateHistoricoSituacao}
          >
            <span>Situação: </span>
            {dataCadastroeStatus?.status}
          </Typography>
          {historico[0]?.evento.identificador != 'CADASTRAR_FAVORECIDO' && (
            <Typography variant="body2" className={classes.stateHistorico}>
              <span>Descrição Histórico: </span>
              {dataCadastroeStatus?.descricaoHistorico}
            </Typography>
          )}
        </Box>
        <Grid container className={classes.timeline}>
          {historico.map((item, i) => (
            <Grid
              item
              md={
                historico?.length === 1 ? 12 : historico?.length === 2 ? 6 : 4
              }
              xl={
                historico?.length === 1 ? 12 : historico?.length === 2 ? 6 : 4
              }
              lg={
                historico?.length === 1 ? 12 : historico?.length === 2 ? 6 : 4
              }
              sm={12}
              xs={12}
              className={classes.divHistoricoWithIconAndTimeline}
            >
              <div className={classes.divHistoricoIcon}>
                {!timelineVisivel[i] && (
                  <Typography variant="body2" className={classes.textHistory}>
                    <Typography variant="body2">
                      Nome Esteira: {item?.clienteConta?.nome}
                    </Typography>
                    <Typography variant="body2">
                      {'Data: '}
                      {item.dataTramitacao === null
                        ? item.situacao
                        : moment(item.dataTramitacao).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                    </Typography>
                  </Typography>
                )}
                <IconButton
                  className={classes.iconHistory}
                  onClick={() => handleClick(i)}
                >
                  {timelineVisivel[i] ? (
                    <HistoryOutlined
                      style={{ color: useTheme().palette.primary.main }}
                      size={24}
                    />
                  ) : (
                    <Tooltip title="Ver mais" placement="bottom">
                      <Add color="primary" size={54} />
                    </Tooltip>
                  )}
                </IconButton>
              </div>
              {timelineVisivel[i] && (
                <Timeline className={classes.timelinePadding} key={item.id}>
                  <TimelineItem key={item.loginTramitador}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">{'Usuário'}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2">
                        {item?.usuarioTramitador === null
                          ? '-'
                          : item?.usuarioTramitador}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem key={item?.clienteCorrentista?.nome}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">{'Nome Esteira'}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="grey" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="body2"
                        className={classes.tipographyClienteCorrentistaNome}
                      >
                        {item?.clienteConta?.nome}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  {item?.parecer != null && (
                    <TimelineItem key={item.parecer}>
                      <TimelineOppositeContent color="secondary">
                        <Typography variant="body2">{'Parecer'}</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="grey" />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="body2">{item?.parecer}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )}
                  <TimelineItem key={item.situacao}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">{'Situação'}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="grey" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2">{item.situacao}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem key={item.dataTramitacao}>
                    <TimelineOppositeContent color="secondary">
                      <Typography variant="body2">
                        {'Data e Hora da Interação'}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="grey" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2">
                        {item.dataTramitacao === null
                          ? item.situacao
                          : moment(item.dataTramitacao).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              )}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Wrapper>
  );
};

export default HistoricoTeds;
