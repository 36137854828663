import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  top: 0px;
  left: 0px;
  ${props =>
    props.showPopUp &&
    css`
      display: flex;
    `}
`;
