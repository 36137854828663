import { Paper, alpha } from '@material-ui/core';
import { styled as MuyStyled } from '@material-ui/core';


export const Container = MuyStyled('div')(({ theme }) => {
  return {
    '&  .MuiPaper-root': {
      overflow: 'auto',
      boxShadow: 'none',
      textTransform: 'capitalize',
      padding: '1rem',
      border: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontFamily: 'Helvetica',
      wordWrap: 'break-word',
      border: 'none',
      lineHeight: '110%',
      fontSize: '13px',
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'break-spaces',
    },
    '&  .MuiDataGrid-columnHeaderWrapper': {
      border: 'none',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      backgroundColor: alpha(theme.palette.primary.dark, 0.2),
    },

    '& .MuiDataGrid-root': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: theme.palette.background.paper,
    },

    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'hidden',
      backgroundColor: 'transparent',
      width: '1px',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
      // '&::before': {
      //   content: "''",
      //   width: '1px',
      //   marginTop: '25px',
      //   marginLeft: '11px',
      //   display: 'block',
      //   height: '45px',
      //   backgroundColor: alpha(theme.palette.text.disabled, 0.2),
      // },
    },

    '& .mfe-MuiTablePagination-root': {
      backgroundColor: theme.palette.background.paper,
      scrollbarColor: theme.palette.primary.main,
      scrollbarWidth: 'thin',

      '&::-webkit-scrollbar': {
        width: '8px',
        maxHeight: '8px',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
      },
    },

    '& .MuiDataGrid-window': {
      overflowX: 'visible',
      scrollbarWidth: 'thin',
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0)'
          : 'rgba(255, 255, 255, 0)',

      '&::-webkit-scrollbar': {
        width: '8px',
        maxHeight: '8px',
      },

      '&::-webkit-scrollbar-track-piece': {
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.background.default
            : theme.palette.background.dark,
        borderRadius: '3px',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
      },
    },

    '& .MuiDataGrid-overlay': {
      color: theme.palette.type === 'light' ? '#000' : '#fff',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },

    '& .headerButtons': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
      marginBottom: '1rem',
    },
  };
});
