/* eslint react/prop-types: 0 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IconButton, Tooltip } from '@material-ui/core';
import { Container, useStyles } from './styles';

const PlusButton = ({
  variant,
  color,
  disabled,
  children,
  title,
  placement,
  width,
  isLoading,
  startIcon,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={title ? title : children}
      placement={placement ? placement : 'bottom'}
    >
      <IconButton
        disabled={disabled}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        className={classes.loadingButton}
        {...rest}
      >
        {startIcon}
      </IconButton>
    </Tooltip>
  );
};

export default PlusButton;
