import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';

import { MdArrowBack, MdSave } from 'react-icons/md';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import { saveService } from '../services';
import { useStyles } from '../styles';
import { FormControlLabel, Switch } from '@material-ui/core';

export default function CadastroServico() {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [cadastroServico, setCadastroServico] = useState({
    descricao: '',
    ativo: false,
  });

  const handleSalvar = () => {
    setLoadButton(true);

    saveService(cadastroServico)
      .then(() => {
        setLoadButton(false);
        toastComponentSuccess('Cadastro feito com sucesso!', theme);
        history.push('/backoffice/servicos');
      })
      .catch(error => {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      title="Cadastrar serviço"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/servicos', name: 'Serviços' },
      ]}
      crumbActive="Cadastro"
    >
      <Box className={classes.boxBorderRadius}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              value={cadastroServico.descricao}
              label="Descrição"
              id="outlined-size-small"
              onChange={event => {
                setCadastroServico(prev => ({
                  ...prev,
                  descricao: event?.target?.value,
                }));
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Switch
                  onChange={event =>
                    setCadastroServico(prev => ({
                      ...prev,
                      ativo: !cadastroServico.ativo,
                    }))
                  }
                  checked={cadastroServico.ativo}
                  color="primary"
                />
              }
              label="Ativo"
            />
          </Grid>
        </Grid>
        <div className={classes.divButtonBackSave}>
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.goBack();
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
