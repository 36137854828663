import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import { editTerm, getTerms, saveTerms } from '../services';

export const initialOpenModal = {
  open: null,
  id: '',
  term: '',
  conta: '',
  service: '',
};
export const initialLoadingDatagrid = {
  servico: false,
  conta: false,
};

export const fetchTerms = (setLoadButton, setRows, theme) => {
  setLoadButton(true);
  getTerms()
    .then(data => {
      setLoadButton(false);
      if (!!data) {
        const aquiTable = data.map(data => ({
          id: data.id,
          assunto: data.assunto,
          observacao: data.observacao,
          template: data.template,
        }));
        setRows(aquiTable);
      }
    })
    .catch(error => {
      setLoadButton(false);
      if (
        error.response.status == 400 ||
        error.response.status == 404 ||
        error.response.status == 409 ||
        error.response.status == 500
      ) {
        toastComponentError(error.response.data.mensagem, theme);
      }
    });
};

export const handleSalvar = (setLoadButton, saveServiceTermsFunc, theme) => {
  setLoadButton(true);
  saveServiceTermsFunc()
    .then(() => {
      setLoadButton(false);
      toastComponentSuccess('Serviços vinculados com sucesso!', theme);
    })
    .catch(error => {
      if (
        error.response.status == 400 ||
        error.response.status == 404 ||
        error.response.status == 500
      ) {
        setLoadButton(false);
        toastComponentError(error.response.data.mensagem, theme);
      }
    });
};

export const handleServices = (
  setLoadingDatagrid,
  getTermsServices,
  setDadosTermo,
  setRows,
) => {
  setLoadingDatagrid(prev => ({ ...prev, servico: true }));
  getTermsServices()
    .then(data => {
      setDadosTermo(prev => ({
        ...prev,
        servicos: data.map(item => item.id),
      }));
      setLoadingDatagrid(prev => ({ ...prev, servico: false }));
      const rows = data.map(item => ({
        id: item?.id,
        descricao: item?.descricao,
        ativo: item?.ativo,
      }));
      setRows(rows);
    })
    .catch(error => {
      console.log('error', error);
      setLoadingDatagrid(prev => ({ ...prev, servico: false }));
      setRows([]);
      setDadosTermo(prev => ({
        ...prev,
        servicos: [],
      }));
    });
};

export const handleSalvarTermo = (
  setLoadButton,
  dadosTermo,
  setDadosTermo,
  setShowServicoeTermos,
  setShowContaeTermos,
  theme,
) => {
  setLoadButton(true);
  !!dadosTermo?.id
    ? editTerm(dadosTermo?.id, {
        assunto: dadosTermo?.assunto,
        id: dadosTermo?.id,
        observacao: dadosTermo?.observacao,
        template: dadosTermo?.template,
      })
        .then(data => {
          toastComponentSuccess('Termo editado com sucesso!', theme);
          setLoadButton(false);
          setShowServicoeTermos(true);
          setShowContaeTermos(true);
          setDadosTermo({
            assunto: dadosTermo?.assunto,
            id: dadosTermo?.id,
            observacao: dadosTermo?.observacao,
            template: dadosTermo?.template,
          });
        })
        .catch(error => {
          console.log(error);
          setLoadButton(false);
          setShowServicoeTermos(false);
          setShowContaeTermos(false);
          if (
            error.response.status == 400 ||
            error.response.status == 404 ||
            error.response.status == 500
          ) {
            toastComponentError(error.response.data.mensagem, theme);
          }
        })
    : saveTerms(dadosTermo)
        .then(data => {
          setLoadButton(false);
          toastComponentSuccess('Termo salvo com sucesso!', theme);
          setShowServicoeTermos(true);
          setShowContaeTermos(true);
          setDadosTermo({
            id: data?.id,
            assunto: data?.assunto,
            observacao: data?.observacao,
            template: data?.template,
            servicos: data?.servicos,
            contas: data?.contas,
          });
        })
        .catch(error => {
          console.log(error);
          setLoadButton(false);
          setShowServicoeTermos(false);
          setShowContaeTermos(false);

          if (
            error.response.status == 400 ||
            error.response.status == 404 ||
            error.response.status == 500
          ) {
            toastComponentError(error.response.data.mensagem, theme);
          }
        });
};

export const fetchContas = (
  setLoadingDatagrid,
  setDadosTermo,
  getTermsContas,
  setRowsContas,
) => {
  setLoadingDatagrid(prev => ({ ...prev, conta: true }));
  getTermsContas()
    .then(data => {
      setDadosTermo(prev => ({
        ...prev,
        contas: data.map(item => item.id),
      }));
      setLoadingDatagrid(prev => ({ ...prev, conta: false }));
      const rows = data.map(item => ({
        id: item?.id,
        nome: item?.cliente?.nome,
        cnpjCpf: item?.cliente?.cnpjCpf,
        conta: `${item.agencia} ${item?.conta}-${item?.digitoConta}`,
      }));
      setRowsContas(rows);
    })
    .catch(error => {
      console.log(error);
      setLoadingDatagrid(prev => ({ ...prev, conta: false }));
      setRowsContas([]);
      setDadosTermo(prev => ({
        ...prev,
        contas: [],
      }));
    });
};

export const featchDeleteTermService = async (
  deleteTermService,
  setOpenModalDelete,
  fetchServices,
  theme,
) => {
  await deleteTermService()
    .then(() => {
      setOpenModalDelete(initialOpenModal);
      toastComponentSuccess('Serviço deletado com sucesso do termo!', theme);
      fetchServices();
    })
    .catch(error => {
      setOpenModalDelete(initialOpenModal);
      if (error.response.status == 400 || error.response.status == 500) {
        toastComponentError(error.response.data.mensagem, theme);
      }
    });
};

export const featchDeleteTermConta = async (
  deleteTermConta,
  setOpenModalDelete,
  fetchContas,
  theme,
) => {
  await deleteTermConta()
    .then(() => {
      setOpenModalDelete(initialOpenModal);
      toastComponentSuccess('Conta deletada com sucesso do termo!', theme);
      fetchContas();
    })
    .catch(error => {
      setOpenModalDelete(initialOpenModal);
      if (error.response.status == 400 || error.response.status == 500) {
        toastComponentError(error.response.data.mensagem, theme);
      }
    });
};

export const handleDeleteAllTermService = async (
  deleteAllTermService,
  fetchServices,
  theme,
) => {
  await deleteAllTermService()
    .then(() => {
      toastComponentSuccess('Serviços deletados com sucesso do termo!', theme);
      fetchServices();
    })
    .catch(error => {
      if (error.response.status == 400 || error.response.status == 500) {
        toastComponentError(error.response.data.mensagem, theme);
      }
    });
};

export const handleDeleteAllTermAccounts = async (
  deleteAllTermAccounts,
  fetchAccounts,
  theme,
) => {
  await deleteAllTermAccounts()
    .then(() => {
      toastComponentSuccess('Contas deletadas com sucesso do termo!', theme);
      fetchAccounts();
    })
    .catch(error => {
      if (error.response.status == 400 || error.response.status == 500) {
        toastComponentError(error.response.data.mensagem, theme);
      }
    });
};
