import React, { useCallback, useEffect, useState } from 'react';
import {
  downloadPDFCSV,
  downloadPDFCSVSelecionados,
  getSaldos,
} from './service';
import { Chip, TextField, Checkbox, useTheme, Grid } from '@material-ui/core';
import { maskCNPJ } from '../../util/mask';
import formattedMoney from '../../util/FormatteMoney';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import { IndeterminateCheckBox } from '@material-ui/icons';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { useHistory } from 'react-router-dom';

const PainelSaldos = () => {
  const history = useHistory();
  const theme = useTheme();
  const [sort, setSort] = useState('ASC');
  const [sortParam, setSortParam] = useState('cliente.dataSolicitacao');
  const [rowsCached, setRowsCached] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [rowsState, setRowsState] = React.useState({ page: 0, size: 10 });

  const [carregandoCSVPDF, setCarregandoCSVPDF] = useState({
    carregaExcel: false,
    carregaIconBotaoExcel: false,
    carregaPDF: false,
    carregaIconPDF: false,
  });

  const initialForm = {
    cnpjCpf: '',
    nome: '',
  };

  const [form, setForm] = useState(initialForm);

  const checkAllIndeterminate = () => {
    if (!!checkedAll) {
      return false;
    }
    return (
      selectionModel?.length > 0 && selectionModel?.length < rowsCached?.length
    );
  };

  const columns = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return (
          <Checkbox
            checked={checkedAll}
            onChange={(_e, value) => {
              if (!!value) {
                setCheckedAll(true);
                if (rowCount !== rowsCached.length) {
                  setRowCount(0);
                  setRowsCached([]);
                  fetchPainelSaldo(form, 0, 10, {});
                }
              } else {
                setCheckedAll(false);
                setSelectionModel([]);
              }
            }}
            indeterminate={checkAllIndeterminate()}
            indeterminateIcon={<IndeterminateCheckBox color="primary" />}
            color="primary"
          />
        );
      },
      renderCell: event => {
        return (
          <Checkbox
            checked={!!selectionModel.includes(event.id)}
            onChange={(_e, checked) => {
              if (!checked) {
                setCheckedAll(false);
                setSelectionModel(old => old.filter(id => id !== event.id));
              } else {
                if (rowsCached.length === 1) {
                  setCheckedAll(true);
                }
              }
            }}
            color="primary"
          />
        );
      },
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 150,
      renderHeader: () => {
        return (
          <div
            style={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              setSort(sort === 'DESC' ? 'ASC' : 'DESC');
              setSortParam('cliente.cnpjCpf');
            }}
          >
            CNPJ
          </div>
        );
      },
    },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      width: 250,
      flex: 1,
      minWidth: 250,
      renderCell: event => {
        return (
          <div
            style={{
              lineHeight: '150%',
              height: '50px',
              alignItems: 'center',
              display: 'flex',
              fontSize: event?.row?.razaoSocial?.length > 40 ? '12px' : '13px',
              flexWrap: 'wrap',
              whiteSpace: 'break-spaces',
            }}
          >
            {event?.row?.razaoSocial}
          </div>
        );
      },
      renderHeader: () => {
        return (
          <div
            style={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              setSort(sort === 'DESC' ? 'ASC' : 'DESC');
              setSortParam('cliente.nome');
            }}
          >
            Razão Social
          </div>
        );
      },
    },
    {
      field: 'conta',
      headerName: 'Conta',
      type: 'text',
      width: 130,
      renderHeader: () => {
        return (
          <div
            style={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              setSort(sort === 'DESC' ? 'ASC' : 'DESC');
              setSortParam('conta');
            }}
          >
            Conta
          </div>
        );
      },
      renderCell: event => {
        return (
          <div
            style={{
              lineHeight: '150%',
              height: '50px',
              alignItems: 'center',
              display: 'flex',
              fontSize: event?.row?.conta?.length > 30 ? '12px' : '13px',
              flexWrap: 'wrap',
              whiteSpace: 'break-spaces',
            }}
          >
            {event.row.conta || '-'}
            {event.row.error && (
              <Chip
                size="small"
                variant="outlined"
                label={event.row.error}
                color="secondary"
                style={{ fontSize: '10px' }}
              />
            )}
          </div>
        );
      },
    },

    {
      field: 'tipoControle',
      headerName: 'Controle',
      width: 110,
      renderCell: event => {
        return (
          <div
            style={{
              lineHeight: '150%',
              height: '50px',
              alignItems: 'center',
              display: 'flex',
              fontSize: event?.row?.tipoControle?.length > 7 ? '12px' : '13px',
              flexWrap: 'wrap',
              whiteSpace: 'break-spaces',
            }}
          >
            {event?.row?.tipoControle}
          </div>
        );
      },
    },
    {
      field: 'dataAbertura',
      headerName: 'Data Abertura',
      width: 150,
      renderHeader: () => {
        return (
          <div
            style={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              setSort(sort === 'DESC' ? 'ASC' : 'DESC');
              setSortParam('cliente.dataAberturaConta');
            }}
          >
            Data Abertura
          </div>
        );
      },
    },
    {
      field: 'dataUltimaMov',
      headerName: 'Data Última Mov.',
      width: 150,
    },
    {
      field: 'saldo',
      headerName: 'Saldo',
      width: 150,
      renderCell: event => {
        return (
          <div
            style={{
              lineHeight: '150%',
              height: '50px',
              alignItems: 'center',
              display: 'flex',
              fontSize:
                formattedMoney(event?.row?.saldo)?.toString()?.length > 15
                  ? '10px'
                  : '13px',
              flexWrap: 'wrap',
              whiteSpace: 'break-spaces',
            }}
          >
            {formattedMoney(event.row.saldo)}
          </div>
        );
      },
    },
  ];

  const fetchPainelSaldo = useCallback(
    async (params = {}, page = 0, size = 10, header) => {
      setRowsState({ page, size });
      setLoading(true);
      const data = await getSaldos(
        {
          ...params,
          page,
          sort,
          sortParam,
          size,
        },
        header,
      );
      setRows(data.content);
      setRowsCached(old => {
        return old.concat(data.content);
      });
      setRowCount(data.total);
      setLoading(false);
    },
    [sort, sortParam],
  );

  const handleInput = e => {
    const value =
      e.target.name === 'cnpjCpf' ? maskCNPJ(e.target.value) : e.target.value;
    setForm(old => ({ ...old, [e.target.name]: value }));
  };

  useEffect(() => {
    fetchPainelSaldo();
  }, [fetchPainelSaldo, sort, sortParam]);

  useEffect(() => {
    setCarregandoCSVPDF({
      carregaExcel: false,
      carregaIconBotaoExcel: false,
      carregaPDF: false,
      carregaIconPDF: false,
    });
  }, [form, selectionModel]);

  useEffect(() => {
    if (checkedAll) {
      setSelectionModel([...new Set(rowsCached?.map(item => item.id))]);
    }
  }, [checkedAll, rowsCached]);

  const filterPainelSaldo = (page, pageSize) => {
    if (form.cnpjCpf) {
      form.cnpjCpf = form.cnpjCpf.replace(/\D/g, '');
    }
    fetchPainelSaldo(form, page, pageSize);
  };

  const fetchAsyncFile = async (razaoSocial = '', cnpjCpf = '', tipo) => {
    if (rows.length === 0) {
      toastComponentError('Nenhum boleto selecionado', theme);
    } else if (!checkedAll && selectionModel.length > 0) {
      if (tipo === 'XLSX') {
        setCarregandoCSVPDF(prev => ({
          ...prev,
          carregaExcel: true,
          carregaIconBotaoExcel: true,
        }));
        const data = await downloadPDFCSVSelecionados(selectionModel, tipo);

        if (data) {
          toastComponentSuccess('Arquivo gerado com sucesso', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaExcel: false,
            carregaIconBotaoExcel: false,
          }));
        } else {
          toastComponentError('Erro ao gerar arquivo', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaExcel: false,
            carregaIconBotaoExcel: false,
          }));
        }
      } else {
        setCarregandoCSVPDF(prev => ({
          ...prev,
          carregaPDF: true,
          carregaIconPDF: true,
        }));
        const data = await downloadPDFCSVSelecionados(selectionModel, tipo);

        if (data) {
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaPDF: false,
            carregaIconPDF: false,
          }));
          toastComponentSuccess('Arquivo gerado com sucesso', theme);
        } else {
          toastComponentError('Erro ao gerar arquivo', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaPDF: false,
            carregaIconPDF: false,
          }));
        }
      }
    } else {
      if (tipo === 'XLSX') {
        setCarregandoCSVPDF(prev => ({
          ...prev,
          carregaExcel: true,
          carregaIconBotaoExcel: true,
        }));
        const data = await downloadPDFCSV(razaoSocial, cnpjCpf, tipo);

        if (data) {
          toastComponentSuccess('Arquivo gerado com sucesso', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaExcel: false,
            carregaIconBotaoExcel: false,
          }));
        } else {
          toastComponentError('Erro ao gerar arquivo', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaExcel: false,
            carregaIconBotaoExcel: false,
          }));
        }
      } else {
        setCarregandoCSVPDF(prev => ({
          ...prev,
          carregaPDF: true,
          carregaIconPDF: true,
        }));
        const data = await downloadPDFCSV(razaoSocial, cnpjCpf, tipo);

        if (data) {
          toastComponentSuccess('Arquivo gerado com sucesso', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaPDF: false,
            carregaIconPDF: false,
          }));
        } else {
          toastComponentError('Erro ao gerar arquivo', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaPDF: false,
            carregaIconPDF: false,
          }));
        }
      }
    }
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="Razão Social"
            value={form.nome}
            variant="outlined"
            size="small"
            name="nome"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 5 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="CNPJ"
            value={form.cnpjCpf}
            variant="outlined"
            size="small"
            name="cnpjCpf"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 5 },
      },
    ],
    searchButton: {
      searchButton: () => filterPainelSaldo(0, rowsState.size),
    },
    cleanButton: {
      cleanButton: () => {
        setForm(initialForm);
        setSelectionModel([]);
        fetchPainelSaldo({}, 0, rowsState.size);
      },
    },
  };

  const idsPodeSelecionar = React.useMemo(() => {
    return rowsCached.map(item => item.id);
  }, [rowsCached]);

  return (
    <DataGridWrapper
      PDFCSVCustomer={{
        csvExcel: 'XLSX',
        downloadExcel: () => fetchAsyncFile(form?.nome, form?.cnpjCpf, 'XLSX'),
        downloadPDF: () => fetchAsyncFile(form?.nome, form?.cnpjCpf, 'PDF'),
        rotaArquivosGerados: () =>
          history.push('/backoffice/arquivos-gerados-saldos'),
        title: 'Monitor',
        carregaExcel: carregandoCSVPDF.carregaExcel,
        carregaIconExcel: carregandoCSVPDF.carregaIconBotaoExcel,
        carregaPDF: carregandoCSVPDF.carregaPDF,
        carregaIconPDF: carregandoCSVPDF.carregaIconPDF,
      }}
      headerComponent={() => (
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            style={{
              display: 'flex',
              gap: 20,
              flexWrap: 'wrap',
            }}
          >
            <Chip
              color="primary"
              onClick={() => {
                history.push('/backoffice/arquivos-gerados-saldos');
              }}
              variant="outlined"
              style={{ width: '100%', cursor: 'pointer' }}
              label="Consultar arquivos gerados"
            />
          </Grid>
        </Grid>
      )}
      title={'Painel de Saldos'}
      crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
      rows={rows}
      formData={filterInputs}
      key={rows?.map(item => item?.id)}
      columns={columns}
      loading={loading}
      styleFormBackground={{ display: 'flex', justifyContent: 'space-between' }}
      pageSize={rowsState.size}
      minHeight="600px"
      pagination
      page={rowsState.page}
      checkboxSelection
      componentsProps={{
        pagination: { classes: null },
      }}
      onSelectionModelChange={newSelection => {
        const ids = Object.values(newSelection);
        const newIds = ids.filter(
          id => !selectionModel.includes(id) && idsPodeSelecionar.includes(id),
        );
        setSelectionModel(old => [...old, ...newIds]);
      }}
      onPageSizeChangeFunction={newPageSize => {
        filterPainelSaldo(0, newPageSize);
      }}
      rowsPerPageOptions={[10, 20, 50, 100]}
      paginationMode="server"
      paginate
      selectionModel={selectionModel}
      rowCount={rowCount}
      onPageChange={newPage => {
        filterPainelSaldo(newPage, rowsState.size);
      }}
    />
  );
};

export default PainelSaldos;
