import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

import { api_multipague_configuracao } from '../../../../services/api';
import { MdArrowBack, MdSave } from 'react-icons/md';

import Box from '../../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../../components/Toast';
import Wrapper from '../../../../components/Wrapper';
import { Divider, Typography } from '@material-ui/core';

export default function CadastroEventoTarifador() {
  const history = useHistory();
  const theme = useTheme();
  const historyState = JSON.parse(localStorage.getItem('segmento-tarifador'));
  const [loadButton, setLoadButton] = useState(false);
  const [valuesEventoTarifador, setValuesEventoTarifador] = useState({
    descricao: '',
    faixaFinal: 0,
    faixaInicial: 0,
    valorCusto: 0,
    valorTarifa: 0,
  });

  const handleSalvar = () => {
    setLoadButton(true);

    api_multipague_configuracao
      .post(`/segmento/%7BidSegmento%7D/evento/${historyState?.id}/faixa`, {
        descricao: valuesEventoTarifador.descricao,
        faixaFinal: Number(valuesEventoTarifador.faixaFinal),
        faixaInicial: Number(valuesEventoTarifador.faixaInicial),
        valorCusto: Number(valuesEventoTarifador.valorCusto),
        valorTarifa: Number(valuesEventoTarifador.valorTarifa),
      })

      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Cadastro feito com sucesso!', theme);
        history.push('/backoffice/evento-tarifador-editar');
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      title="Cadastrar Faixa Tarifa"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/tarifador', name: 'Tarifadores' },
        { link: '/backoffice/tarifador-editar', name: 'Eventos Tarifadores' },
        {
          link: '/backoffice/evento-tarifador-editar',
          name: 'Editar Evento Tarifador',
        },
      ]}
      crumbActive="Cadastro"
    >
      <Box style={{ borderRadius: '20px', padding: 25 }}>
        <Grid container style={{ padding: '10px 0 20px' }}>
          <Grid item md={12}>
            <Typography variant="body1" style={{ fontSize: 15 }}>
              Segmento Tarifador:{' '}
            </Typography>
            <Typography
              style={{ fontWeight: '600', fontSize: 15, marginLeft: 2 }}
              variant="body1"
            >
              {historyState?.segmentoTarifador?.descricao}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: 15 }}>
              Evento:{' '}
            </Typography>
            <Typography
              style={{ fontWeight: '600', fontSize: 15, marginLeft: 2 }}
              variant="body1"
            >
              {historyState?.evento?.descricao}
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={3} style={{ paddingTop: 25 }}>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              onChange={event => {
                const descricao = event?.target?.value;
                setValuesEventoTarifador(prev => ({
                  ...prev,
                  descricao: descricao || '',
                }));
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Faixa Inicial"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={valuesEventoTarifador.faixaInicial}
              onChange={event => {
                const faixaInicial = event?.target?.value;
                setValuesEventoTarifador(prev => ({
                  ...prev,
                  faixaInicial: faixaInicial,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Faixa Final"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={valuesEventoTarifador.faixaFinal}
              onChange={event => {
                const faixaFinal = event?.target?.value;
                setValuesEventoTarifador(prev => ({
                  ...prev,
                  faixaFinal: faixaFinal,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              label="Valor Custo"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={valuesEventoTarifador.valorCusto}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(_, value) => {
                setValuesEventoTarifador(prev => ({
                  ...prev,
                  valorCusto: value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              label="Valor Tarifa"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={valuesEventoTarifador.valorTarifa}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(_, value) =>
                setValuesEventoTarifador(prev => ({
                  ...prev,
                  valorTarifa: value,
                }))
              }
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/evento-tarifador-editar');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
