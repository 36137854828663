import React from 'react';
import Wrapper from '../../components/Wrapper';
import { MonitorEsccrowProvider } from './useMonitor';
import Content from './Content';
import { useTheme } from '@material-ui/core';

const MonitorAprovacao = () => {
  const theme = useTheme();
  return (
    <MonitorEsccrowProvider theme={theme}>
      <Content />
    </MonitorEsccrowProvider>
  );
};

export default MonitorAprovacao;
