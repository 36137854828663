import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { api_multipague_configuracao } from '../../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import DataGridWrapper from '../../../components/DataGrid/DataGridWrapper';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import { useTheme } from '@material-ui/core';
import RenderActionsCell from '../../../components/DataGrid/DataGridWrapper/Actions';

export default function AvisosLista() {
  const history = useHistory();
  const theme = useTheme();
  const [openMenuActions, setOpenMenuActions] = useState();
  const [rowId, setRowId] = useState(null);

  const columns = [
    { field: 'observacao', headerName: 'Descrição', flex: 3, minWidth: 200 },
    {
      field: 'ativo',
      headerName: 'Status',
      flex: 2,
      minWidth: 110,
      renderCell: event => {
        return event.row.ativo == true ? 'ATIVO' : 'DESATIVADO';
      },
    },
    {
      field: 'vigenciaInicio',
      headerName: 'Data Vigência Início',
      flex: 3,
      minWidth: 110,
    },
    {
      field: 'vigenciaFim',
      headerName: 'Data Vigência Fim',
      flex: 3,
      minWidth: 110,
    },
    {
      field: 'editar',
      headerName: 'Ações',
      type: 'text',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        return (
          <RenderActionsCell
            event={params}
            actionsComponent={
              <>
                <Tooltip title="Editar">
                  <EditIcon
                    style={{ cursor: 'pointer' }}
                    color="primary"
                    size={20}
                    onClick={() => {
                      handleEditar(params.row);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Deletar">
                  <DeleteIcon
                    style={{ cursor: 'pointer' }}
                    color="error"
                    size={20}
                    onClick={() => {
                      handleDeletar(params.row);
                    }}
                  />
                </Tooltip>
              </>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);
  const [loadButton, setLoadButton] = useState(false);
  const [situacao, setSituacao] = useState('');
  const [detalhe, setDetalhe] = useState('');
  const [dataVigenciaDe, setDataVigenciaDe] = useState();

  const cleanFilters = () => {
    setSituacao('');
    setDataVigenciaDe('');
    setDetalhe('');
  };
  const handleSituacao = event => {
    setSituacao(event.target.value);
  };
  const handleVigenciaDe = event => {
    setDataVigenciaDe(event.target.value);
  };
  function novoAviso() {
    history.push('/backoffice/avisos-cadastro');
  }
  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/avisos`;
  const URLsearch = `/avisos?ativo=${
    situacao == 'ATIVADO' ? true : situacao == '' ? '' : false
  }&vigenteEm=${
    dataVigenciaDe != null ? dataVigenciaDe : ''
  }&observacao=${detalhe}`;
  const URLclean = `/avisos?ativo=${''}&vigenteEm=${''}&observacao=${''}`;

  useEffect(() => {
    setLoadButton(true);
    api_multipague_configuracao
      .get(URL, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        const aquiTable = response.data.content.map(data => ({
          ...data,
          id: data.id,
          ativo: data.ativo,
          caminhoStorage: data.caminhoStorage,
          observacao: data.observacao,
          urlRedirecionamento: data.urlRedirecionamento,
          vigenciaInicio: moment(new Date(data.vigenciaInicio)).format(
            'DD/MM/YYYY',
          ),
          vigenciaFim: moment(new Date(data.vigenciaFim)).format('DD/MM/YYYY'),
        }));
        setRows(aquiTable);
        console.log('Aqui Will: ', aquiTable);
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }, []);

  function pesquisar(url) {
    setLoadButton(true);
    api_multipague_configuracao
      .get(url, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        const aquiTable = response.data.content.map(data => ({
          ...data,
          id: data.id,
          ativo: data.ativo,
          caminhoStorage: data.caminhoStorage,
          observacao: data.observacao,
          urlRedirecionamento: data.urlRedirecionamento,
          vigenciaInicio: moment(new Date(data.vigenciaInicio)).format(
            'DD/MM/YYYY',
          ),
          vigenciaFim: moment(new Date(data.vigenciaFim)).format('DD/MM/YYYY'),
        }));
        setRows(aquiTable);
        console.log('Aqui Will: ', aquiTable);
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  const handleDeletar = value => {
    setLoadButton(true);
    api_multipague_configuracao
      .delete(`/avisos/${value.id}`)
      .then(response => {
        setLoadButton(false);
        toastComponentSuccess('Aviso deletado!', theme);
        setTimeout(function () {
          location.reload();
        }, 3000);
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleEditar = value => {
    console.log('Dados Aviso: ', value);
    localStorage.setItem('idAviso', value.id);
    history.push('/backoffice/avisos-editar');
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="Descrição"
            id="outlined-size-small"
            onChange={e => {
              setDetalhe(e.target.value);
            }}
            value={detalhe}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 12, lg: 4 },
      },
      {
        html: () => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          >
            <InputLabel
              shrink
              style={{
                backgroundColor: theme.palette.background.paper,
                padding: '0 5px',
              }}
              id="demo-simple-select-outlined-label"
            >
              Ativo ?
            </InputLabel>
            <Select
              value={situacao}
              fullWidth
              label="Situação"
              id="outlined-size-small"
              variant="outlined"
              size="small"
              onChange={handleSituacao}
            >
              <MenuItem value="ATIVADO">ATIVADO</MenuItem>
              <MenuItem value="DESATIVADO">DESATIVADO</MenuItem>
            </Select>
          </FormControl>
        ),
        grid: { xs: 12, sm: 6, md: 6, lg: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Vigência"
            id="outlined-size-small"
            value={dataVigenciaDe}
            onChange={handleVigenciaDe}
            variant="outlined"
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 6, lg: 3 },
      },
    ],
    searchButton: {
      searchButton: () => pesquisar(URLsearch),
    },
    cleanButton: {
      cleanButton: () => {
        pesquisar(URLclean);
        cleanFilters();
      },
    },
  };

  return (
    <DataGridWrapper
      rows={rows}
      columns={columns}
      loading={loadButton}
      pageSize={10}
      newRegister={novoAviso}
      onRowClick={params => setRowId(params.id)}
      formData={filterInputs}
      title="Lista de Avisos"
      crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
      minHeight="600px"
    />
  );
}
