/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useField } from '@unform/core';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  FormContainer,
  Itens,
  Label,
  SelectOptions,
  MessageError,
} from './styles';

const SelectComponent = ({
  id,
  label,
  itens,
  name,
  valueDefault,
  callback,
  required,
  ...rest
}) => {
  const {
    fieldName,
    defaultValue,
    error,
    registerField,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current.children[1],
      path: 'value',
    });
  }, [fieldName, registerField]);

  const [valueSelected, setValueSelected] = useState(valueDefault);

  useEffect(() => {
    setValueSelected(valueDefault);
  }, [valueDefault]);
  
  const useStylesFormControl = makeStyles(theme =>
    createStyles({
      formControl: {
        width: '100%',
      },
      selectEmpty: {
        width: '100%',
        marginTop: theme.spacing(2),
      },
    }),
  );

  const classesFormControl = useStylesFormControl();

  const selectRef = useRef(null);

  const handleChange = event => {
    if (callback) {
      callback(event.target.value);
    }
  };

  return (
    <Container>
      <FormContainer
        className={classesFormControl.formControl}
        variant="outlined"
        size="small"
      >
        <Label id={id}>{label}</Label>
        <SelectOptions
          size="small"
          labelId={id + label}
          id={id}
          value={valueSelected}
          displayEmpty
          className={useStylesFormControl.selectEmpty}
          label={label}
          defaultValue={defaultValue}
          ref={selectRef}
          error={!!error}
          onFocus={clearError}
          onChange={handleChange}
          required={required}
          {...rest}
        >
          {/* <Itens value="">
            <em>Selecione...</em>
          </Itens> */}
          {itens.map(item => (
            <Itens key={item.value} value={item.value}>
              {item.label}
            </Itens>
          ))}
        </SelectOptions>
        {error && <MessageError>{error}</MessageError>}
      </FormContainer>
    </Container>
  );
};

export default SelectComponent;
