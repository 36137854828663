import React, { useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import { FaEllipsisV } from 'react-icons/fa';

const RenderActionsCell = ({
  event,
  actionsComponent,
  openMenuActions,
  setOpenMenuActions,
  rowId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpenMenuActions(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuActions(false);
  };

  const open = Boolean(anchorEl) && openMenuActions && event.row.id === rowId;
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ mt: 2 }}>
        <FaEllipsisV aria-describedby={id} variant="contained" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: '10px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {actionsComponent}
      </Popover>
    </>
  );
};

export default RenderActionsCell;
