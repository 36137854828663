import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  boxBorderRadius: {
    borderRadius: '20px',
    padding: 30,
  },
  wrapperBox: {
    borderRadius: '20px',
    padding: 40,
  },
  gridTypography: {
    display: 'flex',
    borderRadius: '20px',
    padding: '5px 20px',
    border: `1px solid ${theme.palette.divider}`,
  },
  divButtonBackSave: {
    marginTop: '1rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  btnAdd: {
    width: '25px',
    height: '25px',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.primary.dark, 0.8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: alpha(
      theme.palette.appBar[theme.palette.type].bgMenu,
      0.7,
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  divServicosBorder: {
    borderRadius: '20px',
    height: 300,
    padding: '0 10px',
    margin: '20px 0',
    overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`,
  },
  gridFlex: {
    display: 'flex',
  },
  gridPadding: { padding: '0 0 20px' },
  divListIntern: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectAllCheckbox: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    padding: '0 20px 0px 0px',
    borderRadius: '20px',
    zIndex: 9,
    marginLeft: '16px',
  },
  typographySize: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
}));
