import { alpha, makeStyles } from "@material-ui/core";

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  input: {
    display: 'none',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fff',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: '25%',
    overflow: 'scroll',
    height: '90vh',
    width: '90vw',
    display: 'block',
  },
  fixedHeight: {
    height: '300px',
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginRight: '25px',
  },
  searchButton: {
    float: 'right',
  },
  rootform: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  btnprint: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  btnpdf: {
    marginRight: '10px',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '10px',
    width: '100%',
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  positive: {
    color: theme.palette.primary.main,
  },
  grid: {
    marginBottom: '20px',
  },
  cancelIcon: {
    color: '#e9a645',
    cursor: 'pointer',
  },
  topoLegal: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapperBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
    padding: '1.5rem',
  },
}));
