import React, { useRef, useEffect, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function InputMask({
  name,
  isLoading,
  disabled = false,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [size, setSize] = useState(defaultValue?.length || 12);
  const [mask, setMask] = useState();

  useEffect(() => {
    if (defaultValue) {
      setSize(defaultValue.length);
    }
  }, [defaultValue]);

  function retornarLengthText(value) {
    console.log(inputRef.current.value);
    var num = value.replace(/[^\d]/g, '');
    console.log(num.length);
    setSize(num.length);
    if (num.length <= 11) {
      setMask('999.999.999-999');
    } else {
      setMask('99.999.999/9999-99');
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField, mask]);

  return (
    <ReactInputMask
      mask={'99.999.999/9999-99'}
      // onChange={ event => retornarLengthText(event.target.value) }
      maskChar=""
      ref={inputRef}
      defaultValue={defaultValue}
      onFocus={clearError}
      disabled={disabled}
      {...rest}
      // mask={ size <= 11 ?  '999.999.999-999' : '99.999.999/9999-99' }
      // mask={'99.999.999/9999-99'}
    >
      {() => (
        <TextField
          disabled={disabled}
          style={{ width: '100%' }}
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          label="CPF/CNPJ"
          helperText={error}
          error={!!error}
          InputProps={
            isLoading && {
              endAdornment: (
                <CircularProgress
                  color="inherit"
                  size={24}
                  circleDisableShrink
                />
              ),
            }
          }
          {...rest}
        />
      )}
    </ReactInputMask>
  );
}
