import React, { useEffect, useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Cookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { api_multipague_configuracao } from '../../services/api';
import imgSenha from '../../images/senha.png';

import DvrIcon from '@material-ui/icons/Dvr';
import LockIcon from '@material-ui/icons/Lock';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import Wrapper from '../../components/Wrapper';
import { Divider, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => {
  const colorBgMenu = theme.palette.appBar[theme.palette.type].bgMenu;
  const colorMonitor =
    theme.palette.type === 'dark'
      ? theme.palette.getContrastText(theme.palette.background.paper)
      : colorBgMenu;
  return {
    root: {
      display: 'flex',
    },
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(7),
    },

    corMonitor: {
      background: theme.palette.background.paper,
      borderRadius: '20px',
      transition: '0.1s ease',
      textTransform: 'none',
      alignItems: 'start',
      boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        '& .corMonitorImg': {
          color: theme.palette.getContrastText(theme.palette.primary.main),
        },
      },
      color: colorMonitor,
      '& .corMonitorImg': {
        color: colorMonitor,
      },
    },
    textStyle: {
      fontFamily: 'Helvetica, sans-serif',
      display: 'flex',
      justifyContent: 'center',
    },
    corMonitorDisabled: {
      background: theme.palette.background.paper,
      boxShadow: '0 22.8px 22.2px rgba(0, 0, 0, 0.034)',
      borderRadius: '20px',
    },
  };
});

export default function Backoffice() {
  const classes = useStyles();
  const cookies = new Cookies();
  const theme = useTheme();

  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput.id);
  const permissionUser = cookies.get('roles');
  const history = useHistory();

  useEffect(() => {
    api_multipague_configuracao
      .get('/usuario')
      .then(response => {
        console.log('Select do cadastro ', response.data.id);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Wrapper>
      <Container maxWidth="lg" className={classes.container}>
        {/* <ToastContainer/> */}
        <Grid spacing={3} style={{ padding: '0 0 1rem' }}>
          <Typography
            style={{
              color: theme.palette.text.primary,
              fontWeight: 'bold',
              fontFamily: 'Helvetica, sans-serif',
              padding: '.5rem 0',
            }}
            align="center"
            component="h1"
            variant="h6"
          >
            Home
          </Typography>
          <Divider
            style={{
              height: '.1rem',
              width: '60%',
              margin: '0 auto 1%',
              backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
            }}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}></Grid>
          {permissionUser?.indexOf('usuario_master') >= 0 ||
          permissionUser?.indexOf('backoffice-empresa') >= 0 ||
          permissionUser?.indexOf('backoffice-multiplica') >= 0 ? (
            <Grid item xs={12} md={4}>
              <Link
                color="inherit"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => history.push('/backoffice/pendencias-abertura')}
              >
                <Card className={classes.corMonitor}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <DvrIcon
                      style={{ fontSize: '8rem' }}
                      className={'corMonitorImg'}
                    />
                    <Typography variant="h5" className={classes.textStyle}>
                      Monitor
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ) : (
            <Grid item xs={12} md={4}>
              <Card className={classes.corMonitorDisabled}>
                <CardContent style={{ textAlign: 'center' }}>
                  <DvrIcon
                    style={{ fontSize: '8rem', opacity: '0.4' }}
                    className={'corMonitorImg'}
                  />
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className={classes.textStyle}
                  >
                    Monitor
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <Link
              color="inherit"
              style={{ cursor: 'pointer', textDecoration: 'none' }}
              onClick={() => history.push('/backoffice/esteira-back')}
            >
              <Card className={classes.corMonitor}>
                <CardContent style={{ textAlign: 'center' }}>
                  <PlaylistPlayIcon
                    style={{ fontSize: '8rem' }}
                    src={imgSenha}
                    className={'corMonitorImg'}
                  />

                  <Typography variant="h5" className={classes.textStyle}>
                    Esteira
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={4}>
            <Link
              color="inherit"
              style={{ cursor: 'pointer', textDecoration: 'none' }}
              onClick={() => history.push('/backoffice/pwd')}
            >
              <Card className={classes.corMonitor}>
                <CardContent style={{ textAlign: 'center' }}>
                  <LockIcon
                    style={{ fontSize: '8rem' }}
                    src={imgSenha}
                    className={'corMonitorImg'}
                  />

                  <Typography variant="h5" className={classes.textStyle}>
                    Senha Financeira
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            {permissionUser?.indexOf('usuario_master') >= 0 ? (
              <Link
                color="inherit"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => history.push('/backoffice/userlist')}
              >
                <Card className={classes.corMonitor}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <PeopleAltIcon
                      style={{ fontSize: '8rem' }}
                      src={imgSenha}
                      className={'corMonitorImg'}
                    />
                    <Typography variant="h5" className={classes.textStyle}>
                      Gestão de Usuários
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            ) : (
              <Card className={classes.corMonitorDisabled}>
                <CardContent style={{ textAlign: 'center' }}>
                  <PeopleAltIcon
                    style={{ fontSize: '8rem', opacity: '0.4' }}
                    src={imgSenha}
                    className={'corMonitorImg'}
                  />
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className={classes.textStyle}
                    style={{
                      opacity: '0.4',
                    }}
                  >
                    Gestão de Usuários
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}
