import { api_multipague_configuracao } from '../../services/api';

export const getServices = async params => {
  const URL = `/servico`;
  const { data } = await api_multipague_configuracao.get(URL, {
    params,
  });

  return data;
};

export const getEventsService = async id => {
  const URL = `/servico/${id}/evento`;
  const { data } = await api_multipague_configuracao.get(URL);

  return data;
};

export const editEventsService = async (id, body) => {
  const URL = `/servico/${id}`;
  const { data } = await api_multipague_configuracao.put(URL, { ...body });

  return data;
};

export const saveEventsService = async (id, body) => {
  const URL = `/servico/${id}/evento`;
  const { data } = await api_multipague_configuracao.post(URL, body);

  return data;
};

export const deleteEventService = async (id, idEvento) => {
  const URL = `/servico/${id}/evento/${idEvento}`;

  const { data } = await api_multipague_configuracao.delete(URL);

  return data;
};

export const saveService = async body => {
  const URL = `/servico`;
  const { data } = await api_multipague_configuracao.post(URL, { ...body });

  return data;
};

export const getEvents = async () => {
  const URL = `/evento`;

  const { data } = await api_multipague_configuracao.get(URL);

  return data;
};

export const deleteService = async id => {
  const URL = `/servico/${id}`;

  const { data } = await api_multipague_configuracao.delete(URL);

  return data;
};
