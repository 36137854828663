import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import UseMonitor from '../../useMonitor';
import ModalActionUnit from '../ModalActionUnit';
import ModalActionLote from '../ModalActionLote';
import PopUpPassword from '../../../../components/PopUpPassword';

export default () => {
  const {
    selectionModel,
    modal,
    openModalPasswordUnit,
    openModalActionUnit,
    closeModalActionUnit,
    rows,
    modalLote,
    closeModalActionLote,
    openModalPasswordLote,
    anchorEl,
    setAnchorEl,
    popPupPassword,
    onClosePassword,
  } = UseMonitor();
  return (
    <>
      <PopUpPassword
        show={popPupPassword.open}
        callback={data =>
          popPupPassword.callback && popPupPassword.callback(data)
        }
        closePopUp={onClosePassword}
      />
      <ModalActionUnit
        modal={modal}
        onClose={closeModalActionUnit}
        onConfirm={openModalPasswordUnit}
      />

      <ModalActionLote
        modalLote={modalLote}
        onClose={closeModalActionLote}
        onConfirm={openModalPasswordLote}
        transacoes={rows.filter(item => selectionModel.includes(item.id))}
      />
    </>
  );
};
