import React, { useEffect, useState } from 'react';
import {
  Chip,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import { maskCNPJ } from '../../util/mask';
import AutocompleteClienteConta from '../../components/AutocompleteClienteConta';
import { getHistoricoSaldos } from '../../hooks/historico-contas';
import { useHistory } from 'react-router-dom';
import { api_multipague_conta_ApplicationContext } from '../../services/api';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import moment from 'moment';

const HistoricoSaldos = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [rows, setRows] = useState([]);

  const initialParams = {
    rowCount: 0,
    page: 0,
    size: 10,
    idClienteConta: '',
    dataInicio: moment().format('YYYY-MM-DD'),
    dataFim: moment().format('YYYY-MM-DD'),
  };
  const [params, setParams] = useState(initialParams);

  const [carregandoCSVPDF, setCarregandoCSVPDF] = useState({
    carregaExcel: false,
    carregaIconBotaoExcel: false,
    carregaPDF: false,
    carregaIconPDF: false,
  });

  const refAutocomplete = React.useRef();
  const { data, isLoading, isFetching, refetch } = getHistoricoSaldos(
    params,
    params.page,
    params.size,
  );

  const handleInput = e => {
    const value =
      e.target.name === 'cnpjCpf' ? maskCNPJ(e.target.value) : e.target.value;
    setParams(old => ({ ...old, [e.target.name]: value }));
  };

  const columns = [
    {
      field: 'data',
      headerName: 'Data',
      width: 150,
    },
    {
      field: 'cnpjCpf',
      headerName: 'CNPJ/CPF',
      width: 180,
    },
    {
      field: 'nome',
      headerName: 'Razão Social',
      width: 250,
      flex: 1,
    },
    {
      field: 'conta',
      headerName: 'Conta',
      width: 150,
    },
    {
      field: 'saldoInicial',
      headerName: 'Saldo Inicial',
      width: 150,
    },
    {
      field: 'saldoFinal',
      headerName: 'Saldo Final',
      width: 150,
    },
  ];

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <AutocompleteClienteConta
            ref={refAutocomplete}
            onChange={item => {
              setParams(old => ({ ...old, idClienteConta: item?.value }));
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Inicial"
            value={params.dataInicio}
            variant="outlined"
            size="small"
            required
            error={!params.dataInicio}
            helperText={!params.dataInicio && 'Campo inválido'}
            type="date"
            name="dataInicio"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Final"
            value={params.dataFim}
            variant="outlined"
            size="small"
            error={
              !params.dataFim ||
              moment(params.dataFim).isBefore(moment(params.dataInicio))
            }
            required
            helperText={
              !params.dataFim ||
              (moment(params.dataFim).isBefore(moment(params.dataInicio)) &&
                'Campo inválido')
            }
            type="date"
            name="dataFim"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
    ],
    searchButton: {
      searchButton: () => {
        if (moment(params.dataFim).isSameOrAfter(moment(params.dataInicio))) {
          filterHistorico();
        }
      },
    },
    cleanButton: {
      cleanButton: () => {
        if (refAutocomplete?.current) {
          refAutocomplete?.current?.reset();
        }
        setParams({
          rowCount: 0,
          page: 0,
          size: 10,
          idClienteConta: '',
          dataInicio: '',
          dataFim: '',
        });
        setTimeout(() => {
          refetch();
        }, 0);
      },
    },
  };

  const downloadPDFCSV = async (
    idClienteConta,
    dataInicio,
    dataFim,
    tipoArquivo,
  ) => {
    if (rows.length === 0) {
      toastComponentError('Nenhum informação na tabela', theme);
      setCarregandoCSVPDF({
        carregaExcel: false,
        carregaIconBotaoExcel: false,
        carregaPDF: false,
        carregaIconPDF: false,
      });
    } else {
      try {
        const { data } = await api_multipague_conta_ApplicationContext.get(
          `/saldo/arquivo?tipoArquivo=${tipoArquivo}&idClienteConta=${idClienteConta}&dataInicio=${dataInicio}&dataFim=${dataFim}`,
        );

        if (tipoArquivo === 'PDF') {
          toastComponentSuccess('Arquivo gerado com sucesso', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaPDF: false,
            carregaIconPDF: false,
          }));
        } else {
          toastComponentSuccess('Arquivo gerado com sucesso', theme);
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaExcel: false,
            carregaIconBotaoExcel: false,
          }));
        }
        return data;
      } catch (error) {
        toastComponentError('Erro ao gerar arquivo', theme);
        if (tipoArquivo === 'PDF') {
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaPDF: false,
            carregaIconPDF: false,
          }));
        } else {
          setCarregandoCSVPDF(prev => ({
            ...prev,
            carregaExcel: false,
            carregaIconBotaoExcel: false,
          }));
        }
      }
    }
  };

  const fetchAsyncFile = async (
    idClienteConta = null,
    dataInicio = '',
    dataFim = '',
    tipoArquivo,
  ) => {
    if (tipoArquivo === 'XLSX') {
      setCarregandoCSVPDF(prev => ({
        ...prev,
        carregaExcel: true,
        carregaIconBotaoExcel: true,
      }));
      await downloadPDFCSV(idClienteConta, dataInicio, dataFim, tipoArquivo);
    } else {
      setCarregandoCSVPDF(prev => ({
        ...prev,
        carregaPDF: true,
        carregaIconPDF: true,
      }));
      await downloadPDFCSV(idClienteConta, dataInicio, dataFim, tipoArquivo);
    }
  };

  async function filterHistorico() {
    setParams(prev => ({ ...prev, page: 0 }));
    refetch();
  }

  useEffect(() => {
    setRows(data?.data);
    setParams(prev => ({ ...prev, rowCount: data?.totalElements }));
  }, [data?.data]);

  return (
    <DataGridWrapper
      title={'Histórico de Saldos'}
      minHeight="600px"
      crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
      columns={columns}
      formData={filterInputs}
      loading={isFetching || isLoading}
      rows={rows || []}
      styleFormBackground={{
        display: 'flex',
        justifyContent: isMediumScreen ? 'flex-end' : 'space-between',
      }}
      pageSize={params.size}
      PDFCSVCustomer={{
        csvExcel: "XLSX",
        downloadExcel: () =>
          fetchAsyncFile(
            params?.idClienteConta,
            params?.dataInicio,
            params?.dataFim,
            'XLSX',
          ),
        downloadPDF: () =>
          fetchAsyncFile(
            params?.idClienteConta,
            params?.dataInicio,
            params?.dataFim,
            'PDF',
          ),
        rotaArquivosGerados: () =>
          history.push('/backoffice/arquivos-gerados-historico'),
        title: 'Historico de Saldo',
        carregaExcel: carregandoCSVPDF.carregaExcel,
        carregaIconExcel: carregandoCSVPDF.carregaIconBotaoExcel,
        carregaPDF: carregandoCSVPDF.carregaPDF,
        carregaIconPDF: carregandoCSVPDF.carregaIconPDF,
      }}
      headerComponent={() => (
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            style={{
              display: 'flex',
              gap: 20,
              flexWrap: 'wrap',
            }}
          >
            <Chip
              color="primary"
              onClick={() => {
                history.push('/backoffice/arquivos-gerados-historico');
              }}
              variant="outlined"
              style={{ width: '100%', cursor: 'pointer' }}
              label="Consultar arquivos gerados"
            />
          </Grid>
        </Grid>
      )}
      onPageSizeChangeFunction={newPageSize => {
        setParams(prev => ({ ...prev, page: 0, size: newPageSize }));
      }}
      paginationMode="server"
      paginate
      rowCount={params?.rowCount}
      onPageChange={newPage => {
        setParams(prev => ({ ...prev, page: newPage }));
      }}
      rowsPerPageOptions={[10, 20, 50, 100]}
    />
  );
};

export default HistoricoSaldos;
