import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import {
  api_multipague_configuracao,
  api_multipague_configuracao_aviso,
} from '../../../services/api';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import { MdImage, MdSave } from 'react-icons/md';
import { useStyles } from '../styles';

export default function AvisosEditar() {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const [dataVigenciaDe, setDataVigenciaDe] = useState();
  const [dataVigenciaAte, setDataVigenciaAte] = useState();
  const [direcionamento, setDirecionamento] = useState();
  const [observacao, setObservacao] = useState();
  const [idAviso, setIdAviso] = useState(localStorage.getItem('idAviso'));

  const [loadButton, setLoadButton] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [enviaArquivo, setEnviaArquivo] = useState([]);
  const [arquivo, setArquivo] = useState(null);
  const [situacao, setSituacao] = useState();

  useEffect(() => {
    if (arquivo != null) {
      setImageUrl(window.URL.createObjectURL(arquivo));
    }
    console.log('ARQUIVO: ', arquivo);
  }, [arquivo]);

  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/avisos/${idAviso}`;
  const URLSalvar = `/avisos/${idAviso}`;

  useEffect(() => {
    api_multipague_configuracao
      .get(URL, { headers: { Authorization: AuthStr } })
      .then(response => {
        console.log('TESTE AQUI: ', response.data.observacao);
        setDataVigenciaDe(response.data.vigenciaInicio);
        setDataVigenciaAte(response.data.vigenciaFim);
        setDirecionamento(response.data.urlRedirecionamento);
        setSituacao(response.data.ativo);
        setObservacao(response.data.observacao);
        setImageUrl(response.data.caminhoStorage);
      })
      .catch(error => {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }, [idAviso]);

  function salvar() {
    setLoadButton(true);
    const formData = new FormData();
    formData.append('file', arquivo);
    formData.append('dataVigenciaInicio', dataVigenciaDe);
    formData.append('dataVigenciaFim', dataVigenciaAte);
    formData.append('observacao', observacao);
    formData.append('urlRedirecionamento', direcionamento);
    formData.append('ativo', situacao == 'ATIVADO' ? true : false);

    api_multipague_configuracao_aviso
      .put(URLSalvar, formData)
      .then(function (response) {
        setLoadButton(false);
        toastComponentSuccess('Anúncio editado!', theme);
        setTimeout(function () {
          history.push('/backoffice/avisos-lista');
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  function guardarArquivo(e) {
    setArquivo(e.target.files[0]);
    setEnviaArquivo(e.target.files[0]);
  }

  return (
    <Wrapper
      title="Edição de Aviso"
      crumb={[{ link: '/backoffice', name: 'Home' }]}
    >
      <Box className={classes.wrapperBox}>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={12} md={3} lg={3}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
            >
              <InputLabel
                shrink
                style={{
                  backgroundColor: theme.palette.background.paper,
                  padding: '0 5px',
                }}
                id="demo-simple-select-outlined-label"
              >
                {situacao == true ? 'ATIVADO' : 'DESATIVADO'}
              </InputLabel>
              <Select
                fullWidth
                style={{ borderRadius: '20px' }}
                label="Situação"
                id="outlined-size-small"
                variant="outlined"
                size="small"
                value={situacao}
                onChange={event => setSituacao(event.target.value)}
              >
                <MenuItem value="ATIVADO">ATIVADO</MenuItem>
                <MenuItem value="DESATIVADO">DESATIVADO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Data Vigência Início"
              id="outlined-size-small"
              value={dataVigenciaDe}
              onChange={e => setDataVigenciaDe(e.target.value)}
              variant="outlined"
              size="small"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Data Vigência Fim"
              id="outlined-size-small"
              value={dataVigenciaAte}
              onChange={e => setDataVigenciaAte(e.target.value)}
              variant="outlined"
              size="small"
              type="date"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="URL e Direcionamento"
              id="outlined-size-small"
              value={direcionamento}
              onChange={e => {
                setDirecionamento(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              id="outlined-multiline-static"
              label="Observação"
              multiline
              fullWidth
              rows={4}
              value={observacao}
              onChange={e => {
                setObservacao(e.target.value);
              }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}></Grid>
          {imageUrl && !arquivo && (
            <Grid item xs={12} md={4} lg={4}>
              <div>Imagem atual:</div>
              <img
                style={{
                  borderRadius: '5px',
                }}
                src={imageUrl}
                width="100%"
              />
            </Grid>
          )}
          {imageUrl && arquivo && (
            <Grid item xs={12} md={4} lg={4}>
              <div>Previsão da imagem:</div>
              <img src={imageUrl} alt={arquivo.name} width="100%" />
            </Grid>
          )}
          <Grid item xs={12} md={4} lg={4}></Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={e => guardarArquivo(e)}
            />
            <label htmlFor="contained-button-file">
              <LoadingBtn
                style={{
                  borderRadius: '20px',
                  boxShadow: `${alpha(
                    theme.palette.primary.dark,
                    0.2,
                  )} 0px 0px 45px -15px`,
                }}
                startIcon={<MdImage size={15} color="background" />}
                title={'Upload Imagem'}
                variant="contained"
                color="primary"
                component="span"
              >
                Upload Imagem
              </LoadingBtn>
            </label>

            <LoadingBtn
              variant="contained"
              color="primary"
              style={{
                borderRadius: '20px',
                marginLeft: '20px',
                boxShadow: `${alpha(
                  theme.palette.primary.dark,
                  0.2,
                )} 0px 0px 45px -15px`,
              }}
              startIcon={<MdSave size={15} color="background" />}
              title={'Salvar'}
              isLoading={loadButton}
              onClick={() => salvar()}
            >
              Salvar
            </LoadingBtn>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
}
