import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 97%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FormContainer = styled(FormControl).attrs(props => ({
  ref: props.ref,
}))`
  min-width: 100%;
`;
export const Label = styled(InputLabel)`
  min-width: 100%;
`;
export const Itens = styled(MenuItem)`
  min-width: 100%;
`;
export const SelectOptions = styled(Select)`
  width: 100%;
`;

export const MessageError = styled.p`
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Helvetica", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin-left: 14px;
  margin-right: 14px;
  color: #FF5050;
`;
