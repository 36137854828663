import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import 'react-toastify/dist/ReactToastify.css';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../../components/Toast';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { getContas, getTermsContas, saveContasTerms } from '../../services';
import { useStyles } from '../../styles';
import { ButtonsSaveReturn } from '../../ButtonsSaveReturn';

export default function VincularContas({
  datas,
  idTermo,
  setError,
  filtros,
  checkedContas = [],
  voltar,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [loadingContas, setLoadingContas] = useState(false);
  const [checked, setChecked] = useState(checkedContas);
  const [contas, setContas] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setChecked([]);
    } else {
      setChecked(contas.map(conta => conta.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSalvar = () => {
    setError({ fim: !datas?.fim, inicio: !datas?.inicio });
    if (!!datas?.fim && !!datas?.inicio) {
      setLoadButton(true);
      saveContasTerms(idTermo, {
        dataFimVigencia: datas?.fim,
        dataInicioVigencia: datas?.inicio,
        idClienteContas: contas
          .filter(item => checked.includes(item.id))
          .map(item => item.id),
      })
        .then(() => {
          setLoadButton(false);
          toastComponentSuccess('Contas vinculadas com sucesso!', theme);
        })
        .catch(error => {
          setLoadButton(false);
          toastComponentError(error.response.data.mensagem, theme);
        });
    }
  };

  const handleGetContas = () => {
    setLoadingContas(true);
    getContas()
      .then(data => {
        setLoadingContas(false);
        setContas(data);
      })
      .catch(error => {
        setLoadingContas(false);
        toastComponentError(
          !!error?.response?.data
            ? error?.response?.data?.mensagem
            : 'Nenhuma conta encontrada',
          theme,
        );
      });
  };

  useEffect(() => {
    handleGetContas();
    getTermsContas(idTermo).then(data => {
      setChecked(data.map(item => item.id));
    });
  }, []);

  useEffect(() => {
    const filteredContas = contas.filter(item =>
      item?.cliente?.nome?.toLowerCase().includes(filtros?.toLowerCase()),
    );

    if (!!filtros) {
      setContas(filteredContas);
    } else {
      handleGetContas();
    }
  }, [filtros]);

  useEffect(() => {
    setSelectAll(checked.length === contas.length);
  }, [checked, contas]);

  return (
    <Grid className={classes.divVinculaServicos}>
      <FormControlLabel
        control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
        label="Selecionar todos"
        edge="start"
        className={classes.selectAllCheckbox}
        disableRipple
      />
      <Grid container className={classes.divVinculoBorder}>
        {loadingContas ? (
          <CircularProgress style={{ marginTop: 5 }} />
        ) : contas.length === 0 ? (
          <Typography
            variant="body1"
            style={{
              padding: 15,
            }}
          >
            Nenhuma conta encontrada
          </Typography>
        ) : (
          Array(2)
            .fill()
            .map((_, index) => (
              <Grid item xs={12} md={6} key={index}>
                <List className={classes.listColorContainer}>
                  {contas
                    .slice(
                      index === 0 ? 0 : Math.ceil(contas.length / 2),
                      index === 0 ? Math.ceil(contas.length / 2) : undefined,
                    )
                    .map(value => {
                      const labelId = `checkbox-list-label-${value.id}`;

                      return (
                        <ListItem
                          key={value.id}
                          disablePadding
                          style={{ marginBottom: -15 }}
                        >
                          <div
                            role={undefined}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                            onClick={handleToggle(value.id)}
                            dense
                          >
                            <ListItemIcon style={{ marginRight: -20 }}>
                              <Checkbox
                                edge="start"
                                checked={checked?.indexOf(value.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={value?.cliente?.nome}
                            />
                          </div>
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
            ))
        )}
      </Grid>

      <div className={classes.divButtonBackSave}>
        <ButtonsSaveReturn
          loadButton={loadButton}
          onclickback={voltar}
          onclickconfirm={() => !loadButton && handleSalvar()}
        />
      </div>
    </Grid>
  );
}
