import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import 'react-toastify/dist/ReactToastify.css';
import { toastComponentError } from '../../../../components/Toast';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  getServices,
  getTermsServices,
  saveServiceTerms,
} from '../../services';
import { useStyles } from '../../styles';
import { ButtonsSaveReturn } from '../../ButtonsSaveReturn';
import { handleSalvar } from '../handlers';

export default function VincularServicos({
  idTermo,
  checkedServices = [],
  voltar,
  filtro,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [checked, setChecked] = React.useState(checkedServices);
  const [services, setServices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setChecked([]);
    } else {
      setChecked(services.map(serv => serv.id));
    }
    setSelectAll(!selectAll);
  };

  const handleGetServices = () => {
    setLoadingServices(true);
    getServices()
      .then(data => {
        setLoadingServices(false);
        setServices(data);
      })
      .catch(error => {
        setLoadingServices(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  useEffect(() => {
    handleGetServices();
    getTermsServices(idTermo).then(data => {
      setChecked(data.map(item => item.id));
    });
  }, []);

  useEffect(() => {
    const filteredServices = services.filter(item =>
      item?.descricao?.toLowerCase().includes(filtro?.toLowerCase()),
    );

    if (!!filtro) {
      setServices(filteredServices);
    } else {
      handleGetServices();
    }
  }, [filtro]);

  return (
    <Grid className={classes.divVinculaServicos}>
      <FormControlLabel
        control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
        label="Selecionar todos"
        edge="start"
        className={classes.selectAllCheckbox}
        disableRipple
      />
      <Grid container className={classes.divVinculoBorder}>
        {loadingServices ? (
          <CircularProgress />
        ) : (
          Array(2)
            .fill()
            .map((_, index) => (
              <Grid item xs={12} md={6} key={index}>
                <List className={classes.listColorContainer}>
                  {services
                    .slice(
                      index === 0 ? 0 : Math.ceil(services.length / 2),
                      index === 0 ? Math.ceil(services.length / 2) : undefined,
                    )
                    .map(value => {
                      const labelId = `checkbox-list-label-${value.id}`;

                      return (
                        <ListItem
                          key={value.id}
                          disablePadding
                          style={{ marginBottom: -15 }}
                        >
                          <div
                            role={undefined}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                            onClick={handleToggle(value.id)}
                            dense
                          >
                            <ListItemIcon style={{ marginRight: -20 }}>
                              <Checkbox
                                edge="start"
                                checked={checked?.indexOf(value.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={value.descricao}
                            />
                          </div>
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
            ))
        )}
      </Grid>

      <div className={classes.divButtonBackSave}>
        <ButtonsSaveReturn
          loadButton={loadButton}
          onclickback={voltar}
          onclickconfirm={() =>
            !loadButton &&
            handleSalvar(
              setLoadButton,
              () =>
                saveServiceTerms(
                  idTermo,
                  services
                    .filter(item => checked.includes(item.id))
                    .map(item => item.id),
                ),
              theme,
            )
          }
        />
      </div>
    </Grid>
  );
}
