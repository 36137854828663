import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import CallMerge from '@material-ui/icons/CallMerge';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import DvrIcon from '@material-ui/icons/Dvr';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Link from '@material-ui/core/Link';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Functions from '@material-ui/icons/Functions';
import { useHistory } from 'react-router-dom';
import LocalAtm from '@material-ui/icons/LocalAtm';
import Timeline from '@material-ui/icons/Timeline';
import { ImHome } from 'react-icons/im';

import { Container } from './style';

import {
  administradorEscrow,
  usuarioMaster,
  backofficeEmpresa,
  backofficeMultiplica,
  logoutBackoffice,
} from '../../util/Auth';
import { Tooltip, alpha } from '@material-ui/core';
import { EventBusy } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '30px',
    height: '100%',
    maxWidth: 400,
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    color: theme.palette.appBar[theme.palette.type].text,
  },
  menu: {
    // marginLeft: '8%',
    paddingRight: '18%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.appBar[theme.palette.type].text,
  },
  menuActive: {
    // backgroundColor:
    //   theme.palette.type === 'dark'
    //     ? theme.palette.background.dark
    //     : theme.palette.background.default,
    // borderTopLeftRadius: '20px',
  },
  submenu: {
    marginLeft: '5%',
    color: theme.palette.appBar[theme.palette.type].text,
  },
  itemActive: {
    color: theme.palette.appBar[theme.palette.type].textSelected,
  },
  iconItemMenu: {
    marginLeft: '2px',
    fill: theme.palette.primary.main,
    width: '20px',
  },
  iconItemActve: {
    marginLeft: '2px',
    width: '30px',
    fill: theme.palette.appBar[theme.palette.type].textSelected,
  },
  iconItemInactive: {
    marginLeft: '2px',
    width: '20px',
    fill: theme.palette.primary.main,
  },
  iconItemDisabled: {
    marginLeft: '2px',
    fill: alpha(theme.palette.appBar[theme.palette.type].text, 0.5),
    width: '20px',
  },
}));

export default function mainListItems({ tooltip = false }) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [openConfig, setopenConfig] = React.useState(false);
  const [openVinculo, setOpenVinculo] = React.useState(false);
  const [openTarifador, setOpenTarifador] = React.useState(false);
  const [openTermos, setOpenTermos] = React.useState(false);

  const handleClickConfig = () => {
    setopenConfig(!openConfig);
  };

  const handleClickVinculo = () => {
    setOpenVinculo(!openVinculo);
  };

  const handleClickTarifador = () => {
    setOpenTarifador(!openTarifador);
  };

  const handleClickTermos = () => {
    setOpenTermos(!openTermos);
  };

  useEffect(() => {
    const vinculoPaths = [
      '/backoffice/vinculouser',
      '/backoffice/vinculoedit',
      '/backoffice/vinculo-conta',
      '/backoffice/vinculo-conta-edit',
    ];

    const configPaths = [
      '/backoffice/eventos-lista',
      '/backoffice/eventos-editar',
      '/backoffice/bancos-lista',
      '/backoffice/bancos-editar',
      '/backoffice/tarifador',
      '/backoffice/associacao-contas-lista',
      '/backoffice/associacao-contas-consulta',
      '/backoffice/associacao-contas-editar',
      '/backoffice/associacao-bancos-lista',
      '/backoffice/associacao-bancos-consulta',
      '/backoffice/associacao-bancos-editar',
      '/backoffice/parametros',
      '/backoffice/servicos',
    ];

    const tarifadorPath = [
      '/backoffice/tarifador/monitor-geral',
      '/backoffice/tarifador/monitor-conta',
    ];

    const termoPath = ['/backoffice/termos', '/backoffice/monitor-termos'];
    const isOpenVinculo = vinculoPaths.includes(location.pathname);
    const isOpenConfig = configPaths.includes(location.pathname);
    const isOpenTarifador = tarifadorPath.includes(location.pathname);
    const isOpenTermo = termoPath.includes(location.pathname);

    setOpenVinculo(isOpenVinculo);
    setopenConfig(isOpenConfig);
    setOpenTarifador(isOpenTarifador);
    setOpenTermos(isOpenTermo);
  }, [location]);

  function handleTooltip(tooltip, icon, name) {
    return (
      <>
        <ListItemIcon
          style={{
            marginLeft: tooltip ? '25px' : '0',
          }}
        >
          {tooltip ? (
            <Tooltip placement="bottom" title={name}>
              {icon}
            </Tooltip>
          ) : (
            icon
          )}
        </ListItemIcon>
        <ListItemText style={{ marginLeft: tooltip ? '30px' : '-15px' }}>
          <Typography>{name}</Typography>
        </ListItemText>
      </>
    );
  }

  function createRoute(tooltip, icon, name, route, disabled) {
    return (
      <>
        <ListItem
          disabled={disabled}
          style={disabled ? null : getListItemStyle(route)}
          button
          onClick={disabled ? null : () => history.push(route)}
        >
          <ListItemIcon style={{ padding: 0 }}>
            {tooltip ? (
              <Tooltip placement="bottom" title={name}>
                {icon}
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" title={name}>
                {icon}
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText
            style={{
              marginLeft: tooltip ? '30px' : '-15px',
              color:
                location.pathname === route
                  ? theme.palette.primary.main
                  : 'inherit',
            }}
          >
            <Typography
              style={{
                fontWeight: location.pathname === route ? '600' : '400',
                fontSize: location.pathname === route ? '16px' : '15px',
              }}
            >
              {name}
            </Typography>
          </ListItemText>
        </ListItem>
      </>
    );
  }

  const getListItemStyle = path => {
    if (location.pathname === path) {
      return {
        //  marginLeft: '8%',
        color: theme.palette.text.primary,
      };
    } else {
      // return { marginLeft: '8%' };
    }
  };

  const getListItemStyleMenuActive = path => {
    if (location.pathname === path) {
      return {
        //  marginLeft: '8%',
        color: theme.palette.text.primary,
      };
    } else {
      //    return { marginLeft: '8%' };
    }
  };

  function getListItemCode(path, name, icon) {
    const listItemStyle = getListItemStyleMenuActive(path);
    const handleOnClick = () => history.push(path);

    return (
      <ListItem
        button
        className={classes.menu}
        style={listItemStyle}
        onClick={handleOnClick}
      >
        <ListItemIcon>
          {tooltip ? (
            <Tooltip placement="bottom" title={name}>
              {icon}
            </Tooltip>
          ) : (
            icon
          )}
        </ListItemIcon>
        <ListItemText
          style={{
            marginLeft: tooltip ? '30px' : '-15px',
            color:
              location.pathname === path
                ? theme.palette.primary.main
                : theme.palette.getContrastText(
                    theme.palette.appBar[theme.palette.type].bgMenu,
                  ),
          }}
        >
          <Typography
            style={{
              fontWeight: location.pathname === path ? '600' : '400',
              fontSize: location.pathname === path ? '16px' : '15px',
            }}
          >
            {name}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }

  return (
    <Container
      className="container-menu"
      width={tooltip ? theme.spacing(7.9) : 260}
    >
      <List
        component="nav"
        style={{
          paddingRight: tooltip ? '30px' : '0',
        }}
        aria-labelledby="menu-list-subheader"
        className={classes.root}
      >
        {administradorEscrow() ? (
          <>
            {createRoute(
              tooltip,
              <DvrIcon
                class={
                  location.pathname !== '/backoffice/monitor-aprovacao'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Monitor',
              '/backoffice/monitor-aprovacao',
            )}
            {createRoute(
              tooltip,
              <LocalAtm
                class={
                  location.pathname !== '/backoffice/painel-saldos'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Painel de Saldos',
              '/backoffice/painel-saldos',
            )}
          </>
        ) : (
          <>
            {createRoute(
              tooltip,
              <ImHome
                class={
                  location.pathname !== '/backoffice/new-backoffice'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Home',
              '/backoffice/new-backoffice',
            )}
            {usuarioMaster() || backofficeEmpresa() || backofficeMultiplica()
              ? createRoute(
                  tooltip,
                  <DvrIcon
                    class={
                      location.pathname !== '/backoffice/pendencias-abertura'
                        ? classes.iconItemMenu
                        : classes.iconItemInactive
                    }
                  />,
                  'Monitor',
                  '/backoffice/pendencias-abertura',
                )
              : createRoute(
                  tooltip,
                  <DvrIcon class={classes.iconItemDisabled} />,
                  'Monitor',
                  '/backoffice/pendencias-abertura',
                  true,
                )}
            {createRoute(
              tooltip,
              <LocalAtm
                class={
                  location.pathname !== '/backoffice/painel-saldos'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Painel de Saldos',
              '/backoffice/painel-saldos',
            )}
            {createRoute(
              tooltip,
              <Timeline
                class={
                  location.pathname !== '/backoffice/historico-saldos'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Histórico de Saldos',
              '/backoffice/historico-saldos',
            )}
            {createRoute(
              tooltip,
              <EventBusy
                class={
                  location.pathname !== '/backoffice/painel-vencidos'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Painel de Vencidos',
              '/backoffice/painel-vencidos',
            )}
            {createRoute(
              tooltip,
              <AssignmentIcon
                class={
                  location.pathname !== '/backoffice/esteira-back'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Esteira',
              '/backoffice/esteira-back',
            )}
            {createRoute(
              tooltip,
              <LockOpenIcon
                class={
                  location.pathname !== '/backoffice/pwd'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Senha Financeira',
              '/backoffice/pwd',
            )}
            {createRoute(
              tooltip,
              <NotificationImportant
                class={
                  location.pathname !== '/backoffice/avisos-lista'
                    ? classes.iconItemMenu
                    : classes.iconItemInactive
                }
              />,
              'Avisos',
              '/backoffice/avisos-lista',
            )}
            {usuarioMaster()
              ? createRoute(
                  tooltip,
                  <AccountBoxIcon
                    class={
                      location.pathname !== '/backoffice/userlist'
                        ? classes.iconItemMenu
                        : classes.iconItemInactive
                    }
                  />,
                  'Gestão de Usuários',
                  '/backoffice/userlist',
                )
              : createRoute(
                  tooltip,
                  <AccountBoxIcon
                    class={
                      location.pathname !== '/backoffice/userlist'
                        ? classes.iconItemMenu
                        : classes.iconItemInactive
                    }
                  />,
                  'Gestão de Usuários',
                  '/backoffice/userlist',
                  true,
                )}

            {/*** TARIFADOR */}
            <>
              <ListItem
                button
                className={`${classes.menu} ${
                  openTarifador && classes.menuActive
                }`}
                onClick={handleClickTarifador}
              >
                <ListItemIcon>
                  {tooltip ? (
                    <Tooltip placement="bottom" title={'Tarifador'}>
                      <Functions class={classes.iconItemMenu} />
                    </Tooltip>
                  ) : (
                    <Functions class={classes.iconItemMenu} />
                  )}
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: tooltip ? '30px' : '-15px' }}
                >
                  <Typography>Tarifador</Typography>
                </ListItemText>
                {openTarifador ? (
                  <ExpandLess color={'primary'} />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse in={openTarifador} timeout="auto" unmountOnExit>
                <div>
                  <List
                    component="div"
                    disablePadding
                    style={{
                      paddingRight: tooltip ? '30px' : '0',
                    }}
                    className={classes.submenu}
                  >
                    {getListItemCode(
                      '/backoffice/tarifador/monitor-geral',
                      'Monitor Geral',
                      <SubdirectoryArrowRightIcon
                        class={classes.iconItemMenu}
                      />,
                    )}
                    {getListItemCode(
                      '/backoffice/tarifador/monitor-conta',
                      'Monitor por Conta',
                      <SubdirectoryArrowRightIcon
                        class={classes.iconItemMenu}
                      />,
                    )}
                  </List>
                </div>
              </Collapse>
            </>
            <>
              <ListItem
                button
                className={`${classes.menu} ${
                  openTermos && classes.menuActive
                }`}
                onClick={handleClickTermos}
              >
                <ListItemIcon>
                  {tooltip ? (
                    <Tooltip placement="bottom" title={'Termos'}>
                      <NoteAdd class={classes.iconItemMenu} />
                    </Tooltip>
                  ) : (
                    <NoteAdd class={classes.iconItemMenu} />
                  )}
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: tooltip ? '30px' : '-15px' }}
                >
                  <Typography>Termos</Typography>
                </ListItemText>
                {openTermos ? <ExpandLess color={'primary'} /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openTermos} timeout="auto" unmountOnExit>
                <div>
                  <List
                    component="div"
                    disablePadding
                    style={{
                      paddingRight: tooltip ? '30px' : '0',
                    }}
                    className={classes.submenu}
                  >
                    {getListItemCode(
                      '/backoffice/monitor-termos',
                      'Monitor Termos',
                      <SubdirectoryArrowRightIcon
                        class={classes.iconItemMenu}
                      />,
                    )}
                    {getListItemCode(
                      '/backoffice/termos',
                      'Config. Termos',
                      <SubdirectoryArrowRightIcon
                        class={classes.iconItemMenu}
                      />,
                    )}
                  </List>
                </div>
              </Collapse>
            </>
            {/*** --- TARIFADOR */}

            {backofficeMultiplica() ? (
              <>
                <ListItem
                  button
                  className={`${classes.menu} ${
                    openVinculo && classes.menuActive
                  }`}
                  onClick={handleClickVinculo}
                >
                  <ListItemIcon>
                    {tooltip ? (
                      <Tooltip placement="bottom" title={'Vínculos'}>
                        <CallMerge class={classes.iconItemMenu} />
                      </Tooltip>
                    ) : (
                      <CallMerge class={classes.iconItemMenu} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    style={{ marginLeft: tooltip ? '30px' : '-15px' }}
                  >
                    <Typography>Vínculos</Typography>
                  </ListItemText>
                  {openVinculo ? (
                    <ExpandLess color={'primary'} />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse in={openVinculo} timeout="auto" unmountOnExit>
                  <div>
                    <List
                      component="div"
                      disablePadding
                      style={{
                        paddingRight: tooltip ? '30px' : '0',
                      }}
                      className={classes.submenu}
                    >
                      {getListItemCode(
                        '/backoffice/vinculouser',
                        'Vínculo de Usuário',
                        <SubdirectoryArrowRightIcon
                          class={classes.iconItemMenu}
                        />,
                      )}
                      {getListItemCode(
                        '/backoffice/vinculo-conta',
                        'Vínculo por Conta',
                        <SubdirectoryArrowRightIcon
                          class={classes.iconItemMenu}
                        />,
                      )}
                    </List>
                  </div>
                </Collapse>
              </>
            ) : (
              <ListItem button disabled>
                {handleTooltip(
                  tooltip,
                  <AccountBoxIcon
                    class={classes.iconItemDisabled}
                    style={{ marginLeft: tooltip ? '-22px' : '2px' }}
                  />,
                  'Vínculo de Usuários',
                )}
              </ListItem>
            )}
            <ListItem
              button
              className={`${classes.menu} ${openConfig && classes.menuActive}`}
              onClick={handleClickConfig}
            >
              <ListItemIcon>
                {tooltip ? (
                  <Tooltip placement="bottom" title={'Configurações'}>
                    <SettingsIcon class={classes.iconItemMenu} />
                  </Tooltip>
                ) : (
                  <SettingsIcon class={classes.iconItemMenu} />
                )}
              </ListItemIcon>
              <ListItemText style={{ marginLeft: tooltip ? '30px' : '-15px' }}>
                <Typography>Configurações</Typography>
              </ListItemText>
              {openConfig ? <ExpandLess color={'primary'} /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openConfig} timeout="auto" unmountOnExit>
              <div>
                <List
                  component="div"
                  disablePadding
                  style={{
                    paddingRight: tooltip ? '30px' : '0',
                  }}
                  className={classes.submenu}
                >
                  {getListItemCode(
                    '/backoffice/eventos-lista',
                    'Eventos',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                  {getListItemCode(
                    '/backoffice/bancos-lista',
                    'Bancos',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                  {getListItemCode(
                    '/backoffice/tarifador',
                    'Segmento Tarifador',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                  {getListItemCode(
                    '/backoffice/associacao-contas-lista',
                    'Associação de Contas',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                  {getListItemCode(
                    '/backoffice/associacao-bancos-lista',
                    'Associação de Bancos',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                  {getListItemCode(
                    '/backoffice/favorecido-tarifador-lista',
                    'Favorecido Tarifador',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                  {getListItemCode(
                    '/backoffice/servicos',
                    'Serviços',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                  {getListItemCode(
                    '/backoffice/parametros',
                    'Parâmetros',
                    <SubdirectoryArrowRightIcon class={classes.iconItemMenu} />,
                  )}
                </List>
              </div>
            </Collapse>
          </>
        )}

        <Link onClick={() => logoutBackoffice()}>
          <ListItem
            button
            className={classes.menu}
            style={{ marginLeft: tooltip ? '-23px' : '19px' }}
          >
            {handleTooltip(
              tooltip,
              <MeetingRoomIcon class={classes.iconItemMenu} />,
              'Sair',
            )}
          </ListItem>
        </Link>
      </List>
    </Container>
  );
}
