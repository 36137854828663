import React, { useEffect } from 'react';
import {
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  useTheme,
} from '@material-ui/core';
import formattedMoney from '../../util/FormatteMoney';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { api_multipague_cobranca } from '../../services/api';
import { contextContentString } from '../../utils/applicationContext';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { maskCNPJ, maskCPF, maskCpfCnpj } from '../../util/mask';

const PainelVencidos = () => {
  const theme = useTheme();
  const history = useHistory();
  const formStateBack = history.location?.state?.formStateBack;
  const initialForm = {
    cnpjCpfSacado: '',
    cnpjCpfCedente: '',
    nomeCedente: '',
    nomeSacado: '',
    numeroConta: '',
    nomePagador: '',
    dataVencimentoInicio: moment().format('YYYY-MM-DD'),
    dataVencimentoFim: moment().format('YYYY-MM-DD'),
  };
  const [form, setForm] = React.useState(
    formStateBack ? formStateBack : initialForm,
  );
  const [loading, setLoading] = React.useState(false);
  const [sort, setSort] = React.useState('');
  const [sortParams, setSortParams] = React.useState('');
  const [checkboxAll, setCheckboxAll] = React.useState(false);
  const [dataGridState, setDataGridState] = React.useState({
    rows: [],
    page: 0,
    pageSize: 10,
    loading: false,
    rowCount: 0,
  });

  const [totalizing, setTotalizing] = React.useState(0);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleSortProperties = (sortPropertie, headerName) => {
    return (
      <div
        style={{ fontWeight: 700, fontSize: '13px', cursor: 'pointer' }}
        onClick={() => {
          setSort(sort === 'DESC' ? 'ASC' : 'DESC');
          setSortParams(sortPropertie);
        }}
      >
        {headerName}
      </div>
    );
  };

  const columns = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return (
          <Checkbox
            checked={checkboxAll}
            onChange={(_e, value) => changeCheckboxAll(value)}
            color="primary"
          />
        );
      },
      renderCell: params => {
        return (
          <Checkbox
            checked={selectionModel.includes(params.id)}
            onChange={(_e, checked) => {
              if (!checked) {
                setCheckboxAll(false);
                setSelectionModel(old => old.filter(id => id !== params.id));
              }
            }}
            color="primary"
          />
        );
      },
    },

    {
      field: 'numeroConta',
      headerName: 'Número Conta',
      width: 100,
    },
    {
      field: 'nomeCedente',
      headerName: 'Cedente',
      flex: 1,
      minWidth: 200,
      renderCell: event => {
        return (
          <div
            style={{
              lineHeight: '150%',
              height: '50px',
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              fontSize: event?.row?.nomeCedente?.length > 30 ? '11px' : '13px',
              whiteSpace: 'break-spaces',
            }}
          >
            {event?.row?.nomeCedente}
          </div>
        );
      },
    },
    {
      field: 'cpfCnpjCedente',
      headerName: 'CPF/CNPJ Cedente',
      width: 180,
      renderCell: event => {
        return (
          <span>
            {event.row.cpfCnpjCedente
              ? maskCpfCnpj(event.row.cpfCnpjCedente)
              : '-'}
          </span>
        );
      },
    },
    {
      field: 'nomeSacado',
      headerName: 'Sacado',
      flex: 1,
      minWidth: 200,
      renderCell: event => {
        return (
          <div
            style={{
              lineHeight: '150%',
              height: '50px',
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              fontSize: event?.row?.nomeSacado?.length > 30 ? '11px' : '13px',
              whiteSpace: 'break-spaces',
            }}
          >
            {event?.row?.nomeSacado || '-'}
          </div>
        );
      },
    },
    {
      field: 'cpfCnpjSacado',
      headerName: 'CPF/CNPJ Sacado',
      width: 180,
      renderCell: event => {
        return (
          <span>
            {event.row.cpfCnpjSacado
              ? maskCpfCnpj(event.row.cpfCnpjSacado)
              : '-'}
          </span>
        );
      },
      renderHeader: () => {
        return handleSortProperties('documentoFederal', 'CPF/CNPJ Sacado');
      },
    },
    {
      field: 'dataVencimento',
      headerName: 'Data Vencimento',
      width: 150,
      renderCell: event => {
        return (
          <span>
            {event?.row?.dataVencimento
              ? moment(event.row.dataVencimento).format('DD/MM/YYYY')
              : '-'}
          </span>
        );
      },
      renderHeader: () => {
        return handleSortProperties('dataVencimento', 'Data Vencimento');
      },
    },
    {
      field: 'numeroTitulo',
      headerName: 'Número Título',
      width: 150,
      renderHeader: () => {
        return handleSortProperties('numeroTitulo', 'Número Título');
      },
    },
    {
      field: 'valorTitulo',
      headerName: 'Valor',
      width: 120,
      renderCell: event => {
        return <span>{formattedMoney(event.row.valorTitulo)}</span>;
      },
      renderHeader: () => {
        return handleSortProperties('valorTitulo', 'Valor');
      },
    },
    {
      width: 120,
      field: 'diasEmAtraso',
      headerName: 'Dias em Atraso',
    },
  ];

  const changeCheckboxAll = async checked => {
    setCheckboxAll(checked);
    if (checked) setSelectionModel(dataGridState.rows.map(item => item.id));
  };

  const handleInput = e => {
    const value =
      e.target.name === 'cnpjCpfCedente' || e.target.name === 'cnpjCpfSacado'
        ? e.target.value?.length > 14
          ? maskCNPJ(e.target.value)
          : maskCPF(e.target.value)
        : e.target.value;
    setForm(old => ({ ...old, [e.target.name]: value }));
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="Nº da Conta"
            value={form.numeroConta}
            variant="outlined"
            size="small"
            type="number"
            name="numeroConta"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Cedente"
            value={form.nomeCedente}
            variant="outlined"
            size="small"
            onChange={handleInput}
            name="nomeCedente"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="CPF/CNPJ Cedente"
            value={form.cnpjCpfCedente}
            variant="outlined"
            size="small"
            name="cnpjCpfCedente"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Sacado"
            value={form.nomeSacado}
            variant="outlined"
            size="small"
            onChange={handleInput}
            name="nomeSacado"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="CPF/CNPJ Sacado"
            value={form.cnpjCpfSacado}
            variant="outlined"
            size="small"
            name="cnpjCpfSacado"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Vencimento Início"
            value={form.dataVencimentoInicio}
            variant="outlined"
            size="small"
            type="date"
            onChange={handleInput}
            name="dataVencimentoInicio"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: '1111-01-01',
              max: '9999-12-31',
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Vencimento Fim"
            value={form.dataVencimentoFim}
            variant="outlined"
            size="small"
            type="date"
            onChange={handleInput}
            name="dataVencimentoFim"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: '1111-01-01',
              max: '9999-12-31',
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
    ],
    searchButton: {
      searchButton: () => {
        listOverdues(0, dataGridState.pageSize);
      },
    },
    cleanButton: {
      cleanButton: () => {
        setForm(initialForm);
        setDataGridState(prev => ({
          ...prev,
          rows: [],
          loading: true,
          page: 0,
          pageSize: 10,
        }));
        listOverdues(0, dataGridState.pageSize, moment().format('YYYY-MM-DD'));
      },
    },
  };

  const createExcel = () => {
    if (dataGridState.rows.length === 0) {
      toastComponentError('Nenhum boleto selecionado', theme);
    } else if (!checkboxAll && selectionModel.length > 0) {
      api_multipague_cobranca
        .post(`/boletos-protesto/emitir-selecionados`, selectionModel, {
          headers: {
            ApplicationContext: window.btoa(contextContentString),
          },
        })
        .then(response => {
          setLoading(false);
          toastComponentSuccess('Excel solicitado com sucesso!', theme);
        })
        .catch(error => {
          setLoading(false);
          console.log(' error:', error);
          toastComponentError(error?.message, theme);
        });
    } else {
      setLoading(true);
      api_multipague_cobranca
        .post(
          `/boletos-protesto/emitir?nomeCedente=${form.nomeCedente}&contaCedente=${form.numeroConta}&nomePagador=${form.nomePagador}&cpfCnpj=${form.cnpjCpf}&dataVencimentoInicial=${form.dataVencimentoInicio}&dataVencimentoFinal=${form.dataVencimentoFim}`,
          {},
          {
            headers: {
              ApplicationContext: window.btoa(contextContentString),
            },
          },
        )
        .then(response => {
          setLoading(false);
          toastComponentSuccess('Excel solicitado com sucesso!', theme);
        })
        .catch(error => {
          setLoading(false);
          console.log(' error:', error);
          toastComponentError(error?.message, theme);
        });
    }
  };

  const listOverdues = (page, pageSize, data = null) => {
    setDataGridState(prev => ({
      ...prev,
      loading: true,
      page: page,
      pageSize: pageSize,
    }));
    api_multipague_cobranca
      .get(
        `/boletos-protesto/consultar-boletos-vencidos?sort=${sort}&sortProperties=${sortParams}&page=${page}&size=${pageSize}&dataVencimentoInicial=${
          data ? data : form.dataVencimentoInicio
        }&dataVencimentoFinal=${
          data ? data : form.dataVencimentoFim
        }&nomeCedente=${form.nomeCedente}&contaCedente=${
          form.numeroConta
        }&cpfCnpjCedente=${form.cnpjCpfCedente?.replace(
          /\D/g,
          '',
        )}&cnpjCpfSacado=${form.cnpjCpfSacado?.replace(/\D/g, '')}&nomeSacado=${
          form.nomeSacado
        }`,
        {
          headers: {
            ApplicationContext: window.btoa(contextContentString),
          },
        },
      )
      .then(response => {
        setDataGridState(prev => ({
          ...prev,
          rows: response?.data?.content.map(item => ({
            ...item,
            id: item.id,
          })),
          rowCount: response?.data?.totalElements,
          loading: false,
        }));
      })
      .catch(error => {
        toastComponentError(
          error?.response?.data?.mensagem
            ? error?.response?.data?.mensagem
            : error?.message,
          theme,
        );
        setDataGridState(prev => ({
          ...prev,
          rows: [],
          rowCount: 0,
          loading: false,
        }));
      });
  };

  useEffect(() => {
    if (checkboxAll) setSelectionModel(dataGridState.rows.map(row => row.id));
  }, [checkboxAll, dataGridState.rows]);

  useEffect(() => {
    const selectedRows = dataGridState.rows.filter(row =>
      selectionModel.includes(row.id),
    );

    const str = selectedRows.map(totalizador => totalizador.valorTitulo);
    function getSum(total, num) {
      return total + num;
    }
    setTotalizing(str.reduce(getSum, 0));
  }, [selectionModel]);

  useEffect(() => {
    listOverdues(0, 10);
  }, [sort, sortParams]);

  return (
    <DataGridWrapper
      headerComponent={() => (
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xs={12} md={6}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Chip
                style={{ width: '100%' }}
                label={
                  formattedMoney(totalizing)
                    ? 'Total selecionado: ' + formattedMoney(totalizing)
                    : 'Total selecionado: 0'
                }
                color="primary"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 20,
              flexWrap: 'wrap',
            }}
          >
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Chip
                onClick={() => createExcel()}
                color="secondary"
                variant="outlined"
                style={{
                  width: '100%',
                  cursor: 'pointer',
                }}
                label={
                  loading ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      style={{ display: 'flex' }}
                    />
                  ) : (
                    'Solicitar Excel'
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Chip
                color="primary"
                onClick={() =>
                  history.push('/backoffice/arquivos-gerados', {
                    formState: form,
                  })
                }
                variant="outlined"
                style={{ width: '100%', cursor: 'pointer' }}
                label="Consultar arquivos gerados"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      title={'Painel de Vencidos'}
      crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
      rows={dataGridState.rows}
      formData={filterInputs}
      key={dataGridState.rows?.map(item => item?.id)}
      columns={columns}
      loading={dataGridState.loading}
      pageSize={dataGridState.pageSize}
      minHeight="600px"
      pagination
      page={dataGridState.page}
      styleFormBackground={{ display: 'flex', justifyContent: 'flex-end' }}
      componentsProps={{
        pagination: { classes: null },
      }}
      onPageSizeChangeFunction={newPageSize => {
        listOverdues(0, newPageSize);
      }}
      onPageChange={newPage => {
        listOverdues(newPage, dataGridState.pageSize);
      }}
      onSelectionModelChange={newSelection => {
        const ids = Object.values(newSelection);
        const newIds = ids.filter(id => !selectionModel.includes(id));
        setSelectionModel(old => [...old, ...newIds]);
      }}
      selectionModel={selectionModel}
      checkboxSelection
      rowsPerPageOptions={[10, 20, 50, 100]}
      paginationMode="server"
      rowCount={dataGridState.rowCount}
    />
  );
};

export default PainelVencidos;
