import React from 'react';
import { Alert } from '@material-ui/lab';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { ContainerDialogAction } from '../Modals/style';
const ModalActionLote = ({
  modalLote,
  onClose,
  onConfirm,
  transacoes = [],
}) => {
  console.log('transacoes', transacoes);
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={modalLote.open}
      >
        <DialogContent>
          <Alert
            icon={false}
            severity={modalLote.action === 'Reprovar' ? 'warning' : 'success'}
            style={{ width: '100%', marginBottom: '1rem' }}
          >
            Você deseja <b>{modalLote.action}</b> estas transações?
          </Alert>
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Data</TableCell>
                  <TableCell component="th">Titular</TableCell>
                  <TableCell component="th">Beneficiário</TableCell>
                  <TableCell component="th" align="right">
                    Valor
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transacoes.map(transacao => (
                  <TableRow key={transacao.id}>
                    <TableCell style={{ fontWeight: 300 }}>
                      {transacao?.dataCriacao}
                    </TableCell>
                    <TableCell style={{ fontWeight: 300 }}>
                      {transacao?.nomeTitular}
                    </TableCell>
                    <TableCell style={{ fontWeight: 300 }}>
                      {transacao?.nomeBeneficiario}
                    </TableCell>
                    <TableCell style={{ fontWeight: 300 }} align="right">
                      {transacao?.valor}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>

        <DialogActions>
          <ContainerDialogAction>
            <Button onClick={onClose} color="default" size="small">
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => onConfirm(modalLote)}
              color={modalLote.action === 'Reprovar' ? 'secondary' : 'primary'}
              autoFocus
              size="small"
            >
              {modalLote.action}
            </Button>
          </ContainerDialogAction>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalActionLote;
