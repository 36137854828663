import {
  AccordionSummary,
  Accordion,
  Grid,
  Box,
  AccordionDetails,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
  TextField,
} from '@material-ui/core';
import React from 'react';
import {
  MdAdd,
  MdExpandLess,
  MdExpandMore,
  MdRemove,
  MdSearch,
} from 'react-icons/md';
import { useStyles } from '../../styles';
import PlusButton from '../../../../components/Buttons/PlusButton';
import DataGrid from '../../../../components/DataGrid';
import { IoMdTrash } from 'react-icons/io';

export const AccordionLists = ({
  expanded,
  nome,
  vinculo,
  onchange,
  disabledPlusButton,
  onPlusButton,
  accordionProp,
  onChangeFiltro,
  onFiltroSend,
  filtro,
  openShowVincularServicos,
  loadingDatagrid,
  rows,
  deleteAll,
  columnsServices,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item lg={12} className={classes.gridPadding}>
      <Accordion expanded={expanded} className={classes.borderRadius10}>
        {accordionProp ? (
          accordionProp
        ) : (
          <AccordionSummary
            expandIcon={
              <IconButton disabled={disabledPlusButton} onClick={onchange}>
                {expanded ? (
                  <MdExpandLess size={20} />
                ) : (
                  <MdExpandMore size={20} />
                )}
              </IconButton>
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              width={'100%'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                <Typography
                  variant="body1"
                  component="h3"
                  className={classes.marginRight10}
                >
                  {nome}
                </Typography>
                <PlusButton
                  title={'Adicionar Serviços'}
                  startIcon={<MdAdd color="background" />}
                  disabled={disabledPlusButton}
                  onClick={onPlusButton}
                />
                <Tooltip
                  style={{ marginLeft: 8 }}
                  title={'Desvincular todos os Serviços'}
                >
                  <IconButton disabled={disabledPlusButton} onClick={deleteAll}>
                    <IoMdTrash
                      color={
                        disabledPlusButton
                          ? theme.palette.error['light']
                          : theme.palette.error['main']
                      }
                      size={18}
                      spacing={2}
                    />
                  </IconButton>
                </Tooltip>
                <TextField
                  value={filtro}
                  disabled={!openShowVincularServicos}
                  onChange={onChangeFiltro}
                  variant="outlined"
                  id="Contas"
                  style={{ marginLeft: 8 }}
                  fullWidth
                  label="Buscar serviço"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  InputProps={{
                    style: { borderRadius: '20px' },
                    endAdornment: (
                      <IconButton
                        style={{ padding: '5px' }}
                        disabled={!openShowVincularServicos}
                        onClick={onFiltroSend}
                      >
                        <MdSearch
                          style={{ cursor: 'pointer' }}
                          color="inherit"
                          size={20}
                        />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Box>
          </AccordionSummary>
        )}
        <AccordionDetails>
          {!!openShowVincularServicos ? (
            vinculo
          ) : (
            <DataGrid
              style={{ width: '100%' }}
              columns={columnsServices}
              rows={rows}
              loading={loadingDatagrid}
              autoHeight
              pageSize={10}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
