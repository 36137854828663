import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Subcontainer,
  ContainerInput,
  ContainerKeyboard,
  ContainerButtons,
  ContainerTitle,
  ContainerInputPassword,
  ContainerError,
} from './styles';
import KeyboardWrapper from '.';
import KeyboardPassword from '../../KeyboardPassword';
import Button from '../../Buttons/ConfirmButton';
import { MdSend } from 'react-icons/md';
const Keyboard = ({ callback }) => {
  const formRef = useRef(null);
  const keyboardRef = useRef(null);
  const [error, setError] = useState('');

  function sendPassword() {
    console.log('btn sendPassword');
    try {
      if (keyboardRef.current?.password_value.length < 6) {
        setError('Senha Inválida');
        return;
      }
      if (callback) {
        callback(
          window.btoa(JSON.stringify(keyboardRef.current?.password_value)),
        );
      }
      keyboardRef.current?.clean_array([]);
      keyboardRef.current?.clean_field('');
    } catch (err) {
      keyboardRef.current?.clean_array([]);
      keyboardRef.current?.clean_field('');
    }
  }

  return (
    <Container ref={formRef}>
      <Subcontainer>
        <KeyboardPassword ref={keyboardRef} />
        <ContainerButtons>
          <Button
            color="primary"
            variant="contained"
            disabled={false}
            title={"Enviar"}
            width={100}
            startIcon={<MdSend color="background"/>}
            onClick={sendPassword}
          >
            Enviar
          </Button>
        </ContainerButtons>
        <ContainerError>{!!error && <p>{error}</p>}</ContainerError>
      </Subcontainer>
    </Container>
  );
};

export default Keyboard;
