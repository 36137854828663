import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import LoadingBtn from '../../components/Buttons/ConfirmButton';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ErrorIcon from '@material-ui/icons/Error';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import {
  api_multipague_contaAbrir,
  api_multipague_conta,
} from '../../services/api';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';

import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import { useStyles } from './styles';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { Box, IconButton, Popover } from '@material-ui/core';
import { FaEllipsisV } from 'react-icons/fa';

const cookies = new Cookies();
const tknAccess = cookies.get('tkn-access');

export default function Monitor() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [controleBusca, setControleBusca] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const [rowsState, setRowsState] = React.useState(0);
  const [controle, setControle] = useState('');
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [rowsExcel, setRowsExcel] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [carregandoExcel, setCarregandoExcel] = useState({
    carregaExcel: false,
    carregaIconBotaoExcel: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [loadingEmail, setLoadingEmail] = React.useState(false);
  const [loadingContaAtivacao, setLoadingContaAtivacao] = React.useState(false);
  const [loadingMonetizar, setLoadingMonetizar] = React.useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [situacao, setSituacao] = useState('');
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [sort, setSort] = useState('DESC');
  const [dataSolicitacaoDe, setDataSolicitacaoDe] = useState('');
  const [dataSolicitacaoAte, setDataSolicitacaoAte] = useState('');
  const [sortParams, setSortParams] = useState('cliente.dataSolicitacao');
  const [openMenuActions, setOpenMenuActions] = useState(false);

  const breadcrumbs = [{ name: 'Home', link: '/backoffice/new-backoffice' }];

  const dateComparator = (v1, v2) => {
    new Date(v2) - new Date(v1);
  };

  const columns = [
    { field: 'cnpjCpf', headerName: 'CPF/CNPJ', width: 160 },
    { field: 'nome', headerName: 'Nome', width: 300 },
    { field: 'tipoControle', headerName: 'Controle', width: 120 },
    { field: 'conta', headerName: 'Conta', width: 150 },
    {
      field: 'solicitacao',
      headerName: 'Data Solicitação',
      width: 120,
      renderHeader: () => {
        return (
          <div
            style={{
              fontSize: '13px',
              cursor: 'pointer',
              fontWeight: 'bold',
              whiteSpace: 'break-spaces',
              lineHeight: '110%',
            }}
            onClick={() => {
              setSort(sort === 'DESC' ? 'ASC' : 'DESC');
              setSortParams('cliente.dataSolicitacao');
            }}
          >
            Data de Solicitação
          </div>
        );
      },
    },
    {
      field: 'emailMinhaConta',
      headerName: 'Ações',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: event => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = event => {
          setAnchorEl(event.currentTarget);
          setOpenMenuActions(true);
        };

        const handleClose = () => {
          setAnchorEl(null);
          setOpenMenuActions(false);
        };

        const open =
          Boolean(anchorEl) && openMenuActions && event.row.id === rowId;
        const id = open ? 'simple-popover' : undefined;
        return (
          <>
            <IconButton onClick={handleClick} sx={{ mt: 2 }}>
              <FaEllipsisV aria-describedby={id} variant="contained" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'left',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'left',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
            >
              <Box
                style={{
                  padding: '15px 5px 10px 10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Tooltip title="Abrir Detalhes da Conta">
                  <SearchIcon
                    size="2em"
                    style={{
                      marginRight: '7px',
                      cursor: 'pointer',
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => {
                      handleOpenUser(event.row);
                    }}
                  />
                </Tooltip>
                {event.row.situacao == 'AGUARDANDO' ||
                event.row.situacao == 'ATIVADA' ||
                event.row.situacao == 'BLOQUEADA' ||
                event.row.situacao == 'PENDENTE ATIVAÇÃO' ? (
                  <Tooltip title="Abrir uma Conta no Banco">
                    <AccountBalanceIcon
                      disabled
                      color="disabled"
                      style={{ marginRight: '7px' }}
                    />
                  </Tooltip>
                ) : (
                  <>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    {loading == false && (
                      <Tooltip title="Abrir uma Conta no Banco">
                        <AccountBalanceIcon
                          style={{
                            marginRight: '7px',
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                          }}
                          onClick={() => {
                            handleOpen(event.row);
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
                <>
                  {event.row.situacao == 'PENDENTE' ? (
                    <Tooltip title="Enviar Email de Ativação">
                      <EmailIcon
                        disabled
                        color="disabled"
                        style={{
                          marginRight: '7px',
                        }}
                      />
                    </Tooltip>
                  ) : loadingEmail && event.row.id === rowId ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </div>
                  ) : (
                    <Tooltip title="Enviar Email de Ativação">
                      <EmailIcon
                        style={{
                          marginRight: '7px',
                          cursor: 'pointer',
                          color: theme.palette.primary.main,
                        }}
                        onClick={() => {
                          handleEnviarEmail(event.row);
                        }}
                      />
                    </Tooltip>
                  )}
                  {event.row.situacao == 'ATIVADA' ||
                  event.row.situacao == 'PENDENTE' ? (
                    <Tooltip title="Ativar Conta Manual">
                      <LockOpenIcon
                        disabled
                        color="disabled"
                        style={{
                          marginRight: '7px',
                        }}
                      />
                    </Tooltip>
                  ) : loadingContaAtivacao && event.row.id === rowId ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </div>
                  ) : (
                    <Tooltip title="Ativar Conta Manual">
                      <LockOpenIcon
                        style={{
                          marginRight: '7px',
                          cursor: 'pointer',
                          color: theme.palette.primary.main,
                        }}
                        onClick={() => {
                          handleAtivarConta(event.row);
                        }}
                      />
                    </Tooltip>
                  )}
                  {event.row.dataTarifaAberturaConta != null ? (
                    <Tooltip title="Monetizar">
                      <MonetizationOnIcon
                        disabled
                        color="disabled"
                        style={{
                          marginRight: '7px',
                        }}
                      />
                    </Tooltip>
                  ) : loadingMonetizar && event.row.id === rowId ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </div>
                  ) : (
                    <Tooltip title="Monetizar">
                      <MonetizationOnIcon
                        style={{
                          marginRight: '7px',
                          cursor: 'pointer',
                          color: theme.palette.primary.main,
                        }}
                        onClick={() => {
                          handleMonetizar(event.row);
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'emailEnviado',
      headerName: 'Email Enviado',
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <>
            {' '}
            {event.row.situacao == 'PENDENTE ATIVAÇÃO' ||
            event.row.situacao == 'ATIVADA' ? (
              <ThumbUpIcon
                variant="contained"
                color="primary"
                cursor="pointer"
                style={{ margin: 'auto' }}
              ></ThumbUpIcon>
            ) : (
              <ThumbDownIcon
                variant="contained"
                color="error"
                cursor="pointer"
                style={{ margin: 'auto' }}
              ></ThumbDownIcon>
            )}{' '}
          </>
        );
      },
    },
    {
      field: 'dataAprovacao',
      headerName: 'Data de Aprovação',
      width: 170,
      valueGetter: params => params.row.cliente.dataAprovacao,
      renderCell: params => (
        <p>
          {params.row.cliente.dataAprovacao == null
            ? '-'
            : moment(new Date(params.row.cliente.dataAprovacao)).format(
                'DD/MM/YYYY',
              )}
        </p>
      ),
      type: 'date',
      renderHeader: () => {
        return (
          <div
            style={{
              fontSize: '13px',
              cursor: 'pointer',
              fontWeight: 'bold',
              whiteSpace: 'break-spaces',
              lineHeight: '110%',
            }}
            onClick={() => {
              setSort(sort === 'DESC' ? 'ASC' : 'DESC');
              setSortParams('cliente.dataAprovacao');
            }}
          >
            Data de Aprovação
          </div>
        );
      },
      sortComparator: dateComparator,
    },
    {
      field: 'dataAbertura',
      headerName: 'Data de Abertura',
      width: 170,
      renderCell: params => {
        return (
          <p>
            {!params.row.dataAbertura
              ? '-'
              : moment(params.row.dataAbertura).format('DD/MM/YYYY')}
          </p>
        );
      },
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      type: 'number',
      width: 130,
      renderCell: event => (
        <>
          {event.row.situacao == 'BLOQUEADA' ? (
            <>
              <p>{event?.row?.situacao}</p>
              <Tooltip
                title={
                  event?.row?.motivoBloqueio
                    ? event?.row?.motivoBloqueio
                    : 'Sem motivo declarado'
                }
              >
                <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
              </Tooltip>
            </>
          ) : (
            <>
              <p>{event.row.status}</p>
            </>
          )}
        </>
      ),
    },
    {
      field: 'bancoPadrao',
      headerName: 'Banco Padrão',
      type: 'number',
      width: 180,
    },
  ];

  const handleOpen = value => {
    localStorage.setItem('valorAbrirConta', value.cliente.id);
    localStorage.setItem('valorAbrirContaCliente', value.id);
    setOpen(true);
  };

  const handleChange = event => {
    setControle(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenUser = value => {
    localStorage.setItem('valorIdDetalhes', value.id);
    localStorage.setItem('valorIdBloqueio', value.cliente.id);
    history.push('/backoffice/detalhes-cliente');
  };

  function handleAbrirConta() {
    setLoadButton(true);
    setLoading(true);
    const valorId = localStorage.getItem('valorAbrirConta');
    const valorIdCliente = localStorage.getItem('valorAbrirContaCliente');

    api_multipague_contaAbrir
      .post(
        `/conta/abrirContaBancoParceiro/${valorId}/conta/${valorIdCliente}?tipoControleConta=${controle}`,
      )
      .then(function (response) {
        setLoading(false);
        setLoadButton(false);
        toastComponentSuccess('Conta aprovada com sucesso!', theme);
        setTimeout(function () {
          location.reload();
        }, 3000);
      })
      .catch(function (error) {
        setLoadButton(false);
        toastComponentError(error.response.data.mensagem, theme);
        setTimeout(function () {
          location.reload();
        }, 3000);
      });
  }

  const handleEnviarEmail = value => {
    const valorId = value.id;
    setLoadingEmail(true);
    const URL = `/aberturaConta/ativaContaEmail/${valorId}`;
    api_multipague_conta
      .get(URL)
      .then(function (response) {
        setLoadingEmail(false);
        toastComponentSuccess('Email enviado ao usuário', theme);
        setTimeout(function () {
          location.reload();
        }, 3000);
      })
      .catch(function (error) {
        toastComponentError(error.response.data.mensagem, theme);
        setLoadingEmail(false);
      });
  };

  const handleAtivarConta = value => {
    const valorId = value.id;
    const valorIdConta = value.cliente.id;
    var bodyAtivar = {
      idCliente: valorIdConta,
      idClienteConta: valorId,
    };
    setLoadingContaAtivacao(true);
    const URL = `cliente/ativarCliente`;
    api_multipague_conta
      .put(URL, bodyAtivar)
      .then(function (response) {
        setLoadingContaAtivacao(false);
        toastComponentSuccess('Conta ativada!', theme);
        setTimeout(function () {
          location.reload();
        }, 3000);
      })
      .catch(function (error) {
        toastComponentError(error.response.data.mensagem, theme);
        setLoadingContaAtivacao(false);
      });
  };

  const handleMonetizar = value => {
    const valorId = value.id;
    setLoadingMonetizar(true);
    const URLTarifa = `conta/tarifador?idClienteConta=${valorId}`;
    api_multipague_conta
      .post(URLTarifa)
      .then(function (response) {
        setLoadingMonetizar(false);
        toastComponentSuccess('Monetização ativada', theme);
        setTimeout(function () {
          location.reload();
        }, 3000);
      })
      .catch(function (error) {
        if (error.response.status == 400 || error.response.status == 404) {
          toastComponentError(error.response.data.mensagem, theme);
          setLoadingMonetizar(false);
        }
      });
  };

  const handleNome = event => {
    setNome(event.target.value);
  };
  const handleCnpj = event => {
    setCnpj(event.target.value);
  };
  const handleControle = event => {
    setControleBusca(event.target.value);
  };
  const handleSituacao = event => {
    setSituacao(event.target.value);
  };
  const handleSolicitacaoDe = event => {
    setDataSolicitacaoDe(event.target.value);
  };
  const handleSolicitacaoAte = event => {
    setDataSolicitacaoAte(event.target.value);
  };
  const AuthStr = 'Bearer '.concat(tknAccess);
  const URL = `/conta/filtro?sort=${sort}&sortParam=${sortParams}&situacao=${situacao}&dataSolicitacaoDE=${dataSolicitacaoDe}&dataSolicitacaoAte=${dataSolicitacaoAte}&cnpjCpf=${cnpj}&tipoControleConta=${controleBusca}&nome=${encodeURIComponent(
    nome + '%',
  )}`;

  function handleSearch() {
    listMonitor();
    setCarregandoExcel({ carregaExcel: false });
  }

  async function listMonitor(page = 0) {
    setRowsState(page);
    setLoadingTable(true);
    api_multipague_conta
      .get(`${URL}&page=${page}`, {
        headers: {
          Accept: 'application/paged+json',
        },
      })
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data.content;

        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            solicitacao: moment(new Date(data.cliente.dataSolicitacao)).format(
              'DD/MM/YYYY',
            ),
            cnpjCpf: data.cliente.cnpjCpf,
            nome: data.cliente.nome,
            dataAprovacao:
              data.cliente.dataAprovacao == null
                ? ''
                : moment(new Date(data.cliente.dataAprovacao)).format(
                    'DD/MM/YYYY',
                  ),
            dataAbertura: data.dataAbertura,
            situacao: data.situacao.replace(
              'PENDENTE_ATIVACAO',
              'PENDENTE ATIVAÇÃO',
            ),
            usuarioCadastro: data.dataAprovacao,
            bancoPadrao: data.banco.nome,
            tipoControle: data.tipoControle == null ? '' : data.tipoControle,
            conta:
              data.conta == null ? '' : `${data.conta}-${data.digitoConta}`,
          }));
          setLoadingTable(false);
          setRowCount(response.data.totalElements);
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadingTable(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  useEffect(() => {
    listMonitor();
  }, [sort]);

  function downloadPDF() {
    api_multipague_conta
      .get(`${URL}`)
      .then(response => {
        const requestPdfData = response.data;

        if (requestPdfData.length) {
          const formatPdfData = requestPdfData.map(data => ({
            ...data,
            id: data.id,
            solicitacao: moment(new Date(data.cliente.dataSolicitacao)).format(
              'DD/MM/YYYY',
            ),
            cnpjCpf: data.cliente.cnpjCpf,
            nome: data.cliente.nome,
            dataAprovacao:
              data.cliente.dataAprovacao == null
                ? ''
                : moment(new Date(data.cliente.dataAprovacao)).format(
                    'DD/MM/YYYY',
                  ),
            dataAbertura: data.dataAbertura,
            situacao: data.situacao.replace(
              'PENDENTE_ATIVACAO',
              'PENDENTE ATIVAÇÃO',
            ),
            usuarioCadastro: data.dataAprovacao,
            bancoPadrao: data.banco.nome,
            tipoControle: data.tipoControle == null ? '' : data.tipoControle,
            conta:
              data.conta == null ? '' : `${data.conta}-${data.digitoConta}`,
          }));
          localStorage.setItem('pdfMonitor', JSON.stringify(formatPdfData));
          window.open('/backoffice/pdf-monitor');
        }
      })
      .catch(error => {
        toastComponentError(error.response.data.mensagem, theme);
      });
  }

  function downloadExcel() {
    console.log('Downloading');
    setCarregandoExcel({
      carregaExcel: true,
      carregaIconBotaoExcel: true,
    });
    api_multipague_conta
      .get(`${URL}`)
      .then(response => {
        const requestExcelData = response.data;

        if (requestExcelData.length) {
          const formatExcelData = requestExcelData.map(data => ({
            ...data,
            id: data.id,
            solicitacao: moment(new Date(data.cliente.dataSolicitacao)).format(
              'DD/MM/YYYY',
            ),
            cnpjCpf: data.cliente.cnpjCpf,
            nome: data.cliente.nome,
            dataAprovacao:
              data.cliente.dataAprovacao == null
                ? ''
                : moment(new Date(data.cliente.dataAprovacao)).format(
                    'DD/MM/YYYY',
                  ),
            dataAbertura: data.dataAbertura,
            situacao: data.situacao.replace(
              'PENDENTE_ATIVACAO',
              'PENDENTE ATIVAÇÃO',
            ),
            usuarioCadastro: data.dataAprovacao,
            bancoPadrao: data.banco.nome,
            tipoControle: data.tipoControle == null ? '' : data.tipoControle,
            conta:
              data.conta == null ? '' : `${data.conta}-${data.digitoConta}`,
          }));
          setCarregandoExcel({
            carregaIconBotaoExcel: true,
            carregaExcel: true,
          });
          setRowsExcel(formatExcelData);
        }
      })
      .then(() => {
        setCarregandoExcel({
          carregaExcel: true,
          carregaIconBotaoExcel: true,
        });
      })
      .catch(error => {
        toastComponentError(error.response.data.mensagem, theme);
      })
      .finally(() => {
        setCarregandoExcel({
          carregaExcel: true,
          carregaIconBotaoExcel: false,
        });
      });
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            inputProps={{ className: classes.inputColor }}
            fullWidth
            label="Nome"
            id="outlined-size-small"
            onChange={handleNome}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <TextField
            inputProps={{ className: classes.inputColor }}
            fullWidth
            label="CPF/CNPJ"
            id="outlined-size-small"
            onChange={handleCnpj}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 3 },
      },
      {
        html: () => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          >
            <InputLabel
              style={{
                backgroundColor: theme.palette.background.paper,
                padding: '0 5px',
              }}
              shrink
              htmlFor="outlined-age-native-simple"
            >
              Controle
            </InputLabel>
            <Select
              className={classes.inputColor}
              fullWidth
              size="small"
              value={controleBusca}
              onChange={handleControle}
              label="Controle"
            >
              <MenuItem value="INTERNO">Interno (Multiplica)</MenuItem>
              <MenuItem value="EXTERNO">Externo (Cliente)</MenuItem>
              <MenuItem value="ESCROW">Escrow</MenuItem>
              <MenuItem value="ADM_FOLHA">Adm Folha</MenuItem>
            </Select>
          </FormControl>
        ),
        grid: { xs: 12, sm: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Solicitação - De:"
            id="outlined-size-small"
            value={dataSolicitacaoDe}
            onChange={handleSolicitacaoDe}
            variant="outlined"
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Solicitação - Até:"
            id="outlined-size-small"
            variant="outlined"
            value={dataSolicitacaoAte}
            onChange={handleSolicitacaoAte}
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2 },
      },
      {
        html: () => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          >
            <InputLabel
              style={{
                backgroundColor: theme.palette.background.paper,
                padding: '0 5px',
              }}
              shrink
              id="demo-simple-select-outlined-label"
            >
              Situação
            </InputLabel>
            <Select
              fullWidth
              label="Situação"
              id="outlined-size-small"
              variant="outlined"
              size="small"
              onChange={handleSituacao}
            >
              <MenuItem value="ATIVADA">ATIVADA</MenuItem>
              <MenuItem value="PENDENTE_ATIVACAO">PENDENTE ATIVAÇÃO</MenuItem>
              <MenuItem value="PENDENTE">PENDENTE</MenuItem>
              <MenuItem value="AGUARDANDO">AGUARDANDO</MenuItem>
              <MenuItem value="BLOQUEADA">BLOQUEADA</MenuItem>
            </Select>
          </FormControl>
        ),
        grid: { xs: 12, sm: 3 },
      },
    ],
    searchButton: {
      searchButton: () => handleSearch(),
    },
    cleanButton: {
      cleanButton: () => location.reload(),
    },
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginBottom: '20px' }}
            >
              Selecione o Tipo de Controle
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Controle
              </InputLabel>
              <Select
                size="small"
                value={controle}
                onChange={handleChange}
                label="Controle"
                style={{ marginBottom: '20px' }}
              >
                <MenuItem value="INTERNO">Interno (Multiplica)</MenuItem>
                <MenuItem value="EXTERNO">Externo (Cliente)</MenuItem>
                <MenuItem value="ESCROW">Escrow</MenuItem>
                <MenuItem value="ADM_FOLHA">Adm Folha</MenuItem>
              </Select>
              <LoadingBtn
                variant="contained"
                color="primary"
                width="200"
                className={classes.btnAbrirConta}
                isLoading={loadButton}
                onClick={() => {
                  handleAbrirConta();
                }}
                startIcon={!loadButton && <AccountBalanceIcon />}
              >
                Abrir Conta
              </LoadingBtn>
            </FormControl>
          </div>
        </Fade>
      </Modal>

      <DataGridWrapper
        PDFCSV
        handlePDFCSV={{
          downloadExcel: () => downloadExcel(),
          downloadPDF: () => downloadPDF(),
          rowsExcel: rowsExcel,
          title: 'Monitor',
          ExcelColumn: [
            { label: 'Cnpj / CPF', value: 'cnpjCpf' },
            { label: 'Nome', value: 'nome' },
            { label: 'Tipo', value: 'tipoControle' },
            { label: 'Conta', value: 'conta' },
            { label: 'Solicitação', value: 'solicitacao' },
            { label: 'Data Aprovação', value: 'dataAprovacao' },
            { label: 'Situação', value: 'situacao' },
            { label: 'Data Abertura', value: 'dataAbertura' },
            { label: 'Banco Padrão', value: 'bancoPadrao' },
          ],
          carregaExcel: carregandoExcel.carregaExcel,
          carregaIconExcel: carregandoExcel.carregaIconBotaoExcel,
        }}
        rows={rows}
        title={'Monitor'}
        columns={[
          ...columns,
          { field: 'solicitacao', sortable: false },
          {
            field: 'dataAprovacao',
            sortable: false,
          },
          {
            field: 'dataAbertura',
            sortable: false,
          },
        ]}
        sortingOrder={['desc']}
        loading={loadingTable}
        pageSize={10}
        formData={filterInputs}
        minHeight="600px"
        pagination
        page={rowsState}
        onRowClick={params => setRowId(params.id)}
        paginationMode="server"
        rowCount={rowCount}
        onPageChange={newPage => {
          listMonitor(newPage);
        }}
        crumb={breadcrumbs}
      />
    </>
  );
}
