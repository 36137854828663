import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  wrapperBox: {
    borderRadius: '20px',
    padding: 40,
  },
  gridTypography: {
    display: 'flex',
    borderRadius: '20px',
    padding: '5px 20px',
    border: `1px solid ${theme.palette.divider}`,
  },
  divButtonBackSave: {
    marginTop: '1rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  btnAdd: {
    width: '25px',
    height: '25px',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.primary.dark, 0.8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: alpha(
      theme.palette.appBar[theme.palette.type].bgMenu,
      0.7,
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  labelSelect: {
    backgroundColor: theme.palette.background.paper,
    padding: '0 5px',
  },
  boxRadius: {
    borderRadius: '20px',
    padding: 30,
  },
  gridPadding: {
    paddingTop: 50,
  },
  borderRadius10: {
    borderRadius: '10px',
  },
  marginRight10: {
    marginRight: 10,
  },
  fullWidth: {
    width: '100%',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divVinculaServicos: {
    width: '100%',
    position: 'relative',
    padding: 15,
  },
  divVinculoBorder: {
    borderRadius: '20px',
    height: 300,
    padding: '20px',
    marginBottom: '20px',
    overflowY: 'auto',
    overflowX: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
  },
  listSize: {
    // height: 40,
    // padding: "0 5px",
  },
  listColorContainer: {
    width: '100%',
    padding: 0,
    bgcolor: 'background.paper',
  },
  selectAllCheckbox: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: -5,
    left: '31px',
    padding: '0 20px',
    borderRadius: '20px',
    zIndex: 9,
  },
  textFieldSpace: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
}));
