import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import InputText from '../InputText';
import {
  Container,
  ContainerInput,
  ContainerPassword,
  Options,
  ContainerButton,
} from './styles';
import { useTheme } from '@material-ui/core';

const KeyboardPassword = (props, ref) => {
  const theme = useTheme();
  const [input, setInput] = useState('');
  const [passwordOptions, setPasswordOptions] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [optionsPasswordValue, setOptionsPasswordValue] = useState([]);

  function shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  function addPassword(val) {
    if (optionsPasswordValue.length < 6) {
      setOptionsPasswordValue([...optionsPasswordValue, val]);
      const valueInput = input + '0';
      setInput(valueInput);
    }
  }
  function removePassword() {
    const newPassword = optionsPasswordValue.slice(0, -1);

    const valueInput = input.slice(0, -1);

    setOptionsPasswordValue(newPassword);
    setInput(valueInput);
  }

  useEffect(() => {
    console.log('password options ====> ', optionsPasswordValue);
    // props.onChange(optionsPasswordValue);
  }, [optionsPasswordValue]);

  useEffect(() => {
    const shuffleArray = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
    setPasswordOptions([
      [shuffleArray[0], shuffleArray[1]],
      [shuffleArray[2], shuffleArray[3]],
      [shuffleArray[4], shuffleArray[5]],
      [shuffleArray[6], shuffleArray[7]],
      [shuffleArray[8], shuffleArray[9]],
    ]);
  }, []);

  const onChangeInput = event => {
    const input = event.target.value;
    setPassword(input);
  };

  useImperativeHandle(ref, () => ({
    clean_array: val => {
      setOptionsPasswordValue(val);
    },
    clean_field: val => {
      setInput(val);
    },
    password_value: optionsPasswordValue,
  }));

  return (
    <Container>
      <ContainerInput>
        <InputText
          value={input}
          onChange={e => onChangeInput(e)}
          name="password"
          id="password"
          label="Senha financeira"
          type="password"
          disabled={true}
        />
      </ContainerInput>
      <ContainerPassword>
        {passwordOptions.map(value => (
          <Options onClick={() => addPassword(value)}>
            <p>
              {value[0]} ou {value[1]}
            </p>
          </Options>
        ))}
        <Options onClick={() => removePassword()}>
          <RiDeleteBack2Fill
            color={theme.palette.getContrastText(theme.palette.primary.main)}
            size={16}
          />
        </Options>
      </ContainerPassword>
    </Container>
  );
};

export default forwardRef(KeyboardPassword);
