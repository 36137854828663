import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { FaSave } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Wrapper from '../../../components/Wrapper';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { Cookies } from 'react-cookie';
import Box from '../../../components/Box';
import { MdArrowBack, MdSave } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';

import {
  api_multipague_configuracao,
  api_multipague_configuracao2,
} from '../../../services/api';
import { toastComponentError, toastComponentSuccess } from '../../../components/Toast';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: '10px',
    width: '100%',
    minWidth: 120,
  },
  grid: {
    marginBottom: '20px',
  },
}));

export default function AssociacaoContasCadastro() {
  const history = useHistory();
  const classes = useStyles();
  const cookies = new Cookies();
  const theme = useTheme();
  const [loadButton, setLoadButton] = useState(false);

  const [selecionarConta, setSelecionarConta] = useState([]);
  const [valorMinimo, setValorMinimo] = useState('');
  const [valorMaximo, setValorMaximo] = useState('');
  const [selectContas, setSelectContas] = useState('');
  const [combo, setCombo] = useState('');
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);
  const clienteConta = localStorage.getItem('idConsulta');

  const novoCadastro = {
    codigoClienteConta: parseInt(clienteConta),
    codigoEvento: selectContas,
    utilizaEsteira: state.checkedA,
    valorMaximo: parseInt(valorMaximo),
    valorMinimo: parseInt(valorMinimo),
  };

  useEffect(() => {
    api_multipague_configuracao2
      .get(`/evento`)
      .then(response => {
        console.log('Select do cadastro ', response.data);

        const selectFilter = response.data;
        const aquiSelect = selectFilter.map(key => (
          <MenuItem key={key} value={key.id}>
            {key.descricao}
          </MenuItem>
        ));
        setCombo(selectFilter);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    api_multipague_configuracao
      .get(`/tipoConta`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .post(`/eventoClienteConta`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Cadastro feito com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/associacao-contas-consulta');
          // localStorage.clear()
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
          //   setTimeout(function () {
          //     location.reload();
          //   }, 5000);
        }
      });
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleSenha = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Wrapper
      title="Cadastro"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        {
          link: '/backoffice/associacao-contas-lista',
          name: 'Associação de Contas',
        },
        { link: '/backoffice/associacao-contas-consulta', name: 'Consulta' },
      ]}
    >
      <Box style={{ borderRadius: '20px', padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <FormControl
              variant="outlined"
              size="small"
              fullwidth
              className={classes.formControl}
            >
              <Autocomplete
                size="small"
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  setSelectContas(newValue.id);
                }}
                options={combo}
                getOptionLabel={option => option.id + ' - ' + option.descricao}
                style={{ width: '100%' }}
                renderInput={params => (
                  <TextField {...params} label="Evento" variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              label="Valor Mínimo"
              variant="outlined"
              size="small"
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorMinimo(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              label="Valor Máximo"
              variant="outlined"
              size="small"
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorMaximo(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormLabel component="legend">Esteira</FormLabel>
            <CustomSwitch
              checked={state.checkedA}
              onChange={handleSenha}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12} md={4}></Grid>
          <div
            style={{
              margin: '10px',
              width: '100%',
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <LoadingBtn
              variant="contained"
              color="disabled"
              width="115"
              title={'Voltar'}
              onClick={() => {
                history.go(-1);
              }}
              startIcon={<MdArrowBack color="background" />}
            >
              Voltar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              color="primary"
              width="115"
              title={'Salvar'}
              isLoading={loadButton}
              startIcon={
                !loadButton && <FaSave size="1em" color="background" />
              }
              onClick={() => {
                handleSalvar();
              }}
            >
              Salvar
            </LoadingBtn>
          </div>
        </Grid>
      </Box>
    </Wrapper>
  );
}
