import React from 'react';
import { Container } from './styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
const Loading = ({ show }) => {
  return (
    <Container showPopUp={show}>
      {/* <Loader
        type="Rings"
        color="#EEE"
        height={150}
        width={150}
        // timeout={3000} //3 secs
      /> */}
      <img
        height={150}
        width={250}
        alt=""
        src="https://storageappbeyondus.blob.core.windows.net/multisolucoes/tema/loadingbeyondbank.gif"
      />
    </Container>
  );
};

export default Loading;
