import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, Switch } from '@material-ui/core';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';

import { api_multipague_configuracao } from '../../../services/api';

import { MdArrowBack, MdSave } from 'react-icons/md';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

export default function CadastroEventos() {
  const history = useHistory();
  const theme = useTheme();
  const editCodigo = localStorage.getItem('banco-codigo');
  const editDescricao = localStorage.getItem('banco-nome');
  const editAgenciaPadrao = localStorage.getItem('banco-agenciaPadrao');
  const editEspeciePadrao = localStorage.getItem('banco-especiePadrao');
  const editContaPadraoId = localStorage.getItem('banco-tipoContaPadrao-id');
  const editContaPadraoDescricao = localStorage.getItem(
    'banco-tipoContaPadrao-descricao',
  );
  const editNossoNumero = localStorage.getItem('banco-origemNossoNumero');
  const editISPB = localStorage.getItem('banco-ISPB');
  const editBancoParceiro = localStorage.getItem('banco-bancoParceiro');
  const editBancoAtivo = localStorage.getItem('banco-ativo');
  const [loadButton, setLoadButton] = useState(false);
  const [selecionarConta, setSelecionarConta] = useState([]);
  const [codigo, setCodigo] = useState(editCodigo === 'null' ? '' : editCodigo);
  const [descricao, setDescricao] = useState(
    editDescricao === 'null' ? '' : editDescricao,
  );
  const [agenciaPadrao, setAgenciaPadrao] = useState(editAgenciaPadrao);
  const [especiePadrao, setEspeciePadrao] = useState(
    editEspeciePadrao === 'null' ? '' : editEspeciePadrao,
  );
  const [contaPadrao, setContaPadrao] = useState(
    editContaPadraoDescricao === 'null' ||
      editContaPadraoDescricao === 'undefined'
      ? ''
      : editContaPadraoDescricao,
  );
  const [nossoNumero, setNossoNumero] = useState(
    editNossoNumero === 'null' ? '' : editNossoNumero,
  );
  const [ISPB, setISPB] = useState(
    editISPB === 'null' || editISPB === 'undefined' ? '' : editISPB,
  );

  const [bancoParceiro, setBancoParceiro] = useState(
    editBancoParceiro === 'null' ? '' : editBancoParceiro,
  );
  const [bancoAtivo, setBancoAtivo] = useState(editBancoAtivo);
  const [selectContas, setSelectContas] = useState('');
  const [state, setState] = React.useState({
    checkedA: JSON.parse(bancoParceiro),
  });
  const [active, setActive] = React.useState({
    checkedA: JSON.parse(bancoAtivo),
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const editaCadastro = {
    ativo: active.checkedA,
    nome: descricao,
    agenciaPadrao: agenciaPadrao,
    especiePadrao: especiePadrao,
    origemNossoNumero: nossoNumero,
    id: parseInt(codigo),
    bancoMultipague: state.checkedA,
    tipoContaPadrao:
      selectContas === ''
        ? editContaPadraoId.toString()
        : selectContas.toString(),
    ispb: ISPB,
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(`/tipoConta`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(`/banco`, editaCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Informação editada com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/bancos-lista');
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleCodigo = event => {
    setCodigo(event.target.value);
  };
  const handleSenha = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleAtivo = event => {
    setActive({ ...active, [event.target.name]: event.target.checked });
  };

  return (
    <Wrapper
      title="Editar banco"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/bancos-lista', name: 'Bancos' },
      ]}
      crumbActive="Editar"
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={2}>
            <FormControlLabel
              label="Ativo"
              style={{ marginLeft: '8px' }}
              labelPlacement="start"
              control={
                <CustomSwitch
                  checked={active.checkedA}
                  onChange={handleAtivo}
                  color="primary"
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormControlLabel
              label="Banco Parceiro"
              style={{ marginLeft: '8px' }}
              labelPlacement="start"
              control={
                <CustomSwitch
                  checked={state.checkedA}
                  onChange={handleSenha}
                  color="primary"
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="Código"
              id="outlined-size-small"
              value={codigo}
              disabled
              onChange={handleCodigo}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              value={descricao}
              onChange={e => setDescricao(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Agência Padrão"
              id="outlined-size-small"
              value={agenciaPadrao}
              onChange={e => setAgenciaPadrao(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Espécie Padrão"
              id="outlined-size-small"
              value={especiePadrao}
              onChange={e => setEspeciePadrao(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={selecionarConta}
              getOptionLabel={option => option.descricao}
              onChange={(event, option) => {
                setSelectContas(option.id);
              }}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo Conta"
                  placeholder={contaPadrao}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Origem Nosso Número"
              id="outlined-size-small"
              value={nossoNumero}
              onChange={e => setNossoNumero(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="ISPB"
              id="outlined-size-small"
              value={ISPB}
              onChange={e => {
                const value = e.target.value;
                if (value === '' || /^[0-9]{0,8}$/.test(value)) {
                  setISPB(value);
                }
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/bancos-lista');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            title={'Salvar'}
            width="115"
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
