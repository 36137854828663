import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MdSend } from 'react-icons/md';
import Switch from '@material-ui/core/Switch';
import DateComponent from '../../components/InputDate';
import InputText from '../../components/InputText';
import CpfCnpj from '../../components/Inputs/CNPJ';
import Button from '../../components/Buttons/ConfirmButton';
import { Box, Divider, Typography } from '@material-ui/core';
import { BorderAll, ThumbDownOutlined, ThumbUpOutlined } from '@material-ui/icons';
import { api_multipague_esteira } from '../../services/api';
import { Container, ButtonTable, useStyles } from './styles';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { useHistory } from 'react-router-dom';
import { HistoryOutlined } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import { Form } from '@unform/web';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { contextContentString } from '../../utils/applicationContext';

const Estera = () => {
  const theme = useTheme();
  const history = useHistory();
  const formInfo = history.location?.state?.formInfo;
  const formPopUpRef = useRef(null);
  const formRef = useRef(null);
  const [rowsTable, setRowsTable] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [historicoParecer, setHistoricoParecer] = useState('');
  const [idAddParecer, setIdAddParecer] = useState('');
  const [parecerList, setParecerList] = useState();
  const [aprovar, setAprovar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parecerLote, setParecerLote] = useState('');
  const [aprovacaoEmLote, setAprovacaoEmLote] = useState('');
  const [parecerOpen, setParecerOpen] = useState({ open: false, parecer: '' });
  const [reprovar, setReprovar] = useState('');
  const [loadingLote, setLoadingLote] = useState(false);
  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [checkedRow, setCheckedRow] = useState({
    id: '',
    approved: null,
    row: '',
  });
  const [dataEntrada, setDataEntrada] = useState({
    inicial: formInfo?.dataEntrada?.inicial,
    final: formInfo?.dataEntrada?.final,
  });
  const [dataParecer, setDataParecer] = useState({
    inicial: formInfo?.dataParecer?.inicial,
    final: formInfo?.dataParecer?.final,
  });
  const [checkStatusEsteira, setCheckStatusEsteira] = useState(
    formInfo?.checkStatusEsteira
      ? formInfo.checkStatusEsteira
      : [
          {
            label: 'pendentes',
            enum: 'PENDENTE',
            value: 1,
            checked: true,
          },
          {
            label: 'aprovados',
            enum: 'APROVADO',
            value: 2,
            checked: false,
          },
          {
            label: 'negados',
            enum: 'NEGADO',
            value: 3,
            checked: false,
          },
          {
            label: 'pendente',
            enum: 'PENDENTE',
            value: 4,
            checked: false,
          },
          {
            label: 'pendente',
            enum: 'REJEITADO',
            value: 5,
            checked: false,
          },
          {
            label: 'expirado',
            enum: 'EXPIRADO',
            value: 6,
            checked: false,
          },
        ],
  );
  const [selectedValue, setSelectedValue] = React.useState('');

  const columnsTable = [
    {
      field: 'data_entrada',
      headerName: 'Data da Entrada',
      type: 'string',
      width: 150,
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ Empresa',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      minWidth: 200,
    },
    {
      field: 'nome_empresa',
      headerName: 'Empresa',
      headerAlign: 'center',
      type: 'string',
      width: 280,
      renderCell: event => {
        return (
          <Tooltip title={event?.row?.clienteConta?.nome}>
            <div
              style={{
                lineHeight: '150%',
                height: '50px',
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                fontSize:
                  event?.row?.clienteConta?.nome?.length > 40 ? '10px' : '13px',
                whiteSpace: 'break-spaces',
              }}
            >
              {event?.row?.clienteConta?.nome}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'cnpj_beneficiario',
      headerName: 'CNPJ Beneficiário',
      headerAlign: 'center',
      type: 'string',
      align: 'center',
      width: 150,
    },
    {
      field: 'beneficiario',
      headerName: 'Beneficiário',
      headerAlign: 'center',
      type: 'string',
      width: 280,
      renderCell: event => {
        return (
          <Tooltip
            title={
              event.row.beneficiarioFavorecido?.agencia != null
                ? event.row.beneficiarioFavorecido?.nome
                : '-'
            }
          >
            <div
              style={{
                lineHeight: '150%',
                height: '50px',
                alignItems: 'center',
                display: 'flex',
                fontSize:
                  event.row.beneficiarioFavorecido?.nome.length > 40
                    ? '10px'
                    : '13px',
                flexWrap: 'wrap',
                whiteSpace: 'break-spaces',
              }}
            >
              {event.row.beneficiarioFavorecido?.agencia != null
                ? event.row.beneficiarioFavorecido?.nome
                : '-'}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'conta_beneficiario',
      headerName: 'Conta',
      headerAlign: 'center',
      type: 'string',
      width: 150,
    },
    {
      field: 'parecer',
      headerName: 'Parecer',
      headerAlign: 'center',
      sortable: false,
      width: 110,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <ButtonTable
              onClick={() => {
                setSelectedValue('');
                event.row.situacao === 'PENDENTE'
                  ? setParecerOpen({
                      open: false,
                      parecer: event.row.parecer,
                    })
                  : setParecerOpen({
                      open: true,
                      parecer: event.row.parecer,
                    });
              }}
              type="button"
            >
              {event.row.situacao === 'PENDENTE' ? (
                <FaEyeSlash
                  size={20}
                  color={
                    event.row.situacao === 'PENDENTE'
                      ? theme.palette.text.disabled
                      : theme.palette.primary.main
                  }
                />
              ) : (
                <FaEye
                  size={20}
                  color={
                    event.row.situacao === 'PENDENTE'
                      ? theme.palette.text.disabled
                      : theme.palette.primary.main
                  }
                />
              )}
            </ButtonTable>
          </div>
        );
      },
    },
    {
      field: 'aprovar_negar',
      headerName: 'Aprovar / Negar',
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {event.row.situacao !== 'PARCIALMENTE_APROVADO' ? (
              <ButtonTable
                onClick={() => {
                  setAprovar(true);
                  event.row.situacao === 'PENDENTE' &&
                    preCheckedParecer(event.row.id, true, event.row);
                }}
                type="button"
              >
                {event.row.situacao === 'APROVADO' ? (
                  <ThumbUpIcon
                    variant="contained"
                    cursor="pointer"
                    style={{ color: '#28a745' }}
                  />
                ) : event.row.situacao === 'PENDENTE' ? (
                  <ThumbUpOutlined
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ThumbUpIcon
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: '#c5c5c5',
                    }}
                  />
                )}
              </ButtonTable>
            ) : (
              ''
            )}

            {event.row.situacao !== 'PARCIALMENTE_APROVADO' ? (
              <ButtonTable
                onClick={() => {
                  setAprovar(false);
                  event.row.situacao === 'PENDENTE' &&
                    preCheckedParecer(event.row.id, false, event.row);
                }}
                type="button"
              >
                {event.row.situacao === 'NEGADO' ? (
                  <ThumbDownIcon
                    variant="contained"
                    cursor="pointer"
                    style={{ color: '#dc3545' }}
                  />
                ) : event.row.situacao === 'PENDENTE' ? (
                  <ThumbDownOutlined
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ThumbDownIcon
                    variant="contained"
                    cursor="pointer"
                    style={{
                      color: '#c5c5c5',
                    }}
                  />
                )}
              </ButtonTable>
            ) : (
              ''
            )}
          </div>
        );
      },
      // renderCell: event => {
      //   return (
      //     <div
      //       style={{
      //         display: 'flex',
      //         justifyContent: 'center',
      //         alignItems: 'center',
      //         width: '100%',
      //       }}
      //     >
      //       <ButtonTable
      //         onClick={() => {
      //           setSelectedValue('');
      //           event.row.situacao === 'PENDENTE' &&
      //             preCheckedParecer(event.row.id, null, event.row);
      //           event.row.situacao != 'PENDENTE' &&
      //             openParecer(event.row.id, event.row.parecer);
      //         }}
      //         type="button"
      //       >
      //         <AiFillFolderAdd
      //           size={20}
      //           color={theme.palette.secondary.light}
      //         />
      //       </ButtonTable>
      //     </div>
      //   );
      // },
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 130,
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 130,
      renderCell: event =>
        event.row?.valor?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      field: 'nome_evento',
      headerName: 'Evento',
      headerAlign: 'center',
      width: 150,
      disableClickEventBubbling: true,
    },
    {
      field: 'usuario',
      headerName: 'Usuário',
      width: 150,
      disableClickEventBubbling: true,
    },
    {
      field: 'historico',
      headerName: 'Histórico',
      width: 150,
      align: 'center',
      renderCell: event => {
        return (
          <Tooltip title="Histórico de aprovações">
            <ButtonTable>
              {loadingHistorico && event.row.id === rowId ? (
                <CircularProgress size={24} />
              ) : (
                <HistoryOutlined
                  onClick={() => handleHistorico(event.row)}
                  color={ 'primary' }
                  size={24}
                />
              )}
            </ButtonTable>
          </Tooltip>
        );
      },
    },
  ];

  const handleHistorico = row => {
    setRowId(row.codigoTransacaoEtapa);
    setLoadingHistorico(true);
    api_multipague_esteira
      .get(`/transacao-etapa?codigoTransacaoEtapa=${row.codigoTransacaoEtapa}`, {
        headers: {
          ApplicationContext: window.btoa(contextContentString),
        },
      })
      .then(response => {
        setLoadingHistorico(false);
        history.push('/backoffice/historico-teds-backoffice', {
          historic: response.data,
          formInfo: {
            dataEntrada: dataEntrada,
            dataParecer: dataParecer,
            checkStatusEsteira: checkStatusEsteira,
            ...formRef.current.getData(),
          },
        });
      })
      .catch(err => {
        setLoadingHistorico(false);
        toastComponentError(err.response?.data?.mensagem, theme);
      });
  };

  function cleanFilter() {
    setDataEntrada({
      final: '',
      inicial: '',
    });
    setDataParecer({
      final: '',
      inicial: '',
    });
    formRef.current?.setData({
      cnpj_empresa: '',
      nome_empresa: '',
    });

    const formatted = checkStatusEsteira.map(val => ({
      ...val,
      checked: false,
    }));
    setCheckStatusEsteira(formatted);
  }

  useEffect(() => {
    if (!!formInfo?.cnpj_empresa || !!formInfo?.nome_empresa) {
      formRef.current?.setData({
        cnpj_empresa: formInfo?.cnpj_empresa,
        nome_empresa: formInfo?.nome_empresa,
      });
      return () => {
        formRef.current?.setData({
          cnpj_empresa: '',
          nome_empresa: '',
        });
      };
    }
  }, [formInfo]);

  useEffect(() => {
    submitForm();
  }, []);

  function openAddParecer(id) {
    setIdAddParecer(id);
    setShowPopUp(true);
  }

  const preCheckedParecer = (id, approved, row) => {
    openAddParecer(id);
    setCheckedRow({
      id: id,
      approved: approved,
      row: row,
    });
  };

  const openParecer = (id, parecer) => {
    setParecerList(parecer);
    setShowPopUp(true);
    setIdAddParecer(id);
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const checkedParecer = useCallback(
    async (id, approved, row) => {
      setShowPopUp(false);
      try {
        const { codigoTransacaoEtapa } = row;
        const { clienteConta } = row;
        const findRow = rowsTable.filter(row => row.id === id);

        if (findRow.length > 0) {
          if (!!historicoParecer) {
            const { data } = await api_multipague_esteira.put(
              `/transacao-etapa/${codigoTransacaoEtapa}`,
              {
                codigoClienteConta: clienteConta.codigo,
                parecer: historicoParecer,
                situacao: approved ? 'APROVADO' : 'NEGADO',
              },
            );

            const formattedRow = rowsTable.map(row => ({
              ...row,
              situacao: row.id === id ? data.situacao : row.situacao,
            }));

            setRowsTable(formattedRow);

            toastComponentSuccess(`Situação atualizada com sucesso`, theme);
            setHistoricoParecer('');
            submitForm();

            return;
          } else {
            toastComponentError(
              !historicoParecer && `Erro: Por favor adicione o parecer`,
              theme,
            );
            openAddParecer(id);
            return;
          }
        }
      } catch (err) {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
          theme,
        );
      }
    },
    [rowsTable, historicoParecer, selectedValue],
  );

  async function carregarEsteira() {
    try {
      setLoading(true);
      const { data } = await api_multipague_esteira.get(
        `/transacao-etapa?situacao=PENDENTE`,
      );

      if (data.length) {
        moment.locale('pt-br');
        const formatted = data.map(val => ({
          ...val,
          id: uuidv4(),
          codigoTransferencia: val.codigoTransferencia,
          nome_evento: val.evento.descricao,
          data_entrada:
            moment(val.dataCadastro).format('DD/MM/YYYY') +
            ' às ' +
            moment(val.dataCadastro).format('LT'),
          cnpj: val.clienteConta.cnpj,
          situacao: val.situacao,
          nome_empresa: val.clienteConta.nome,
          nome_banco: val.banco.nome,
          conta_cliente: `${val.clienteConta.agencia} / ${val.clienteConta.conta}-${val.clienteConta.digitoConta}`,
          data_parecer:
            moment(val.dataSituacao).format('DD/MM/YYYY') +
            ' às ' +
            moment(val.dataCadastro).format('LT'),
          usuario: val.usuarioCadastro,
          parecerAprovado: null,
          beneficiario:
            val.beneficiarioFavorecido?.agencia != null
              ? val.beneficiarioFavorecido.nome
              : '-',
          cnpj_beneficiario:
            val.beneficiarioFavorecido?.agencia != null
              ? val.beneficiarioFavorecido.cnpjCpf
              : '-',
          conta_beneficiario:
            val.beneficiarioFavorecido?.agencia != null
              ? `${val.beneficiarioFavorecido.banco.id} ${val.beneficiarioFavorecido.agencia} ${val.beneficiarioFavorecido.conta}-${val.beneficiarioFavorecido.digitoConta}`
              : '-',
          parecer: val.parecer,
        }));

        setRowsTable(formatted);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
    }
  }

  const submitForm = useCallback(async () => {
    try {
      setLoading(true);
      const situacao = checkStatusEsteira.filter(val => val.checked);

      const { data } = await api_multipague_esteira.get(
        `/transacao-etapa?dataInicioEntrada=${
          !!dataEntrada.inicial
            ? moment(dataEntrada.inicial).format('YYYY-MM-DD')
            : ''
        }&dataFimEntrada=${
          !!dataEntrada.final
            ? moment(dataEntrada.final).format('YYYY-MM-DD')
            : ''
        }&dataInicioSituacao=${
          !!dataParecer.inicial
            ? moment(dataParecer.inicial).format('YYYY-MM-DD')
            : ''
        }&dataFimSituacao=${
          !!dataParecer.final
            ? moment(dataParecer.final).format('YYYY-MM-DD')
            : ''
        }&situacao=${
          situacao.length ? situacao[0].enum : ''
        }&cnpj=${formRef?.current
          ?.getData()
          ?.cnpj_empresa.replace(/[^\d]/g, '')}&nome=${
          formRef?.current?.getData()?.nome_empresa
        }`,
      );
      if (data.length) {
        moment.locale('pt-br');
        const formatted = data.map(val => ({
          ...val,
          id: uuidv4(),
          codigoTransferencia: val.codigoTransferencia,
          nome_evento: val.evento.descricao,
          data_entrada:
            moment(val.dataCadastro).format('DD/MM/YYYY') +
            ' às ' +
            moment(val.dataCadastro).format('LT'),
          cnpj: val.clienteConta.cnpj,
          nome_empresa: val.clienteConta.nome,
          nome_banco: val.banco.nome,
          conta_cliente: `${val.clienteConta.agencia} / ${val.clienteConta.conta}-${val.clienteConta.digitoConta}`,
          data_parecer:
            moment(val.dataSituacao).format('DD/MM/YYYY') +
            ' às ' +
            moment(val.dataCadastro).format('LT'),
          dataPagamento: val.dataPagamento || null,
          usuario: val.usuarioCadastro,
          id: val.codigoTransacaoEtapa,
          parecerAprovado: null,
          beneficiario:
            val.beneficiarioFavorecido?.agencia != null
              ? val.beneficiarioFavorecido.nome
              : '-',
          cnpj_beneficiario:
            val.beneficiarioFavorecido?.agencia != null
              ? val.beneficiarioFavorecido.cnpjCpf
              : '-',
          conta_beneficiario:
            val.beneficiarioFavorecido?.agencia != null
              ? `${val.beneficiarioFavorecido.banco.id} ${val.beneficiarioFavorecido.agencia} ${val.beneficiarioFavorecido.conta}-${val.beneficiarioFavorecido.digitoConta}`
              : '-',
          parecer: val.parecer,
        }));

        const expirados = formatted.filter(item =>
          moment(item.dataPagamento).isBefore(
            moment(Date.now()).format('YYYY-MM-DD'),
          ),
        );

        setRowsTable(
          checkStatusEsteira[3].checked == true ? expirados : formatted,
        );
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setRowsTable([]);
      console.log(err);
      toastComponentError(
        ` Codigo: ${err.response?.data.codigo} - ${err.response?.data.mensagem}`,
        theme,
      );
    }
  }, [dataEntrada, dataParecer, checkStatusEsteira, formInfo, formRef]);

  const PopUpParecerList = () => {
    return (
      <Form ref={formPopUpRef}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showPopUp}
          className={classes.modal}
          onClose={() => {
            setShowPopUp(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade onEnter={() => setHistoricoParecer('')} in={showPopUp}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">
                {aprovar ? 'Aprovar' : 'Negar'}
              </h2>
              <p
                id="transition-modal-description"
                style={{ marginBottom: '10px' }}
              >
                Adicione um parecer antes de enviar.
              </p>

              <TextField
                fullWidth
                id="listParecer"
                name="listParecer"
                label="Parecer"
                variant="outlined"
                style={{ margin: '3% 0' }}
                onChange={e => {
                  setHistoricoParecer(e.target.value);
                }}
              />
              <Button
                color="primary"
                variant="contained"
                disabled={loadingLote}
                title={'Enviar'}
                width={150}
                startIcon={<MdSend color="background" />}
                onClick={() =>
                  checkedParecer(
                    checkedRow.id,
                    aprovar ? true : !aprovar ? false : '',
                    checkedRow.row,
                  )
                }
              ></Button>
            </div>
          </Fade>
        </Modal>
      </Form>
    );
  };

  function checkFilters(id) {
    const formatted = checkStatusEsteira.map(check => ({
      ...check,
      checked: check.value === id ? !check.checked : false,
    }));

    setCheckStatusEsteira(formatted);
  }

  const [gridSelection, setGridSelection] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openReprovar, setOpenReprovar] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setAprovacaoEmLote(true);
  };
  const handleOpenReprovar = () => {
    setOpenReprovar(true);
    setReprovar(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseReprovar = () => {
    setOpenReprovar(false);
  };

  async function submitApproval() {
    if (aprovacaoEmLote) {
      setLoadingLote(true);
      handleClose;

      const arr = new Array();
      for (let i = 0; i <= gridSelection.length; i++) {
        var convertInt = gridSelection;
        var result = convertInt.map(function (x) {
          return parseInt(x, 10);
        });
        var sendTransacoes = convertInt.map(items => ({
          codigoClienteConta: items.clienteConta.codigo,
          codigoTransacaoEtapa: items.codigoTransacaoEtapa,
        }));
        var obj = {
          parecer: parecerLote,
          situacao: 'APROVADO',
          transacoes: sendTransacoes,
        };
        arr.push(obj);
      }
      const params = obj;
      await api_multipague_esteira
        .post(`/transacao-etapa/processar-lote`, params)
        .then(function (response) {
          toastComponentSuccess(
            'Aprovação em lote concluída com sucesso!',
            theme,
          );
          setTimeout(function () {
            window.location.href = '/backoffice/esteira-back';
          }, 5000);
        })
        .catch(function (error) {
          console.log('Errou Aqui!', error);
        });
    } else if (reprovar === true) {
      setLoadingLote(true);
      handleClose;

      const arr = new Array();
      for (var i = 0; i <= gridSelection.length; i++) {
        var convertInt = gridSelection;
        var result = convertInt.map(function (x) {
          return parseInt(x, 10);
        });
        var sendTransacoesNeg = convertInt.map(items => ({
          codigoClienteConta: items.clienteConta.codigo,
          codigoTransacaoEtapa: items.codigoTransacaoEtapa,
        }));
        var obj = {
          parecer: parecerLote,
          situacao: 'NEGADO',
          transacoes: sendTransacoesNeg,
        };
        arr.push(obj);
      }
      const params = obj;
      await api_multipague_esteira
        .post(`/transacao-etapa/processar-lote`, params)
        .then(function (response) {
          toastComponentSuccess(
            'Reprovação em lote concluída com sucesso!',
            theme,
          );
          setTimeout(function () {
            window.location.href = '/backoffice/esteira-back';
          }, 5000);
        })
        .catch(function (error) {
          console.log('Errou Aqui!', error);
          setLoadingLote(false);
        });
    }
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <DateComponent
            callback={event =>
              setDataEntrada({
                ...dataEntrada,
                inicial: event,
              })
            }
            label="Data de Entrada - Inicial"
            id="data-inicial-filtro"
            value={dataEntrada.inicial}
            dataMinima="1999-01-01"
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <DateComponent
            callback={event =>
              setDataEntrada({
                ...dataEntrada,
                final: event,
              })
            }
            label="Data de Entrada - Final"
            id="data-inicial-filtro"
            value={dataEntrada.final}
            dataMinima="1999-01-01"
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <DateComponent
            value={dataParecer.inicial}
            label="Data do Parecer - Inicial"
            id="data-inicial-filtro"
            callback={event =>
              setDataParecer({
                ...dataParecer,
                inicial: event,
              })
            }
            dataMinima="1999-01-01"
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <DateComponent
            value={dataParecer.final}
            label="Data do Parecer - Final"
            id="data-inicial-filtro"
            callback={event =>
              setDataParecer({
                ...dataParecer,
                final: event,
              })
            }
            dataMinima="1999-01-01"
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <CpfCnpj
            type="cpf_cnpj"
            id="cnpj_empresa"
            name="cnpj_empresa"
            label="CNPJ"
            variant="outlined"
          />
        ),
        grid: { xs: 12, sm: 5, md: 6, lg: 6 },
      },
      {
        html: () => (
          <InputText
            id="nome_empresa"
            name="nome_empresa"
            label="Nome"
            variant="outlined"
          />
        ),
        grid: { xs: 12, sm: 5, md: 6, lg: 6 },
      },
      {
        html: () => (
          <Grid
            item
            md={12}
            lg={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  onChange={event => checkFilters(1)}
                  checked={checkStatusEsteira[0].checked}
                />
              }
              label="Pendentes"
            />
            <FormControlLabel
              control={
                <Switch
                  onChange={event => checkFilters(2)}
                  checked={checkStatusEsteira[1].checked}
                />
              }
              label="Aprovados"
            />
            <FormControlLabel
              data-testid="switch-negados"
              control={
                <Switch
                  onChange={event => checkFilters(3)}
                  checked={checkStatusEsteira[2].checked}
                />
              }
              label="Negados"
            />
            <FormControlLabel
              data-testid="switch-expirados"
              control={
                <Switch
                  onChange={event => checkFilters(6)}
                  checked={checkStatusEsteira[5].checked}
                />
              }
              label="Expirados"
            />
            <FormControlLabel
              control={
                <Switch
                  onChange={event => checkFilters(5)}
                  checked={checkStatusEsteira[4].checked}
                />
              }
              label="Rejeitados"
            />
          </Grid>
        ),
        grid: { xs: 9, sm: 9, md: 9, lg: 9 },
      },
    ],
    searchButton: {
      searchButton: () => submitForm(),
    },
    cleanButton: {
      cleanButton: () => {
        carregarEsteira();
        cleanFilter();
      },
    },
  };

  return (
    <>
      {PopUpParecerList()}
      <Container ref={formRef} onSubmit={submitForm}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Aprovação em Lote</h2>
              <p
                id="transition-modal-description"
                style={{ marginBottom: '10px' }}
              >
                Adicione um parecer antes de enviar.
              </p>
              <TextField
                fullWidth
                style={{ marginBottom: '10px' }}
                id="outlined-multiline-static"
                label="Parecer"
                multiline
                rows={4}
                variant="outlined"
                onChange={e => {
                  setParecerLote(e.target.value);
                }}
              />
              <Button
                color="primary"
                variant="contained"
                disabled={loadingLote}
                width={150}
                title={'Enviar'}
                startIcon={<MdSend color="background" />}
                onClick={submitApproval}
              >
                Enviar
              </Button>
              {loadingLote && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={parecerOpen.open}
          onClose={() => setParecerOpen({ open: false, parecer: '' })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box
            style={{
              borderRadius: '20px',
              backgroundColor: useTheme().palette.background.paper,
              minWidth: '30vw',
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <h2
              style={{ paddingBottom: 5, textAlign: 'center' }}
              id="transition-modal-title"
            >
              Parecer
            </h2>
            <Divider />
            <Typography
              variant="p"
              style={{ marginTop: 10, textAlign: 'center' }}
            >
              {parecerOpen.parecer}
            </Typography>
          </Box>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openReprovar}
          onClose={handleCloseReprovar}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openReprovar}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Reprovação em Lote</h2>
              <p
                id="transition-modal-description"
                style={{ marginBottom: '10px' }}
              >
                Adicione um parecer antes de enviar.
              </p>
              <TextField
                fullWidth
                style={{ marginBottom: '10px' }}
                id="outlined-multiline-static"
                label="Parecer"
                multiline
                rows={4}
                variant="outlined"
                onChange={e => {
                  setParecerLote(e.target.value);
                }}
              />
              <Button
                color="primary"
                variant="contained"
                title={'Enviar'}
                disabled={loadingLote}
                startIcon={<MdSend color="background" />}
                width={150}
                onClick={submitApproval}
              >
                Enviar
              </Button>
              {loadingLote && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Fade>
        </Modal>

        <DataGridWrapper
          headerComponent={() => (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{
                  display: 'flex',
                  marginLeft: '6.8px',
                }}
              >
                <Chip
                  style={{ marginRight: '8px' }}
                  variant="variant"
                  label="Reprovação em Lote"
                  color="secondary"
                  onClick={handleOpenReprovar}
                />
                <Chip
                  variant="variant"
                  label="Aprovação em Lote"
                  color="secondary"
                  onClick={handleOpen}
                />
              </Grid>
            </Grid>
          )}
          formData={filterInputs}
          minHeight="600px"
          checkboxSelection
          rows={rowsTable}
          columns={columnsTable}
          pageSize={10}
          loading={loading}
          onSelectionModelChange={newSelection => {
            const selectedIDs = new Set(newSelection);
            const selectedRows = rowsTable.filter(row =>
              selectedIDs.has(row.id),
            );
            setGridSelection(selectedRows);
          }}
          title={'Esteira'}
          crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
        />
      </Container>
    </>
  );
};

export default Estera;
