import { combineReducers } from 'redux';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import app from './modules/app';

const RootReducer = combineReducers({
  app,
});

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export default createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);
