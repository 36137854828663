import React, { useEffect, useMemo, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputMask from 'react-input-mask';
import { useField } from '@unform/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const TesteComponente = ({ name, tipo, isLoading, ...rest }) => {
  const { fieldName, defaultValue = '', error, registerField,  clearError} = useField(name);
  const inputRef = useRef(null);

// ======= States ========
const [size, setSize] = useState(0);
// ======= States ========

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: tipo === 'money' ? inputRef.current.children[1].children[1] : inputRef.current.children[1].children[0],
      path: 'value',
    });
  }, [fieldName, registerField]);

  function retornarLengthText(value) {
    setSize(value.length);
  }

  function formatReal( int ) {
        var tmp = int+'';
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
        if( tmp.length > 6 )
                tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    console.log(tmp)
        return tmp;
  }

  const [stateTeste, setStateTeste] = useState(defaultValue || '')

  useEffect(() => {
    if (tipo === 'money') {
      const value = !isNaN(defaultValue) ? String(defaultValue) : defaultValue;
      setStateTeste(value.replace('.', ''))
    }

  },[defaultValue])

  const renderInput = useMemo(() => {

    switch (tipo) {
      case 'money': {
        return (
          <TextField
            size="small"
            style={{ width: '100%' }}
            InputLabelProps={{
                    shrink: true,
            }}
            id="input-mask-money"
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            } }
            onChange={event => setStateTeste(event.target.value.replace(',', '').replace('.', ''))}
            variant="outlined"
            // defaultValue={defaultValue}
            ref={inputRef}
            error={!!error}
            helperText={ error }
            onFocus={ clearError }
            value={formatReal(stateTeste)}
            {...rest}
         />
        )
      }
      case 'cpf_cnpj': {
        return (
          <InputMask
            mask={size > 14 ? '99.999.999/9999-99' : '999.999.999-999'}
            maskChar=""
            onChange={ event => retornarLengthText(event.target.value) }
            onFocus={clearError}
          >
            {() => (
              <TextField
                size="small"
                InputLabelProps={{
                    shrink: true,
                }}
                onFocus={clearError}
                style={{ width: '100%' }}

                variant="outlined"
                defaultValue={defaultValue}
                error={!!error}
                helperText={error}
                ref={inputRef}
                InputProps={
                  isLoading && {
                    endAdornment: (
                      <CircularProgress
                        color="inherit"
                        size={24}
                        circleDisableShrink
                      />
                    ),
                  }
                }
                {...rest}
              />
            )}
          </InputMask>
        )
      }
      case 'boleto': {
        return (
          <InputMask
            mask={'99999.99999 99999.999999 99999.999999 9 99999999999999'}
            maskChar=""
            defaultValue={ defaultValue }
            onFocus={ clearError }
            // {...rest}
          >
            {() => (
              <TextField
                size="small"
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ width: '100%' }}
                ref={inputRef}
                defaultValue={defaultValue}
                error={!!error}
                helperText={ error }
                onFocus={clearError}
                {...rest}
              />
            )}
          </InputMask>
        )
      }
      default: {
        return (
          <TextField
            size="small"
            variant="outlined"
            InputLabelProps={{
                  shrink: true,
            }}
            style={{ width: '100%', fontSize: '14px' }}
            helperText={error}
            error={!!error}
            defaultValue={defaultValue}
            ref={ inputRef }
            onFocus={ clearError }
            {...rest}
        />
        )
      }
  }

  }, [tipo, {...rest} ])




  return (
    renderInput
  );
};

export default TesteComponente;
