import React, { useState } from 'react';

import UseMonitor from '../../useMonitor';
import { itemsPorPaginaTransacao } from '../../service';
import { BtnLote } from './BtnLote';
import DataGrid from '../../../../components/DataGrid/DataGridWrapper';
import RenderActionsCell from '../../../../components/DataGrid/DataGridWrapper/Actions';
import { Receipt } from '@material-ui/icons';

import {
  Grid,
  TextField,
  FormControl,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
  Popover,
  IconButton,
} from '@material-ui/core';
import { maskCNPJ } from '../../../../util/mask';
import { MdSearch, MdClear } from 'react-icons/md';
import { FaEllipsisV } from 'react-icons/fa';

export const ListMonitor = () => {
  const {
    loadingList,
    openModalActionUnit,
    selectionModel,
    setSelectionModel,
    changePage,
    rows,
    rowCount,
    setAnchorEl,
    anchorEl,
    filter,
    onFilter,
  } = UseMonitor();

  const [form, setForm] = useState(filter);
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [cleanForm, setCleanForm] = useState({
    dataInicio: '',
    dataFim: '',
    cnpjCpf: '',
    nome: '',
    situacao: 'PENDENTE',
  });

  const clearForm = () => {
    setForm(cleanForm);
  };

  const submit = () => {
    onFilter(form);
  };

  const handleInput = e => {
    const value =
      e.target.name === 'cnpjCpf' ? maskCNPJ(e.target.value) : e.target.value;
    setForm({ ...form, [e.target.name]: value });
  };

  const openMenuAction = event => {
    setAnchorEl(event.currentTarget);
  };

  const permitiAcao = row => {
    return ['PENDENTE', 'PARCIALMENTE_APROVADO'].includes(row.situacao);
  };

  const columns = [
    { field: 'dataCriacao', headerName: 'Data de Criação', width: 180 },
    {
      field: 'titular',
      headerName: 'Titular',
      minWidth: 300,
      flex: 1,
      renderCell: event => {
        return (
          <div className="itemRow">
            <span>{event.row.nomeTitular}</span>
            {event.row.cnpjCpfTitular}
          </div>
        );
      },
    },
    {
      field: 'nomeBeneficiario',
      headerName: 'Beneficiário',
      minWidth: 300,
      flex: 1,
      renderCell: event => {
        return (
          <div className="itemRow">
            <span>{event.row.nomeBeneficiario}</span>
            {event.row.cnpjCpfBeneficiario}
          </div>
        );
      },
    },
    {
      field: 'conta',
      headerName: 'Conta',
      width: 200,
      renderCell: event => {
        return (
          <>
            {event.row.agencia != '' && (
              <div className="itemRow">
                {`${event.row.agencia} ${event.row.conta}`}
              </div>
            )}
          </>
        );
      },
    },
    { field: 'valor', headerName: 'Valor', width: 150 },
    { field: 'tipo', headerName: 'Tipo', width: 120 },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: event => {
        return <span title={event.row.status}>{event.row.status}</span>;
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 140,
      renderCell: event => {
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
          setOpenMenuActions(true);
        };

        const handleClose = () => {
          setAnchorEl(null);
          setOpenMenuActions(false);
        };

        const open =
          Boolean(anchorEl) && openMenuActions && event.row.id === rowId;
        const id = open ? 'simple-popover' : undefined;
        return (
          permitiAcao(event.row) && (
            <>
              <IconButton
                value={event.row.id}
                onClick={handleClick}
                sx={{ mt: 2 }}
              >
                <FaEllipsisV aria-describedby={id} variant="contained" />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {
                    borderRadius: '10px',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                  },
                }}
              >
                <>
                  {/* <Button onClick={openMenuAction} value={event.row.id}>
                    <Receipt
                      style={{ color: useTheme().palette.primary.main }}
                      color="background"
                    />
                  </Button> */}

                  <Button
                    onClick={() =>
                      openModalActionUnit('Aprovar', anchorEl.value)
                    }
                  >
                    Aprovar
                  </Button>
                  <Button
                    onClick={() =>
                      openModalActionUnit('Reprovar', anchorEl.value)
                    }
                  >
                    Reprovar
                  </Button>
                </>
              </Popover>
            </>
          )
        );
      },
    },
  ];

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label={'Data Início'}
            type="date"
            name="dataInicio"
            onChange={handleInput}
            value={form.dataInicio}
            size="small"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 4, lg: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label={'Data Fim'}
            type="date"
            size="small"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            name="dataFim"
            value={form.dataFim}
            onChange={handleInput}
          />
        ),
        grid: { xs: 12, sm: 12, md: 4, lg: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label={'CPF/CNPJ Beneficiário'}
            type="text"
            size="small"
            variant="outlined"
            name="cnpjCpf"
            value={form.cnpjCpf}
            onChange={handleInput}
          />
        ),
        grid: { xs: 12, sm: 12, md: 4, lg: 4 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label={'Nome Beneficiário'}
            type="text"
            size="small"
            variant="outlined"
            name="nome"
            value={form.nome}
            onChange={handleInput}
          />
        ),
        grid: { xs: 12, sm: 12, md: 7, lg: 7 },
      },
      {
        html: () => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          >
            <InputLabel htmlFor="outlined-status-native-simple">
              Status
            </InputLabel>
            <Select
              fullWidth
              size="small"
              name="situacao"
              value={form.situacao}
              onChange={handleInput}
              label="Status"
            >
              <MenuItem value="PENDENTE">Pendentes</MenuItem>
              <MenuItem value="APROVADO">Aprovados</MenuItem>
              <MenuItem value="NEGADO">Negados</MenuItem>
              <MenuItem value="REJEITADO">Rejeitados</MenuItem>
              <MenuItem value="EXPIRADO">Expirados</MenuItem>
            </Select>
          </FormControl>
        ),
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
      },
    ],
    searchButton: {
      searchButton: () => submit(),
    },
    cleanButton: {
      cleanButton: () => clearForm(),
    },
  };

  return (
    <DataGrid
      crumb={[
        {
          link: '/backoffice/monitor-aprovacao',
          name: 'Home',
        },
      ]}
      title="Monitor Aprovação Escrow"
      formData={filterInputs}
      headerComponent={BtnLote}
      minHeight="calc(100vh - 240px)"
      rows={rows}
      checkboxSelection
      isRowSelectable={params => {
        if (params) {
          return permitiAcao(params.row);
        }
      }}
      onRowClick={params => setRowId(params.id)}
      onSelectionModelChange={newSelection => {
        setSelectionModel(newSelection);
      }}
      selectionModel={selectionModel}
      columns={columns}
      loading={loadingList}
      rowCount={rowCount}
      pageSize={itemsPorPaginaTransacao}
      paginationMode="server"
      onPageChange={page => changePage(page)}
    />
  );
};
