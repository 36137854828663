import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../services/api';

export const getTerms = async () => {
  const URL = `/termo`;
  const { data } = await api_multipague_configuracao.get(URL);

  return data;
};

export const getMonitorTerms = async params => {
  const URL = `/termo`;
  const { data } = await api_multipague_conta.get(URL, { params });

  return data;
};

export const saveTerms = async body => {
  const URL = `/termo`;
  const { data } = await api_multipague_configuracao.post(URL, body);

  return data;
};

export const editTerm = async (id, body) => {
  const URL = `/termo/${id}`;
  const { data } = await api_multipague_configuracao.put(URL, body);

  return data;
};

export const saveServiceTerms = async (id, body) => {
  const URL = `/termo/${id}/servico`;
  const { data } = await api_multipague_configuracao.post(URL, body);

  return data;
};
export const saveContasTerms = async (id, body) => {
  const URL = `/termo/${id}/cliente-conta`;
  const { data } = await api_multipague_configuracao.post(URL, body);

  return data;
};

export const getServices = async () => {
  const URL = `/servico`;
  const { data } = await api_multipague_configuracao.get(URL);

  return data;
};

export const getContas = async () => {
  const URL = `/conta/filtro?situacao=ATIVADA`;

  const { data } = await api_multipague_conta.get(URL);

  return data;
};

export const getTermsServices = async id => {
  const URL = `/termo/${id}/servico`;
  const { data } = await api_multipague_configuracao.get(URL);

  return data;
};

export const getTermsContas = async id => {
  const URL = `/termo/${id}/cliente-conta`;
  const { data } = await api_multipague_configuracao.get(URL);

  return data;
};

export const deleteTerm = async id => {
  const URL = `/termo/${id}`;

  const { data } = await api_multipague_configuracao.delete(URL);

  return data;
};

export const deleteTermService = async (idTerm, idService) => {
  const URL = `/termo/${idTerm}/servico/${idService}`;

  const { data } = await api_multipague_configuracao.delete(URL);

  return data;
};

export const deleteTermContas = async (idTerm, idClienteConta) => {
  const URL = `/termo/${idTerm}/cliente-conta/${idClienteConta}`;

  const { data } = await api_multipague_configuracao.delete(URL);

  return data;
};

export const deleteAllTermService = async idTerm => {
  const URL = `/termo/${idTerm}/servico`;

  const { data } = await api_multipague_configuracao.delete(URL);

  return data;
};

export const deleteAllTermAccounst = async idTerm => {
  const URL = `/termo/${idTerm}/cliente-conta`;

  const { data } = await api_multipague_configuracao.delete(URL);

  return data;
};
