import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import Backoffice from '../pages/Backoffice';
import Introducing from '../pages/Introducing';
import PendenciasAbertura from '../pages/PendenciasAbertura';
import DetalhesCliente from '../pages/DetalhesCliente';
import Esteira from '../pages/Esteira';
import CadastroEventos from '../pages/Eventos/Cadastro';
import Parametros from '../pages/Parametros';
import CadastroAvisos from '../pages/Avisos/Cadastro';
import EditarAvisos from '../pages/Avisos/Editar';
import ListaAvisos from '../pages/Avisos/Lista';
import EditaEventos from '../pages/Eventos/Edicao';
import ListaEventos from '../pages/Eventos/Lista';
import CadastroBancos from '../pages/Bancos/Cadastro';
import EditaBancos from '../pages/Bancos/Edicao';
import ListaBancos from '../pages/Bancos/Lista';
import Configurations from '../pages/Configurations';
import PdfMonitor from '../pages/PdfMonitor';
import CadastroAssociacaoContas from '../pages/AssociacaoContas/Cadastro';
import EditaAssociacaoContas from '../pages/AssociacaoContas/Edicao';
import ListaAssociacaoContas from '../pages/AssociacaoContas/Lista';
import ConsultaAssociacaoContas from '../pages/AssociacaoContas/Consulta';
import CadastroAssociacaoBancos from '../pages/AssociacaoBancos/Cadastro';
import EditaAssociacaoBancos from '../pages/AssociacaoBancos/Edicao';
import ListaAssociacaoBancos from '../pages/AssociacaoBancos/Lista';
import ConsultaAssociacaoBancos from '../pages/AssociacaoBancos/Consulta';
import { DesbloqueioUser } from '../pages/DesbloqueioUsuario';
import { Users } from '../pages/usuario';
import { Usuarios } from '../pages/Usuarios';
import { VinculoUsuarios } from '../pages/VinculoUsuarios';
import { VinculoConta } from '../pages/VinculoConta';
import { VinculoEdit } from '../pages/VinculoEdit';
import { VinculoContaEdit } from '../pages/VinculoContaEdit';
import { EdicaoUsuarios } from '../pages/EdicaoUsuario';
import { RegistrarSenha } from '../pages/ConfirmSenha';

import PainelSaldos from '../pages/PainelSaldos';
import MonitorAprovacao from '../pages/MonitorAprovacao';
import HistoricoTeds from '../pages/HistoricoTeds';

import Tarifador from '../pages/Tarifador';
import EditaTarifador from '../pages/Tarifador/Edicao';
import CadastroTarifador from '../pages/Tarifador/Cadastro';
import CadastroFaixaTarifador from '../pages/Tarifador/Cadastro/CadastroFaixaTarifador';
import CadastroEventoTarifador from '../pages/Tarifador/Cadastro/CadastroEventoTarifador';
import EdicaoEventoTarifador from '../pages/Tarifador/Edicao/EdicaoEventoTarifador';
import EdicaoFaixaTarifador from '../pages/Tarifador/Edicao/EdicaoFaixaTarifador';
import TarifadorMonitorGeral from '../pages/Tarifador/MonitorGeral';

import CadastroFaixaAssociacaoConta from '../pages/AssociacaoContas/Cadastro/CadastroFaixaAssociacaoConta';
import ListaFavorecidoTarifador from '../pages/FavorecidoTarifador/Lista';
import CadastroFavorecidoTarifador from '../pages/FavorecidoTarifador/Cadastro';
import TarifadorMonitorConta from '../pages/Tarifador/MonitorConta';
import PainelVencidos from '../pages/PainelVencidos';
import ArquivosGerados from '../pages/PainelVencidos/ArquivosGerados';
import ArquivosGeradosSaldos from '../pages/PainelSaldos/ArquivosGerados';
import Servicos from '../pages/Servicos';
import EdicaoServicos from '../pages/Servicos/EdicaoServicos';
import CadastroServicos from '../pages/Servicos/CadastroServicos';
import CadastroServicoEvento from '../pages/Servicos/CadastroServicoEvento';
import Termos from '../pages/Termos';
import CadastroTermos from '../pages/Termos/CadastroTermos';
import MonitorTermos from '../pages/Termos/MonitorTermos';
import { logoutBackoffice } from '../util/Auth';
import HistoricoSaldos from '../pages/HistoricoSaldo';

function Routes() {
  const isProfileBackOffice = localStorage.getItem('profileBackOffice');
  const administradorEscrow = localStorage.getItem('administradorEscrow');

  useEffect(() => {
    if (isProfileBackOffice == 'false' && administradorEscrow != 'true') {
      window.location.href = logoutBackoffice();
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/backoffice" exact component={Introducing} />
        <Route path="/backoffice/new-backoffice" exact component={Backoffice} />
        <Route
          path="/backoffice/pendencias-abertura"
          exact
          component={PendenciasAbertura}
        />
        <Route
          path="/backoffice/detalhes-cliente"
          component={DetalhesCliente}
        />
        <Route path="/backoffice/esteira-back" component={Esteira} />

        <Route path="/backoffice/pwd" component={DesbloqueioUser} />

        <Route
          path="/backoffice/eventos-cadastro"
          component={CadastroEventos}
        />
        <Route path="/backoffice/parametros" component={Parametros} />
        <Route path="/backoffice/avisos-lista" component={ListaAvisos} />
        <Route path="/backoffice/avisos-cadastro" component={CadastroAvisos} />
        <Route path="/backoffice/avisos-editar" component={EditarAvisos} />
        <Route path="/backoffice/configurations" component={Configurations} />
        <Route path="/backoffice/eventos-editar" component={EditaEventos} />
        <Route path="/backoffice/eventos-lista" component={ListaEventos} />
        <Route path="/backoffice/bancos-cadastro" component={CadastroBancos} />
        <Route path="/backoffice/bancos-editar" component={EditaBancos} />
        <Route path="/backoffice/bancos-lista" component={ListaBancos} />

        <Route
          path="/backoffice/tarifador-cadastro"
          component={CadastroTarifador}
        />
        <Route
          path="/backoffice/evento-tarifador-cadastro"
          component={CadastroEventoTarifador}
        />

        <Route
          exact
          path="/backoffice/favorecido-tarifador-lista"
          component={ListaFavorecidoTarifador}
        />
        <Route
          exact
          path="/backoffice/favorecido-tarifador-form"
          component={CadastroFavorecidoTarifador}
        />
        <Route
          exact
          path="/backoffice/favorecido-tarifador-form/:id"
          component={CadastroFavorecidoTarifador}
        />

        <Route
          path="/backoffice/faixa-tarifador-cadastro"
          component={CadastroFaixaTarifador}
        />
        <Route path="/backoffice/tarifador-editar" component={EditaTarifador} />
        <Route
          path="/backoffice/evento-tarifador-editar"
          component={EdicaoEventoTarifador}
        />
        <Route
          path="/backoffice/faixa-tarifador-editar"
          component={EdicaoFaixaTarifador}
        />
        <Route
          path="/backoffice/associacao-contas-cadastro"
          component={CadastroAssociacaoContas}
        />
        <Route
          path="/backoffice/associacao-contas-editar"
          component={EditaAssociacaoContas}
        />
        <Route
          path="/backoffice/associacao-contas-lista"
          component={ListaAssociacaoContas}
        />
        <Route
          path="/backoffice/associacao-contas-consulta"
          component={ConsultaAssociacaoContas}
        />

        <Route
          path="/backoffice/associacao-contas-faixas/:id"
          component={CadastroFaixaAssociacaoConta}
        />

        <Route
          path="/backoffice/associacao-contas-faixas"
          component={CadastroFaixaAssociacaoConta}
        />

        <Route
          path="/backoffice/associacao-bancos-cadastro"
          component={CadastroAssociacaoBancos}
        />
        <Route
          path="/backoffice/associacao-bancos-editar"
          component={EditaAssociacaoBancos}
        />
        <Route
          path="/backoffice/associacao-bancos-lista"
          component={ListaAssociacaoBancos}
        />
        <Route
          path="/backoffice/associacao-bancos-consulta"
          component={ConsultaAssociacaoBancos}
        />
        <Route path="/backoffice/usuario" component={Users} />
        <Route path="/backoffice/userlist" component={Usuarios} />
        <Route path="/backoffice/vinculouser" component={VinculoUsuarios} />
        <Route path="/backoffice/vinculo-conta" component={VinculoConta} />
        <Route path="/backoffice/vinculoedit" component={VinculoEdit} />
        <Route
          path="/backoffice/vinculo-conta-edit"
          component={VinculoContaEdit}
        />
        <Route path="/backoffice/edicao-usuarios" component={EdicaoUsuarios} />
        <Route path="/backoffice/newpassword" component={RegistrarSenha} />
        <Route path="/backoffice/pdf-monitor" component={PdfMonitor} />
        <Route
          path="/backoffice/arquivos-gerados-historico"
          render={props => <ArquivosGeradosSaldos {...props} historico />}
        />

        <Route
          path="/backoffice/painel-saldos"
          exact
          component={PainelSaldos}
        />
        <Route
          path="/backoffice/painel-vencidos"
          exact
          component={PainelVencidos}
        />
        <Route
          path="/backoffice/arquivos-gerados"
          exact
          component={ArquivosGerados}
        />
        <Route
          path="/backoffice/arquivos-gerados-saldos"
          exact
          component={ArquivosGeradosSaldos}
        />

        <Route
          path="/backoffice/monitor-aprovacao"
          exact
          component={MonitorAprovacao}
        />
        <Route
          path="/backoffice/historico-teds-backoffice"
          component={HistoricoTeds}
        />

        <Route
          path="/backoffice/tarifador/monitor-geral"
          component={TarifadorMonitorGeral}
        />
        <Route
          path="/backoffice/tarifador/monitor-conta"
          component={TarifadorMonitorConta}
        />
        <Route path="/backoffice/tarifador" exact component={Tarifador} />
        <Route path="/backoffice/servicos" exact component={Servicos} />
        <Route
          path="/backoffice/servicos-editar"
          exact
          component={EdicaoServicos}
        />
        <Route
          path="/backoffice/servicos-cadastro"
          exact
          component={CadastroServicos}
        />
        <Route
          path="/backoffice/evento-servico-cadastro"
          exact
          component={CadastroServicoEvento}
        />
        <Route path="/backoffice/termos" exact component={Termos} />
        <Route
          path="/backoffice/termos-cadastro"
          exact
          component={CadastroTermos}
        />
        <Route
          path="/backoffice/termos-editar"
          exact
          component={CadastroTermos}
        />
        <Route
          path="/backoffice/monitor-termos"
          exact
          component={MonitorTermos}
        />
        <Route
          path="/backoffice/historico-saldos"
          exact
          component={HistoricoSaldos}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
