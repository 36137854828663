import React, { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { ContainerDialogAction } from '../Modals/style';

const ModalActionUnit = ({ modal, onClose, onConfirm }) => {
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={modal.open}
      >
        <DialogContent>
          <Alert
            icon={false}
            severity={modal.action === 'Reprovar' ? 'warning' : 'success'}
            style={{ width: '100%', marginBottom: '1rem' }}
          >
            Você deseja <b>{modal.action}</b> esta transação?
          </Alert>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th">Data</TableCell>
                <TableCell component="th">Titular</TableCell>
                <TableCell component="th">Beneficiário</TableCell>
                <TableCell component="th" align="right">
                  Valor
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: 300 }}>
                  {modal?.transacao?.dataCriacao}
                </TableCell>
                <TableCell style={{ fontWeight: 300 }}>
                  {modal?.transacao?.nomeTitular}
                </TableCell>
                <TableCell style={{ fontWeight: 300 }}>
                  {modal?.transacao?.nomeBeneficiario}
                </TableCell>
                <TableCell style={{ fontWeight: 300 }} align="right">
                  {modal?.transacao?.valor}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <ContainerDialogAction>
            <Button onClick={onClose} color="default" size="small">
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => onConfirm(modal)}
              color={modal.action === 'Reprovar' ? 'secondary' : 'primary'}
              autoFocus
              size="small"
            >
              {modal.action}
            </Button>
          </ContainerDialogAction>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalActionUnit;
