import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { AiFillPrinter } from 'react-icons/ai';
import Button from '../../components/Buttons/ConfirmButton';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@material-ui/core';
import { maskCpfCnpj } from '../../util/mask';

const useStyles = makeStyles(theme => ({
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}));

export default function PdfMonitor() {
  const classes = useStyles();
  const theme = useTheme();

  function printDiv() {
    var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printButton').style.display = 'inline';
  }

  const rows = JSON.parse(localStorage.getItem('pdfMonitor'));

  return (
    <React.Fragment>
      <CssBaseline />
      <Box padding={4}>
        <div id="printableArea">
          <img alt="" height="80" src={theme.config.loginLogo} />

          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              placement={'top'}
              color="primary"
              id="printButton"
              title={'Imprimir'}
              startIcon={<AiFillPrinter color="background" />}
              onClick={printDiv}
            >
              Imprimir
            </Button>
            <br />
          </div>
          <Typography
            style={{ textAlign: 'center', margin: '3rem 0' }}
            variant="h5"
            component="h5"
          >
            Monitor Backoffice
          </Typography>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th"> CNPJ/CPF</TableCell>
                <TableCell component="th"> Nome</TableCell>
                <TableCell component="th"> Tipo Controle</TableCell>
                <TableCell component="th"> Conta </TableCell>
                <TableCell component="th"> Data Solicitação </TableCell>
                <TableCell component="th"> Data Aprovação </TableCell>
                <TableCell component="th"> Data Abertura</TableCell>
                <TableCell component="th"> Situação</TableCell>
                <TableCell component="th"> Banco Padrão</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      maxWidth: '150px',
                    }}
                  >
                    {maskCpfCnpj(item.cnpjCpf) || '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.cliente.nome || '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '100px',
                    }}
                  >
                    {item.tipoControle || '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '100px',
                    }}
                  >
                    {item.conta || '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '100px',
                    }}
                  >
                    {item.solicitacao || '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '100px',
                    }}
                  >
                    {item.cliente.dataAprovacao
                      ? moment(new Date(item.cliente.dataAprovacao)).format(
                          'DD/MM/YYYY',
                        )
                      : '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '100px',
                    }}
                  >
                    {item.dataAbertura
                      ? moment(new Date(item.dataAbertura)).format('DD/MM/YYYY')
                      : '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '100px',
                    }}
                  >
                    {item.cliente.situacao || '-'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '120px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.bancoPadrao || '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Box>
    </React.Fragment>
  );
}
