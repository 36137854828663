import React, { useState, useRef, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import * as Yup from 'yup';
import { api_multipague_configuracao } from '../../services/api';
import Button from '../../components/Buttons/ConfirmButton';
import TextField from '@material-ui/core/TextField';
import InputText from '../../components/InputText';
import getValidationErros from '../../util/FormattedError';
import { Container, Main, ContainerButton, InfoContainer } from './styles.js';
import { Tooltip, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MdSave, MdArrowBack } from 'react-icons/md';
import Box from '../../components/Box';
import Wrapper from '../../components/Wrapper';
import { alpha, useTheme } from '@material-ui/core';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import SelectComponent from '../../components/Select';

export const EdicaoUsuarios = () => {
  const history = useHistory();
  const theme = useTheme();
  const editingInfo = JSON.parse(
    localStorage.getItem('edicao_usuario_backoffice'),
  );
  const formRef = useRef(null);
  const [listaPerfis, setListaPerfis] = useState();
  const [cpf, setCpf] = useState(editingInfo.login);
  const [ddd, setDdd] = useState();
  const [telefone, setTelefone] = useState();
  const [nome, setNome] = useState(editingInfo.nome);
  const [email, setEmail] = useState(editingInfo.email);
  const [grupos, setGrupos] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [grupoSelected, setGrupoSelected] = useState('');

  useEffect(() => {
    const fetchGrupo = async () => {
      try {
        const response = await api_multipague_configuracao.get(
          '/keycloak/groups',
        );
        setGrupos(
          response.data?.map(item => {
            return { label: item.name, value: item.id };
          }) || [],
        );
      } catch (error) {
        toastComponentError(
          error.response.data.mensagem
            ? `Código: ${error.response.data.codigo} - ${error.response.data.mensagem}`
            : `Não foi possível buscar grupos`,
          theme,
        );
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchGrupo();
  }, []);

  useEffect(() => {
    api_multipague_configuracao
      .get(`/usuario/${editingInfo.login.replace(/[^0-9]+/g, '')}`)
      .then(function (response) {
        console.log(response);
        setDdd(response.data.ddd);
        setTelefone(response.data.telefone);
        setListaPerfis(response.data.perfis[0]);
        setGrupoSelected(response.data.grupos[0]?.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onSubmit = async body => {
    try {
      const schmeCadastro = Yup.object().shape({
        nome: Yup.string().required('Campo obrigatorio'),
        email: Yup.string().required('Campo obrigatorio'),
        perfil: Yup.string().required('Campo obrigatorio'),
      });

      await schmeCadastro.validate(body, {
        abortEarly: false,
      });

      const bodyResquest = {
        ...body,
        atributos: {
          profileBackOffice: editingInfo.atributos.profileBackOffice,
          permissaoContas: editingInfo.atributos.permissaoContas,
          profileBackOffice: editingInfo.atributos.profileBackOffice,
        },
        ativo: editingInfo.ativo,
        email: editingInfo.email,
        id: editingInfo.id,
        login: editingInfo.login,
        nome: editingInfo.nome,
        perfil: editingInfo.perfil,
        perfis: editingInfo.perfis,
        situacao: editingInfo.situacao,
      };
      if (!!editingInfo?.id) {
        const updateBody = {
          nome: nome,
          email: email,
          login: cpf,
          ddd: ddd,
          telefone: telefone,
          keycloakGroupId: body.grupos,
        };

        await api_multipague_configuracao.put(
          `/usuario/${editingInfo.login.replace(/[^0-9]+/g, '')}`,
          updateBody,
        );
      } else {
        const { data } = await api_multipague_configuracao.post(
          `/usuario?idCliente=${editingInfo.id}`,
          bodyResquest,
        );
      }

      localStorage.removeItem('edicao_usuario_backoffice');
      history.push('/backoffice/userlist');
      toastComponentSuccess('Usuário editado com sucesso', theme);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErros(err);
        formRef.current?.setErrors(erros);
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
          theme,
        );
        localStorage.removeItem('edicao_usuario_backoffice');
        console.log(err);
      }
    }
  };

  return (
    <Wrapper
      title="Edição de Usuário"
      crumb={[
        { name: 'Home', link: '/backoffice/new-backoffice' },
        { name: 'Usuário', link: '/backoffice/userlist' },
      ]}
      crumbActive="Edição"
    >
      <Container ref={formRef} onSubmit={onSubmit}>
        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '20px',
            boxShadow: `${alpha(
              theme.palette.primary.dark,
              0.2,
            )} 0px 0px 45px -15px`,
            padding: '1.5rem',
          }}
        >
          <Main>
            <InputText
              variant="outlined"
              label="Nome Completo"
              name="nome"
              id="nome"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              value={nome}
              onChange={e => setNome(e.target.value)}
            />
            <ReactInputMask
              mask={'99'}
              maskChar=""
              name="ddd"
              id="ddd"
              value={ddd}
              onChange={e => setDdd(e.target.value)}
            >
              {() => (
                <TextField
                  style={{ width: '100%' }}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '20px',
                    },
                  }}
                  label="DDD"
                />
              )}
            </ReactInputMask>
            <ReactInputMask
              mask={'999999999'}
              maskChar=""
              value={telefone}
              onChange={e => setTelefone(e.target.value)}
            >
              {() => (
                <TextField
                  style={{ width: '100%' }}
                  size="small"
                  name="telefone"
                  id="telefone"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '20px',
                    },
                  }}
                  label="Telefone"
                />
              )}
            </ReactInputMask>
            <InputText
              variant="outlined"
              label="E-mail"
              name="email"
              id="email"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <ReactInputMask
              mask={'999.999.999-99'}
              maskChar=""
              name="cpf"
              id="cpf"
              value={cpf}
              onChange={e => setCpf(e.target.value)}
            >
              {() => (
                <TextField
                  style={{ width: '100%' }}
                  size="small"
                  name="cpf"
                  id="cpf"
                  InputProps={{
                    style: {
                      borderRadius: '20px',
                    },
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="CPF"
                />
              )}
            </ReactInputMask>
            <InputText
              variant="outlined"
              label="Perfil"
              name="perfil"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              id="text"
              value={listaPerfis}
              disabled
            />
            <SelectComponent
              id="grupos"
              name="grupos"
              label="Grupos"
              itens={grupos}
              valueDefault={grupoSelected}
              callback={val => setGrupoSelected(val)}
            />
          </Main>
          <InfoContainer></InfoContainer>
          <ContainerButton>
            <Tooltip title="Voltar">
              <IconButton
                variant="contained"
                color="primary"
                style={{
                  boxShadow: 'none',
                  textTransform: 'initial',
                  fontSize: '8px',
                  backgroundColor: alpha(
                    theme.palette.appBar[theme.palette.type].bgMenu,
                    0.7,
                  ),
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main,
                    ),
                  },
                }}
                onClick={() => history.goBack()}
              >
                <MdArrowBack
                  color={theme.palette.getContrastText(
                    alpha(theme.palette.appBar[theme.palette.type].bgMenu, 0.4),
                  )}
                />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              type="submit"
              startIcon={
                <MdSave
                  color={theme.palette.getContrastText(
                    alpha(theme.palette.appBar[theme.palette.type].bgMenu, 0.4),
                  )}
                />
              }
              color="primary"
              style={{
                boxShadow: 'none',
                textTransform: 'initial',
                fontSize: '8px',
                backgroundColor: alpha(
                  theme.palette.appBar[theme.palette.type].bgMenu,
                  0.7,
                ),
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.getContrastText(
                    theme.palette.primary.main,
                  ),
                },
              }}
            >
              Salvar
            </Button>
          </ContainerButton>
        </Box>
      </Container>
    </Wrapper>
  );
};
