import React, { forwardRef, useImperativeHandle } from "react";

import { Autocomplete } from "@material-ui/lab";
import { TextField, debounce } from "@material-ui/core";
import { api_multipague_conta } from "../../services/api";


const AutocompleteClienteConta = ({onChange},ref)=>{
    const [inputValue,setInputValue] = React.useState("");
    const [options ,setOptions] = React.useState([])
    const [carregandoPessoa,setCarregandoPessoa]= React.useState(false)

    const searchDelayed = debounce((newValue) => {
        if(!!newValue && newValue?.length >= 3){
            setCarregandoPessoa(true)
            const params = {};
            const checkIsConta = newValue.replace(/\D/g, "");
            const nome =  newValue.replace(/\d/g, "").trim();
            if(checkIsConta.length >= 3){
                params["conta"] = checkIsConta;
            }else{
                params["nome"] = nome;
            }
            api_multipague_conta.get("/conta/filtro",{params}).then(({data})=>{
                const newOptions = data.map((item)=>{
                    return {
                        value:item?.id,
                        label: `${item?.conta} - ${item?.banco?.nome} - ${item?.cliente?.nome}`,
                    }
                })
                setOptions(newOptions)
                setCarregandoPessoa(false)
            })
        }else{
            setCarregandoPessoa(false)
            setOptions([])
        }
    }, 300);

    const reset = ()=>{
        setOptions([]);
        setInputValue("")
    }

    useImperativeHandle(ref, () => {
        return {
          reset
        };
    });



    return <Autocomplete
        id="busca-pessoa-server"
        options={options}
        freeSolo
        value={inputValue}
        onChange={(_event, newValue) => {
            setInputValue(newValue);
            onChange(newValue)
        }}
        onInputChange={(_event, newInputValue) => {
            searchDelayed(newInputValue);
        }}
        getOptionLabel={(option) => option.label}
        loading={carregandoPessoa}
        renderInput={(params) => (
            <TextField
                {...params}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Buscar Cliente Conta"
                size="small"
                variant="outlined"
            />
        )}
    />
}

export default forwardRef(AutocompleteClienteConta)
