import styled from 'styled-components';
import { Form } from '@unform/web';
export const Container = styled.div`
  display: grid;
  row-gap: 40px;
`;
export const Header = styled(Form)`
  display: grid;
  row-gap: 20px;
`;
export const Filter = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;
export const Table = styled.div``;
export const BodyTable = styled.div`
  width: 100%;
  height: 500px;
  @media only screen and (min-width: 1024px) {
    width: 100%;
    height: 500px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const Checks = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10%;
  align-items: center;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ButtonTable = styled.button.attrs(props => ({
  disabled: false,
}))`
  border: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
