import styled from 'styled-components';
import { Form } from '@unform/web';
import { styled as MuyStyled, Paper } from '@material-ui/core';

export const StyledPaper = styled(Paper)`
  border-radius: 20px;
`;

export const ContainerPopUp = styled(Form)`
  position: absolute;
  z-index: 99999;
  min-height: 100vh;
  min-width: 100vw;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 120%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  flex-direction: column;
  overflow: hidden;
  transition: 0.5s;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    position: absolute;
    z-index: 99999;
    min-height: 100vh;
    min-width: 100vw;
    width: 100%;
    height: 120%;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
  @media only screen and (min-width: 1440px) {
    position: absolute;
    z-index: 99999;
    min-height: 100vh;
    min-width: 100vw;
    width: 100%;
    height: 120%;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 30px 0px 0px;

  @media only screen and (min-width: 1024px) {
    padding: 15px;
    svg {
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const InfoPopUp = MuyStyled('div')(({ theme }) => {
  return {
    width: '100%',
    borderRadius: '20px',
    transition: '.5s',
    minHeight: '30%',
    backgroundColor: theme.palette.background.paper,
  };
});
