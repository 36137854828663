import styled from 'styled-components';
import { Form } from '@unform/web';
export const Container = styled(Form)`
  display: grid;
  row-gap: 40px;
`;
export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
  }
`;
export const Main = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto auto;
`;
export const ContainerDisabled = styled.div`
  display: grid;
  row-gap: 20px;
  width: min-content;
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;
export const InfoContainer = styled.div`
  display: grid;
  row-gap: 10px;

  div {
    span {
      font-weight: bold;
    }
  }
`;
