import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { api_multipague_configuracao } from '../../../services/api';
import DataGridWrapper from '../../../components/DataGrid/DataGridWrapper';
import { toastComponentError } from '../../../components/Toast';
import { Button, useTheme } from '@material-ui/core';

export default function DataGridDemo() {
  const history = useHistory();

  const columns = [
    { field: 'id', headerName: 'Código', flex: 1, minWidth: 100 },
    { field: 'descricao', headerName: 'Descrição', flex: 3, minWidth: 200 },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Editar',
      type: 'text',
      flex: 1,
      renderCell: event => {
        return (
          <>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                handleEditar(event.row);
              }}
            >
              <EditIcon color={useTheme().palette.primary.main} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [loadButton, setLoadButton] = useState(true);

  function novoRegistro() {
    history.push('/backoffice/eventos-cadastro');
  }
  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/evento?codigo=&descricao=`;

  useEffect(() => {
    setLoadButton(true);
    api_multipague_configuracao
      .get(URL, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;
        console.log(monitorFilter);

        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            descricao: data.descricao,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }, []);

  const handleEditar = value => {
    localStorage.setItem('codigo', value.id);
    localStorage.setItem('descricao', value.descricao);
    localStorage.setItem('identificadorEvento', value.identificadorEvento);
    localStorage.setItem('esteira', value.utilizaEsteira);
    localStorage.setItem('senha', value.utilizaSenha);
    localStorage.setItem('ativo', value.ativo);
    history.push('/backoffice/eventos-editar');
  };

  return (
    <DataGridWrapper
      minHeight="600px"
      rows={rows}
      title="Eventos"
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        { name: 'Configurações' },
      ]}
      newRegister={novoRegistro}
      columns={columns}
      loading={loadButton}
      pageSize={10}
    />
  );
}
