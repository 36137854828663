import React from 'react';

import { Button, Chip } from '@material-ui/core';
import UseMonitor from '../../useMonitor';
export const BtnLote = () => {
  const { selectionModel, openModalActionLote } = UseMonitor();

  return (
    <>
      <Chip
        size="small"
        color="secondary"
        variant="outlined"
        width={200}
        style={{ padding: '15px 10px' }}
        label="Reprovação em Lote"
        disabled={selectionModel.length === 0}
        onClick={() => openModalActionLote('Reprovar')}
      ></Chip>
      <Chip
        size="small"
        color="primary"
        variant="outlined"
        width={200}
        style={{ padding: '15px 10px' }}
        label="Aprovação em Lote"
        disabled={selectionModel.length === 0}
        onClick={() => openModalActionLote('Aprovar')}
      >
        Aprovação em Lote
      </Chip>
    </>
  );
};
