import styled from 'styled-components';
import { styled as MuiStyled, Box } from '@material-ui/core';

export const Container = styled.div`
  display: grid;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
`;
export const ContainerInput = styled.div``;
export const ContainerPassword = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Options = MuiStyled(Box)(({ theme }) => {
  return {
    width: '60px',
    height: '30px',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    marginRight: '5px',
    marginBottom: '5px',
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  };
});
