import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { api_multipague_conta } from '../../services/api';
import InputText from '../../components/InputText';
import { maskCpfCnpj } from '../../util/mask';
import PersonIcon from '@material-ui/icons/Person';
import { Header, ButtonTable } from './styles';
import { useHistory } from 'react-router-dom';

import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import { Form } from '@unform/web';
import { toastComponentError } from '../../components/Toast';
import RenderActionsCell from '../../components/DataGrid/DataGridWrapper/Actions';

export const VinculoConta = () => {
  const history = useHistory();
  const theme = useTheme();
  const formRef = useRef(null);
  const [openMenuActions, setOpenMenuActions] = useState();
  const [rowId, setRowId] = useState(null);

  function handleEditUser(value) {
    api_multipague_conta
      .get(`/conta/${value.id}`)
      .then(function (response) {
        localStorage.setItem(
          'edicao_usuario_vinculo',
          JSON.stringify(
            Object.assign(response.data, { tipoControle: value.tipoControle }),
          ),
        );
        history.push('/backoffice/vinculo-conta-edit');
      })
      .catch(() => {
        toastComponentError('Não foi possível finalizar a requisição', theme);
      });
  }

  const columnsTable = [
    { field: 'conta', headerName: 'Conta', flex: 1, minWidth: 200 },
    {
      field: 'cnpjCpf',
      headerName: 'CNPJ/CPF',
      type: 'string',
      minWidth: 150,
    },
    {
      field: 'banco',
      headerName: 'Dados Bancários',
      type: 'string',
      flex: 1,
      minWidth: 230,
    },
    {
      field: 'controle',
      headerName: 'Controle',
      type: 'string',
      minWidth: 150,
    },
    {
      field: 'snedemail',
      headerName: 'Ações',
      sortable: false,
      minWidth: 110,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: '50%',
                  margin: 'auto',
                }}
              >
                <Tooltip
                  title="Vincular Usuários"
                  aria-label="vincular-usuarios"
                >
                  <ButtonTable
                    style={{ color: theme.palette.primary.main }}
                    onClick={() => {
                      handleEditUser(event.row);
                    }}
                  >
                    <PersonIcon size={24} color={theme.palette.primary.main} />
                  </ButtonTable>
                </Tooltip>
              </div>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const [rowsTable, setRowsTable] = useState([]);

  const [loadingTable, setLoadingTable] = useState(false);

  async function listarUsuarios() {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_conta.get(
        '/conta/filtro?situacao=ATIVADA',
      );

      const formatted = data.map(usr => ({
        ...usr,
        conta: usr.cliente.nome,
        cnpjCpf: maskCpfCnpj(usr.cliente.cnpjCpf),
        controle: usr.tipoControle == null ? '' : usr.tipoControle,
        banco: `${usr.banco.nome} ${usr.agencia} ${usr.conta} - ${usr.digitoConta}`,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
    } finally {
      setLoadingTable(false);
    }
  }

  async function filterUser(body) {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_conta.get(
        `conta/filtro?situacao=ATIVADA&nome=${
          body.conta
        }&cnpjCpf=${body.cnpjcpf.replace(/[^\d]/g, '')}`,
      );

      const formatted = data.map(usr => ({
        ...usr,
        conta: usr.cliente.nome,
        cnpjCpf: maskCpfCnpj(usr.cliente.cnpjCpf),
        controle: usr.tipoControle == null ? '' : usr.tipoControle,
        banco: `${usr.banco.nome} ${usr.agencia} ${usr.conta} - ${usr.digitoConta}`,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
    } finally {
      setLoadingTable(false);
    }
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <InputText id="conta" name="conta" label="Conta" variant="outlined" />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
      {
        html: () => (
          <InputText
            id="cnpjcpf"
            name="cnpjcpf"
            label="CNPJ/CPF"
            variant="outlined"
          />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
    ],
    searchButton: {
      searchButton: () => filterUser(formRef?.current?.getData()),
    },
    cleanButton: {
      cleanButton: () => {
        formRef?.current?.setData({ conta: '', cnpjcpf: '' });
        filterUser(formRef?.current?.getData());
      },
    },
  };

  useEffect(() => {
    listarUsuarios();
  }, []);

  return (
    <>
      <Form ref={formRef} onSubmit={filterUser}>
        <DataGridWrapper
          crumb={[
            { link: '/backoffice/new-backoffice', name: 'Home' },
            { name: 'Vínculos' },
          ]}
          minHeight="600px"
          rows={rowsTable}
          columns={columnsTable}
          title="Vínculo por Conta"
          onRowClick={params => setRowId(params.id)}
          pageSize={10}
          loading={loadingTable}
          formData={filterInputs}
        />
      </Form>
    </>
  );
};
