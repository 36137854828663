import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  loadingButton: {
    boxShadow: 'none',
    textTransform: 'initial',
    width: 20,
    height: 20,
    padding: 0,
    backgroundColor: alpha(
      theme.palette.appBar[theme.palette.type].bgMenu,
      0.7,
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '& svg': {
      fontSize: 18,
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));
