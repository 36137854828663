import styled from 'styled-components';
import { Form } from '@unform/web';
import { styled as MuyStyled, makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const Container = styled(Form)``;
export const Subcontainer = styled.div`
  display: grid;
  row-gap: 60px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Filtros = styled.div`
  display: grid;
  row-gap: 20px;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const Evento = styled.div``;

// =========== Datas ===========

export const ContainerDates = styled.div`
  display: flex;
  gap: 1rem;
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const DataFileds = styled.div`
  display: flex;
  gap: 1rem;
`
export const Label = styled.div`
  margin-bottom: 1rem;
`;
export const Dates = styled.div`
  flex:1;
`;


// =========== Datas ===========

export const InfoEmpresa = styled.div`
  display: flex;
  gap: 20px;

  @media only screen and (min-width: 1024px) {
    display: flex;
    gap: 20px;
  }

  @media only screen and (min-width: 1440px) {
  }
`;

export const Checks = styled.div`
  width:400px;
  display: flex;
  justify-content: space-between;
`;

export const ContainerTable = styled.div``;
export const BodyTable = styled.div`
  width: auto;
  height: 500px;
  @media only screen and (min-width: 1024px) {
    width: 100%;
    height: 500px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ButtonTable = styled.button.attrs(({disabled}) => ({
  disabled: false
})) `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap:1rem;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const AdicionarParecer = styled(Form)`
  width: 100%;
  height: 100%;
  padding: 5px 20px;
  display: grid;
  row-gap: 20px;

  @media only screen and (min-width: 1024px) {
    min-width: 500px;
    height: auto;
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const InputParecer = styled.div`
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ActionsParecer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const ParecerList = MuyStyled('div')(({ theme }) => {
  return {
    maxHeight: '150px',
    overflowY: 'auto',

    '& div:nth-child(even)': {
      backgroundColor: theme.palette.action.focus,
    },

    '&::-webkit-scrollbar': {
      width: '8px',
      maxHeight: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '3px',
    },

    display: 'grid',
    rowGap: '10px',
    gridTemplateRows: 'min-content',
  };
});

export const ListagemParecer = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
`;

export const useStyles = makeStyles(theme => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonsCleanSearch: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));
