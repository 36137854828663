import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    borderRadius: '15px',
  },
}));

export const AlertPopUp = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [motivo, setMotivo] = useState('');

  useEffect(() => {
    setOpen(props.data.show);
  }, [props.data.show]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    if (!!motivo) {
      if (props.data.onPress) {
        props.data.onPress(motivo);
      }
      setOpen(false);
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    if (props.data.onCancel) {
      props.data.onCancel();
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialogContainer }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.data.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.data.text}</DialogContentText>
        <TextField
          margin="dense"
          id="motivo"
          label="Motivo"
          type="text"
          fullWidth
          required
          error={error}
          onChange={event => setMotivo(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {props.data.buttonPressName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
