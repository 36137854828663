import styled, { keyframes } from 'styled-components';
import { Form } from '@unform/web';
export const Container = styled(Form)`
  display: grid;
  row-gap: 10px;
`;
export const ContainerTable = styled.div``;
export const BodyTable = styled.div`
  width: auto;
  height: 500px;
  @media only screen and (min-width: 1024px) {
    width: 100%;
    height: 500px;
  }
  @media only screen and (min-width: 1440px) {
  }
`;
export const ContainerFiltros = styled.div`
  display: grid;
  gap: 20px;
`;
export const OptionsFilter = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1440px) {
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

  }
`;
export const ContainerButtonFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    justify-content: space-between;
  }
  @media only screen and (min-width: 1440px) {
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    animation: ${rotate} 2s linear infinite;
  }
`;
