import { Cookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';

const cookies = new Cookies();
export const permissionUser = cookies.get('roles');

export const usuarioMaster = () => {
  return permissionUser?.indexOf('usuario_master') >= 0;
};
export const backofficeEmpresa = () => {
  return permissionUser?.indexOf('backoffice-empresa') >= 0;
};
export const backofficeMultiplica = () => {
  return permissionUser?.indexOf('backoffice-multiplica') >= 0;
};

export const logoutBackoffice = () => {
  const keycloak = process.env;
  localStorage.clear();
  sessionStorage.clear();

  window.location.href = `${keycloak.MSKEYCLOAK['auth-server-url']}realms/${
    keycloak.MSKEYCLOAK['realm']
  }/protocol/openid-connect/logout?client_id=${
    keycloak.MSKEYCLOAK['resource']
  }&post_logout_redirect_uri=${
    window.location.origin + '/'
  }login?contexto=backoffice`;
};

//Aplicando regra para pegar do token e não do localStorage
const token = localStorage.getItem('tkn-access');
export const getAuth = () => {
  if (token) {
    try {
      const auth = jwt_decode(token);
      return auth;
    } catch (error) {
      logoutBackoffice();
    }
  } else {
    logoutBackoffice();
  }
  return;
};

export const perfilBackOffice = () => {
  const { profileBackOffice } = getAuth();
  return Boolean(profileBackOffice);
};

export const perfilMaster = () => {
  const { master } = getAuth();
  return Boolean(master);
};

export const administradorEscrow = () => {
  const { administradorEscrow } = getAuth();
  return Boolean(administradorEscrow);
};
