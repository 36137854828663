import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  Modal,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import DateComponent from '../../../../components/InputDate';
import moment from 'moment';
import formattedMoney from '../../../../util/FormatteMoney';
import { api_multipague_conta } from '../../../../services/api';
import PopUpPassword from '../../../../components/PopUpPassword';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  paper: classContainer => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: 0,
    ...classContainer,
  }),
  content: {
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.2rem',
    textAlign: 'center',
    gap: '5px',
    paddingBottom: '0px',
  },
  boxActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '10px',
    padding: '1rem',
    gap: '1rem',
    '& button': {
      borderRadius: '30px',
      flex: 1,
      fontSize: '13px !important',
      textTransform: 'capitalize',
    },
  },
  card: {
    borderRadius: '5px',
    backgroundColor: '#efefef',
    padding: '10px',
    display: 'flex',
    marginBottom: '10px',
    '& span.grey': {
      color: '#665',
    },
    '& b': {
      fontWeight: 'bold',
    },
  },

  tarifas: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
    '& b': {
      fontWeight: 'bold',
    },
  },
}));

function ModalTed({
  rows,
  onClose,
  onConfirm,
  closePassword,
  setOpenModalPassword,
}) {
  const classes = useStyles();
  const [dataTed, setDataTed] = React.useState('');
  const [saldo, setSaldo] = React.useState(0);
  const [loadingSaldo, setLoadingSaldo] = React.useState(true);
  const [bloqueiaTed, setBloqueiaTed] = React.useState(false);

  const tarifas = React.useMemo(() => {
    const valores = {
      banco: 0,
      multiplica: 0,
      totalTarifa: 0,
    };

    if (!rows) {
      return valores;
    }

    rows.forEach(element => {
      valores.banco += element?.valorTarifaBanco || 0;
      valores.multiplica += element?.valorTarifaMultiplica || 0;
    });
    valores.totalTarifa = valores.banco + valores.multiplica;
    return valores;
  }, [rows]);

  const getSaldo = row => {
    setLoadingSaldo(true);
    api_multipague_conta
      .get('/saldo/conta', {
        params: {
          nrBanco: row?.banco,
          agencia: row?.agencia,
          conta: row?.conta,
          digitoConta: row?.digitoConta,
        },
      })
      .then(({ data }) => {
        setLoadingSaldo(false);
        const dataSaldo = data?.saldo || 0;
        setSaldo(dataSaldo);
        if (dataSaldo === 0) {
          setBloqueiaTed(true);
        } else {
          setBloqueiaTed(false);
        }
      });
  };

  const handleClose = () => {
    setDataTed('');
    setSaldo(0);
    onClose();
  };

  const isLote = React.useMemo(() => {
    return rows?.length > 1;
  }, [rows]);

  React.useEffect(() => {
    if (rows?.length === 1) {
      getSaldo(rows[0]);
    }
  }, [rows]);

  return (
    <>
      <PopUpPassword
        show={closePassword}
        closePopUp={() => setOpenModalPassword(false)}
        callback={data => {
          onConfirm(rows, dataTed, data);
        }}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!!rows}
        className={classes.modal}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!rows}>
          <div className={classes.paper}>
            <div className={`${classes.content}`}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Realizar TED
              </Typography>
              <Box width="350px">
                {!!rows && rows.length === 1 && (
                  <Box
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    className={classes.card}
                  >
                    <Box textAlign={'left'}>
                      <Typography variant="caption">
                        <span className="grey">Conta:</span>
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        <b>{`${rows[0]?.agencia} ${rows[0]?.conta}-${rows[0]?.digitoConta}`}</b>
                      </Typography>
                    </Box>
                    <Box textAlign={'right'}>
                      <Typography variant="caption">
                        <span className="grey">Saldo:</span>
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        {loadingSaldo ? (
                          <CircularProgress
                            color="secondary"
                            width={'15px'}
                            style={{ width: '15px', height: '15px' }}
                            size={'small'}
                          />
                        ) : (
                          <b>{formattedMoney(parseFloat(saldo))}</b>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  className={classes.card}
                  display={'flex'}
                  flexDirection={'column'}
                  marginBottom={'20px !important'}
                >
                  <Box style={{ textAlign: 'center', width: '100%' }}>
                    <Typography variant="caption">
                      {isLote
                        ? 'Resumo das Transferências'
                        : 'Resumo da Transferência'}
                    </Typography>
                  </Box>
                  <Box
                    width={'100%'}
                    borderTop={'solid 1px #f5f5f5'}
                    marginTop={'10px'}
                    paddingTop={'10px'}
                  >
                    {rows?.length > 1 && (
                      <Box className={classes.tarifas}>
                        <Typography variant="caption">
                          Quantidade de Transferências:
                        </Typography>
                        <Typography variant="caption">
                          <b>{rows?.length}</b>
                        </Typography>
                      </Box>
                    )}
                    <Box className={classes.tarifas}>
                      <Typography variant="caption">
                        Valor Tarifa Banco:
                      </Typography>
                      <Typography variant="caption">
                        <b>{formattedMoney(parseFloat(tarifas?.banco || 0))}</b>
                      </Typography>
                    </Box>
                    <Box className={classes.tarifas}>
                      <Typography variant="caption">
                        Valor Tarifa Multiplica:
                      </Typography>
                      <Typography variant="caption">
                        <b>
                          {formattedMoney(parseFloat(tarifas?.multiplica || 0))}
                        </b>
                      </Typography>
                    </Box>
                    <Box className={classes.tarifas}>
                      <Typography variant="caption">
                        Total de Tarifas:
                      </Typography>
                      <Typography variant="caption">
                        <b>
                          {formattedMoney(
                            parseFloat(tarifas?.totalTarifa || 0),
                          )}
                        </b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>

            <Box
              style={{
                borderTop: 'solid 1px #e1e1e1',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Box padding={'1rem 1rem 0px 1rem'}>
                <DateComponent
                  fullwidth
                  callback={value => {
                    setDataTed(value);
                    if (bloqueiaTed && value) {
                      setBloqueiaTed(false);
                    }
                  }}
                  label={'Agendar TED'}
                  size={'small'}
                  id="data-periodo-inicio-filtro"
                  type={'date'}
                  inputProps={{
                    min: moment().format('YYYY-MM-DD'),
                  }}
                />
              </Box>
              <div className={`${classes.boxActions}`}>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>

                <Button
                  size="small"
                  color={'primary'}
                  variant="outlined"
                  disabled={bloqueiaTed}
                  onClick={() => {
                    setOpenModalPassword(true);
                  }}
                >
                  {!dataTed ? 'Enviar' : 'Agendar'}
                </Button>
              </div>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default ModalTed;
