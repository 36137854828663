import React, { useEffect, useRef } from 'react';
import { FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa';
import ReactExport from 'react-export-excel';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  alpha,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import { useState } from 'react';
import { MdCloudDownload } from 'react-icons/md';
import { useStyles } from './styles';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExcelGrid = ({
  handlePDFCSV = {
    downloadPDF: () => {},
    downloadExcel: () => {},
    rowsExcel: [],
    ExcelColumn: [],
    carregaExcel: false,
    carregaIconExcel: false,
    title,
  },
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const modalRef = useRef();

  const [showListExport, setShowListExport] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowListExport(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <Grid
      item
      spacing={3}
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
      }}
    >
      <Grid spacing={3} style={{ position: 'relative' }}>
        <Tooltip title="Exportar" placement="top">
          <IconButton
            variant="contained"
            className={classes.hoverIconButton}
            color="primary"
            onClick={() => setShowListExport(!showListExport)}
          >
            <MdCloudDownload
              color={theme.palette.getContrastText(
                alpha(theme.palette.appBar[theme.palette.type].bgMenu, 0.4),
              )}
            />
          </IconButton>
        </Tooltip>
        {showListExport ? (
          <div ref={modalRef}>
            <List
              style={{
                border: `1px solid ${alpha(theme.palette.primary.dark, 0.2)}`,
                boxShadow: `0px 8px 45px -15px ${alpha(
                  theme.palette.primary.dark,
                  0.2,
                )}`,
                borderRadius: '10px',
                backgroundColor: theme.palette.background.paper,
                position: 'absolute',
                zIndex: theme.zIndex.drawer + 8,
                top: '110%',
                flexDirection: 'row',
                right: 0,
                display: 'flex',
              }}
            >
              <ListItem
                alignItems="flex-end"
                onClick={() => handlePDFCSV.downloadPDF()}
              >
                <IconButton style={{ borderRadius: '2px' }}>PDF</IconButton>
              </ListItem>
              <ListItem>
                {!handlePDFCSV.carregaExcel && (
                  <IconButton
                    onClick={() => handlePDFCSV.downloadExcel()}
                    style={{
                      borderRadius: '2px',
                      width: '160px',
                    }}
                  >
                    CARREGAR EXCEL
                  </IconButton>
                )}
                {handlePDFCSV.carregaExcel && (
                  <ExcelFile
                    element={
                      <IconButton style={{ borderRadius: '2px' }}>
                        {!handlePDFCSV.carregaIconExcel ? (
                          'EXCEL'
                        ) : (
                          <CircularProgress
                            style={{ margin: '0 1.2rem 0' }}
                            size={20}
                          />
                        )}
                      </IconButton>
                    }
                  >
                    <ExcelSheet
                      data={handlePDFCSV?.rowsExcel}
                      name={handlePDFCSV.title}
                    >
                      {handlePDFCSV.ExcelColumn.map(item => (
                        <ExcelColumn label={item.label} value={item.value} />
                      ))}
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </ListItem>
            </List>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};
