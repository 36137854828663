import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  hoverIconButton: {
    boxShadow: 'none',
    textTransform: 'initial',
    fontSize: '8px',
    backgroundColor: alpha(
      theme.palette.appBar[theme.palette.type].bgMenu,
      0.7,
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
}));
