import React, { useState, useEffect } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ContainerPopUp, InfoPopUp, Close } from './styles';
import Keyboard from './Keyboard';
import { useTheme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const PopUpPassword = ({ show = false, closePopUp, callback }) => {
  const theme = useTheme();
  const [showInfo, setShowInfo] = useState(false);
  const [showPopUp, setShowPopUp] = useState(show);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowInfo(true);
      }, 0.5);
    }

    setShowPopUp(show);
  }, [show]);

  const onConfirm = data => {
    callback(data);
  };

  return (
    <Dialog
      open={showPopUp}
      PaperProps={{
        style: {
          borderRadius: '8px',
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={closePopUp}
    >
      <DialogContent>
        <InfoPopUp>
          <Close>
            <button type="button" onClick={closePopUp}>
              <AiFillCloseCircle
                size={18}
                color={theme.palette.secondary.main}
              />
            </button>
          </Close>
          <Keyboard callback={onConfirm} />
        </InfoPopUp>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpPassword;
