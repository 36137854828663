import React, { useState } from 'react';

import LockIcon from '@material-ui/icons/Lock';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

export const InputPassword = ({ onChange, value, label, labelWidth = 100 }) => {
  const [visibel, setVisibel] = useState(false);
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={visibel ? 'text' : 'password'}
        value={value}
        onChange={event => onChange(event.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setVisibel(!visibel);
              }}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {visibel ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelWidth}
      />
    </FormControl>
  );
};
