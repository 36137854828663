import styled from 'styled-components';

export const Container = styled.div`
  button span {
    text-transform: none;
  }
  /* .MuiDataGrid-root
    .MuiDataGrid-columnHeaderCheckbox
    .MuiDataGrid-columnHeaderTitleContainer {
    display: none;
  } */
  .filtro {
    padding: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .itemRow {
    line-height: 1rem;
  }
  .itemRow span {
    display: block;
  }
`;
