import { InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';

import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

export const TextEditor = ({
  name,
  label,
  value: propValue,
  modulesProps,
  onChange,
  readOnly = false,
}) => {
  const [value, setValue] = useState(propValue || '');

  useEffect(() => {
    if (propValue !== value) {
      setValue(propValue);
    }
  }, [propValue]);

  const handleChange = content => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  return (
    <>
      <InputLabel
        style={{ fontSize: '13px', marginBottom: '1%' }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <ReactQuill
        value={value}
        onChange={handleChange}
        modules={modulesProps ? modulesProps : modules}
        formats={formats}
        readOnly={readOnly}
      />
    </>
  );
};
