import { cpf, cnpj } from 'cpf-cnpj-validator';

const validade = (value) => {
  if (value.length < 18) {
    return cpf.isValid(value)
  } else {
     return cnpj.isValid(value)
  }

}

export default validade;
