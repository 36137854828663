import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import DataGridWrapper from './../../../components/DataGrid/DataGridWrapper';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  toastComponentError,
  toastComponentSuccess,
} from './../../../components/Toast';
import {
  api_multipague_configuracao,
  api_multipague_tarifador,
  api_multipague_transacao,
} from '../../../services/api';
import DateComponent from '../../../components/InputDate';
import moment from 'moment';
import Loading from '../../../components/Loading';
import { downloadCSV } from '../../../utils';
import { useStyles } from './style';
import ModalTedUnica from './ModalTed';
import formattedMoney from '../../../util/FormatteMoney';
import { IndeterminateCheckBox } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import AutocompleteClienteConta from '../../../components/AutocompleteClienteConta';

export default function TarifadorMonitorGeral() {
  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);

  const theme = useTheme();

  const [rows, setRows] = useState([]);
  const [rowsCached, setRowsCached] = useState([]);
  const [loadButton, setLoadButton] = useState(true);
  const [loadPage, setLoadPage] = useState(false);

  const ultimoPeriodo = moment().subtract(1, 'months').format('YYYY-MM');

  const paramsInicial = {
    periodo: ultimoPeriodo,
    idClienteConta: '',
    idSegmentoTarifador: '',
    identificadorEvento: '',
    situacaoTed: '',
  };
  const [params, setParams] = React.useState(paramsInicial);
  const [paginate, setPaginate] = React.useState({
    page: 0,
    size: 10,
    count: 0,
  });

  const [openModalTed, setOpenModalTed] = React.useState(null);

  const [selectionModel, setSelectionModel] = useState([]);
  const [optionsIdentificadorEvento, setOptionsIdentificadorEvento] = useState(
    [],
  );
  const [optionsSegmentos, setOptionsSegmentos] = useState([]);
  const [optionsStatusTed, setOptionsStatusTed] = useState([]);

  const [checkedAll, setCheckedAll] = useState(false);
  const [openModalPassword, setOpenModalPassword] = React.useState(false);

  const refAutocomplete = React.useRef();

  const podeRealizarTed = statusTed => {
    const arrStatus = ['REJEITADO', 'CANCELADO', 'ERRO'];
    if (Boolean(statusTed) === false) {
      return true;
    }
    if (arrStatus.includes(statusTed)) {
      return true;
    }
    return false;
  };

  const checkAllIndeterminate = () => {
    if (!!checkedAll) {
      return false;
    }
    return (
      selectionModel?.length > 0 && selectionModel?.length < rowsCached?.length
    );
  };

  const columns = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return (
          <Checkbox
            checked={checkedAll}
            onChange={(_e, value) => {
              if (!!value) {
                setSelectionModel(rowsCached.map(item => item.id));
                setCheckedAll(true);
              } else {
                setCheckedAll(false);
                setSelectionModel([]);
              }
            }}
            indeterminate={checkAllIndeterminate()}
            indeterminateIcon={<IndeterminateCheckBox color="primary" />}
            color="primary"
          />
        );
      },
      renderCell: event => {
        const disabled = !podeRealizarTed(event.row?.statusTed);
        return (
          <Checkbox
            checked={!!selectionModel.includes(event.id)}
            onChange={(_e, checked) => {
              if (!checked) {
                setCheckedAll(false);
                setSelectionModel(old => old.filter(id => id !== event.id));
              } else {
                if (rowsCached.length === 1) {
                  setCheckedAll(true);
                }
              }
            }}
            disabled={disabled}
            color="primary"
          />
        );
      },
    },
    {
      field: 'id',
      headerName: 'Código',
      minWidth: 30,
    },
    {
      field: 'nomeCliente',
      headerName: 'Nome Cliente',
      minWidth: 250,
      renderCell: event => {
        return (
          <div
            style={{
              lineHeight: '150%',
              height: '50px',
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              whiteSpace: 'break-spaces',
            }}
          >
            {event?.row?.nomeCliente}
          </div>
        );
      },
    },
    {
      field: 'conta',
      headerName: 'Agência - Conta',
      width: 140,
      renderCell: value => {
        return (
          <p>
            {`${value.row?.agencia} ${value.row?.conta}-${value.row?.digitoConta}`}{' '}
          </p>
        );
      },
    },
    {
      field: 'valorTarifaBanco',
      headerName: 'Valor Tarifa Banco',
      minWidth: 140,
      align: 'center',
      textAlign: 'center',
      renderCell: value => {
        return (
          <p>{formattedMoney(parseFloat(value.row.valorTarifaBanco || 0))} </p>
        );
      },
    },
    {
      field: 'valorTarifaMultiplica',
      headerName: 'Valor Tarifa Multiplica',
      minWidth: 170,
      align: 'center',
      renderCell: value => {
        return (
          <p>
            {formattedMoney(parseFloat(value.row.valorTarifaMultiplica || 0))}{' '}
          </p>
        );
      },
    },
    {
      field: 'totalTarifa',
      headerName: 'Total Tarifa',
      minWidth: 120,
      align: 'center',
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.totalTarifa || 0))} </p>;
      },
    },
    {
      field: 'periodo',
      headerName: 'Período Apuração',
      minWidth: 150,
      align: 'center',
      renderCell: event => {
        let periodo = moment(event.row?.dataInicioApuracao).format(
          'MMM / YYYY',
        );
        return periodo;
      },
    },
    {
      field: 'statusTed',
      headerName: 'Status TED',
      minWidth: 150,
      align: 'center',
      renderHeader: () => {
        return (
          <Box m={'auto'}>
            <Typography
              style={{ fontSize: '13px', fontWeight: 600 }}
              variant="body2"
            >
              Status TED
            </Typography>
          </Box>
        );
      },
      renderCell: event => {
        if (!!event.row?.statusTed) {
          return (
            <Tooltip title={event.row?.mensagem}>
              <Chip label={event.row?.statusTed} />
            </Tooltip>
          );
        } else {
          return <Chip label={'TED não realizada'} />;
        }
      },
    },

    {
      field: 'acao',
      minWidth: 170,
      headerName: 'Ação',
      type: 'text',
      renderHeader: () => {
        return (
          <Box m={'auto'}>
            <Typography
              style={{ fontSize: '13px', fontWeight: 600 }}
              variant="body2"
            >
              Ação
            </Typography>
          </Box>
        );
      },
      flex: 1,
      renderCell: event => {
        let disabled = !podeRealizarTed(event.row?.statusTed);
        return (
          <Box marginX={'auto'} width={'140px'} padding={'5px'}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              style={{ borderRadius: '20px' }}
              color="primary"
              disabled={disabled}
              onClick={e => {
                e.stopPropagation();
                setSelectionModel([]);
                setOpenModalTed([event.row]);
              }}
            >
              Realizar Ted
            </Button>
          </Box>
        );
      },
    },
  ];

  const carregarMonitor = (page = 0, size = 10) => {
    const URL = `/apuracao-tarifa`;

    const [ano, mes] = params.periodo.split('-');
    const body = {
      mes: mes.padStart(2, '0'),
      ano,
      page,
      size,
      ...params,
    };

    setLoadButton(true);
    api_multipague_tarifador
      .get(URL, { headers: { Authorization: AuthStr }, params: body })
      .then(response => {
        setLoadButton(false);
        const { content, totalElements } = response.data;
        const monitorFilter = content;
        if (monitorFilter.length) {
          setRows(monitorFilter);
          setRowsCached(old => {
            const filterRows = monitorFilter.filter(item => {
              return (
                !old.map(item => item.id).includes(item.id) &&
                podeRealizarTed(item?.statusTed)
              );
            });
            const dataConcat = old.concat(filterRows);
            return dataConcat;
          });
          setPaginate(old => ({ ...old, count: totalElements }));
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const onSubmit = (rows, dataTed, password) => {
    setLoadPage(true);
    const URL =
      rows.length > 1
        ? `/apuracao-tarifa/reprocessar?` +
          rows.map(item => `idTarifas=${item.id}`).join('&')
        : `/apuracao-tarifa/${rows[0].id}/reprocessar`;

    const params = {};
    if (!!dataTed) {
      params['dataTed'] = dataTed;
    }

    api_multipague_tarifador
      .put(
        URL,
        {},
        {
          headers: {
            'x-assinatura-financeira': password,
          },
          params,
        },
      )
      .then(() => {
        setOpenModalTed(null);
        setSelectionModel([]);
        setOpenModalPassword(false);
        carregarMonitor();
        toastComponentSuccess(
          !!dataTed
            ? 'TED Agendada com sucesso!'
            : 'TED Realizada com sucesso!',
          theme,
        );
      })
      .catch(error => {
        setOpenModalPassword(false);
        toastComponentError(error.response.data.mensagem, theme);
      })
      .finally(() => {
        setSelectionModel([]);
        setOpenModalPassword(false);
        setLoadPage(false);
      });
  };

  const handleExportarCSV = () => {
    if (selectionModel.length > 0) {
      setLoadPage(true);
      api_multipague_tarifador
        .post('/apuracao-tarifa/gerar-csv-selecionados', selectionModel, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream',
          },
          responseType: 'arraybuffer',
        })
        .then(async ({ data }) => {
          await downloadCSV(data, 'Tarifador-monitor-geral.csv');
          toastComponentSuccess(
            'Tarifador Monitor Geral exportado com sucesso!',
            theme,
          );
        })
        .catch(error => {
          toastComponentError(error.response.data.mensagem, theme);
        })
        .finally(() => {
          setLoadPage(false);
        });
    } else {
      setLoadPage(true);
      const URL = `/apuracao-tarifa/gerar-csv`;
      const [ano, mes] = params.periodo.split('-');
      const body = {
        mes: mes.padStart(2, '0'),
        ano,
        ...params,
      };
      api_multipague_tarifador
        .get(URL, { params: body, responseType: 'blob' })
        .then(async ({ data }) => {
          await downloadCSV(data, 'Tarifador-monitor-geral.csv');
          toastComponentSuccess(
            'Tarifador Monitor Geral exportado com sucesso!',
            theme,
          );
        })
        .catch(error => {
          toastComponentError(error.response.data.mensagem, theme);
        })
        .finally(() => {
          setLoadPage(false);
        });
    }
  };

  const getOptionsIdentificadorEvento = () => {
    const URL = `/identificador-evento`;
    api_multipague_configuracao.get(URL).then(async ({ data }) => {
      setOptionsIdentificadorEvento(
        data.map(item => ({
          label: item.descricao,
          value: item.valor,
        })),
      );
    });
  };

  const getOptionsSegmento = () => {
    const URL = `/segmento`;
    api_multipague_configuracao.get(URL).then(async ({ data }) => {
      const format = data.map(item => ({
        label: item.descricao,
        value: item.id,
      }));
      setOptionsSegmentos(format);
    });
  };

  const getOptionsStatusTed = () => {
    const URL = `/status-transacao`;
    api_multipague_transacao.get(URL).then(async ({ data }) => {
      const format = data.map(item => ({
        label: item.nome,
        value: item.nome,
      }));
      setOptionsStatusTed(format);
    });
  };

  React.useEffect(() => {
    getOptionsIdentificadorEvento();
    getOptionsSegmento();
    getOptionsStatusTed();
  }, []);

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <DateComponent
            callback={value => {
              if (!!value) {
                setParams(old => ({ ...old, periodo: value }));
              } else {
                setParams(old => ({ ...old, periodo: ultimoPeriodo }));
              }
            }}
            label="Selecionar Período"
            id="data-periodo-inicio-filtro"
            defaultValue={params.periodo}
            value={params.periodo}
            type={'month'}
            inputProps={{
              max: ultimoPeriodo,
            }}
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <AutocompleteClienteConta
            ref={refAutocomplete}
            onChange={item => {
              setParams(old => ({ ...old, idClienteConta: item?.value }));
            }}
          />
        ),
        grid: { xs: 12, sm: 8, md: 6, lg: 6 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-identificador"
            size="small"
            options={optionsStatusTed}
            getOptionLabel={option => option.label}
            onChange={(_, option) => {
              setParams(old => ({ ...old, situacaoTed: option.value }));
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Status TED"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-segmento"
            size="small"
            options={optionsSegmentos}
            getOptionLabel={option => option.label}
            onChange={(_, option) => {
              setParams(old => ({
                ...old,
                idSegmentoTarifador: option?.value,
              }));
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Segmento Tarifador"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-identificador"
            size="small"
            options={optionsIdentificadorEvento}
            getOptionLabel={option => option.label}
            onChange={(_, option) => {
              setParams(old => ({ ...old, identificadorEvento: option.value }));
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Identificador Evento"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
    ],
    searchButton: {
      searchButton: () => carregarMonitor(),
    },
    cleanButton: {
      cleanButton: () => {
        carregarMonitor();
        if (refAutocomplete?.current) {
          refAutocomplete?.current?.reset();
        }
        setParams(paramsInicial);
      },
    },
  };

  const idsPodeSelecionar = React.useMemo(() => {
    return rowsCached.map(item => item.id);
  }, [rowsCached]);

  useEffect(() => {
    carregarMonitor();
  }, []);

  const classesModal = useStyles();

  return (
    <>
      <DataGridWrapper
        headerComponent={() => (
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{
                display: 'flex',
                marginLeft: '6.8px',
              }}
            >
              <Chip
                style={{ marginRight: '8px' }}
                variant="outlined"
                label="Realizar TED em Lote"
                color="primary"
                onClick={() => {
                  setOpenModalTed(
                    rowsCached.filter(item => selectionModel.includes(item.id)),
                  );
                }}
                disabled={selectionModel?.length === 0}
              />
              <Chip
                variant="outlined"
                label="Exportar CSV"
                color="secondary"
                onClick={handleExportarCSV}
              />
            </Grid>
          </Grid>
        )}
        formData={filterInputs}
        minHeight="600px"
        rows={rows}
        checkboxSelection
        columns={columns}
        loading={loadButton}
        rowCount={paginate.count}
        page={paginate.page}
        pageSize={paginate.size}
        title="Monitor Geral"
        crumb={[{ link: '/backoffice', name: 'Home' }, { name: 'Tarifador' }]}
        newRegister={undefined}
        onSelectionModelChange={newSelection => {
          const ids = Object.values(newSelection);
          const newIds = ids.filter(
            id =>
              !selectionModel.includes(id) && idsPodeSelecionar.includes(id),
          );
          setSelectionModel(old => [...old, ...newIds]);
        }}
        onRowClick={() => {
          if (rowsCached?.length === 1) {
            setCheckedAll(true);
          }
        }}
        paginationMode="server"
        paginate
        selectionModel={selectionModel}
        onPageChange={page => {
          carregarMonitor(page);
          setPaginate(old => ({ ...old, page }));
        }}
      />

      <ModalTedUnica
        closePassword={openModalPassword}
        setOpenModalPassword={setOpenModalPassword}
        onClose={() => setOpenModalTed(null)}
        rows={openModalTed}
        title="Realizar TED"
        onConfirm={onSubmit}
      />
      <Loading show={loadPage} />
    </>
  );
}
