/* eslint react/prop-types: 0 */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import TopBar from '../TopBar';

import {
  Container,
  Subcontainer,
  Principal,
  CaminhoScreen,
  ContainerPath,
  PathToPag,
  TitlePrincipalPath,
} from './styles';
import { useHistory } from 'react-router';

const EstruturaContainer = ({ noMenu, children, root = [], ...rest }) => {
  const history = useHistory();
  const theme = useTheme()
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      backgroundColor: '#fff',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  }));

  const classes = useStyles();
  return !!noMenu ? (
    <Container {...rest}>
      <Subcontainer>
        <Principal>
          <CaminhoScreen>
            <ContainerPath>
              {root.length &&
                root.path.map(p => (
                  <PathToPag onClick={() => history.push(p.caminho)}>
                    <p>{p.name}</p>
                  </PathToPag>
                ))}

              <TitlePrincipalPath>
                <p>{root.pagAtual}</p>
              </TitlePrincipalPath>
            </ContainerPath>
          </CaminhoScreen>
          {children}
        </Principal>
      </Subcontainer>
    </Container>
  ) : (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <TopBar />
      <main
        style={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <div className={classes.appBarSpacer} />
        <div maxWidth="lg" className={classes.container}>
          <Container {...rest}>
            <Subcontainer>
              <Principal>
                <CaminhoScreen>
                  <ContainerPath>
                    {root.path.map(p => (
                      <PathToPag onClick={() => history.push(p.caminho)}>
                        <p>{p.name}</p>
                      </PathToPag>
                    ))}
                    <TitlePrincipalPath>
                      <p>{root.pagAtual}</p>
                    </TitlePrincipalPath>
                  </ContainerPath>
                </CaminhoScreen>
                {children}
              </Principal>
            </Subcontainer>
          </Container>
        </div>
      </main>
    </div>
  );
};

export default EstruturaContainer;
