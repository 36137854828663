export const Types = {
  ACTIVEMENU: 'app/activemenu',
  MODE_THEME: 'app/modeTheme',
  PREVIEW_THEME: 'app/previewTheme',
};

const INITIAL_STATE = {
  activeMenu: '',
  modeTheme: 'light',
  previewTheme: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ACTIVEMENU:
      return { ...state, activeMenu: action.payload };
    case Types.MODE_THEME:
      return { ...state, modeTheme: action.payload };
    case Types.PREVIEW_THEME:
      return { ...state, previewTheme: action.payload };
    default:
      return state;
  }
};

export const setMenu = menu => {
  return {
    type: Types.ACTIVEMENU,
    payload: menu,
  };
};

export const changeModeDark = active => {
  return {
    type: Types.MODE_THEME,
    payload: active ? 'dark' : 'light',
  };
};

export const changePreviewTheme = theme => {
  return {
    type: Types.PREVIEW_THEME,
    payload: theme,
  };
};
