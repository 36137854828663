import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function Introducing() {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push('/backoffice/new-backoffice');
    }, 1000);
  }, []);
  return <div></div>;
}
