import React, { useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  Tooltip,
  alpha,
  Grid,
  IconButton,
} from '@material-ui/core';
import DataGridWrapper from '../../../components/DataGrid/DataGridWrapper';
import { toastComponentError } from '../../../components/Toast';
import { api_multipague_cobranca } from '../../../services/api';
import moment from 'moment';
import { MdArrowBack, MdDownload } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { contextContentString } from '../../../utils/applicationContext';

const ArquivosGerados = () => {
  const theme = useTheme();
  const history = useHistory();
  const formState = history.location.state?.formState;
  const initialFormArquivosGerados = {
    dataInicial: moment().format('YYYY-MM-DD'),
    dataFinal: moment().format('YYYY-MM-DD'),
    situacao: '',
  };
  const [formArquivosGerados, setFormArquivosGerados] = React.useState(
    initialFormArquivosGerados,
  );
  const [dataGridState, setDataGridStateArquivosGerados] = React.useState({
    rows: [],
    page: 0,
    pageSize: 10,
    loading: false,
    rowCount: 0,
  });

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 180,
    },
    {
      field: 'dataCriacao',
      headerName: 'Data Criação',
      minWidth: 150,
      flex: 1,
      renderCell: event => {
        return (
          <span>
            {event?.row?.dataCriacao
              ? moment(event.row.dataCriacao).format('DD/MM/YYYY HH:mm:ss')
              : '-'}
          </span>
        );
      },
    },
    {
      field: 'situacao',
      width: 150,
      flex: 1,
      headerName: 'Situação',
    },
    {
      field: 'caminhoStorage',
      headerName: 'Ação',
      align: 'center',
      renderCell: event => {
        return event.row.caminhoStorage ? (
          <Tooltip title="Download">
            <a
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              href={event.row.caminhoStorage}
              download={event.row.caminhoStorage}
            >
              <MdDownload size={22} color={theme.palette.primary.main} />
            </a>
          </Tooltip>
        ) : (
          <Tooltip title="Download">
            <a
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <MdDownload
                size={22}
                color={alpha(theme.palette.text.disabled, 0.2)}
              />
            </a>
          </Tooltip>
        );
      },
    },
  ];

  const handleInputArquivosGerados = e => {
    setFormArquivosGerados(old => ({
      ...old,
      [e?.target?.name]: e.target.value,
    }));
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Inicial"
            value={formArquivosGerados.dataInicial}
            variant="outlined"
            size="small"
            type="date"
            onChange={handleInputArquivosGerados}
            name="dataInicial"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Final"
            value={formArquivosGerados.dataFinal}
            variant="outlined"
            size="small"
            type="date"
            onChange={handleInputArquivosGerados}
            name="dataFinal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 3 },
      },
      {
        html: () => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          >
            <InputLabel
              shrink
              style={{
                backgroundColor: theme.palette.background.paper,
                padding: '0 5px',
              }}
              id="demo-simple-select-outlined-label"
            >
              Situação ?
            </InputLabel>
            <Select
              value={formArquivosGerados.situacao}
              fullWidth
              name="situacao"
              label="Situação"
              id="outlined-size-small"
              variant="outlined"
              size="small"
              onChange={handleInputArquivosGerados}
            >
              <MenuItem value="SOLICITADO">SOLICITADO</MenuItem>
              <MenuItem value="GERANDO">GERANDO</MenuItem>
              <MenuItem value="ERRO">ERRO</MenuItem>
              <MenuItem value="FINALIZADO">FINALIZADO</MenuItem>
            </Select>
          </FormControl>
        ),
        grid: { xs: 12, sm: 12, md: 5 },
      },
      {
        html: () => (
          <Grid
            container
            spacing={2}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Tooltip title="Voltar">
                <IconButton
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: 'none',
                    textTransform: 'initial',
                    fontSize: '8px',
                    backgroundColor: alpha(
                      theme.palette.appBar[theme.palette.type].bgMenu,
                      0.7,
                    ),
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                    },
                  }}
                  onClick={() =>
                    history.push('/backoffice/painel-vencidos', {
                      formStateBack: formState,
                    })
                  }
                >
                  <MdArrowBack
                    color={theme.palette.getContrastText(
                      alpha(
                        theme.palette.appBar[theme.palette.type].bgMenu,
                        0.4,
                      ),
                    )}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
        grid: { xs: 12, sm: 6, md: 4 },
      },
    ],
    searchButton: {
      searchButton: () => {
        listArquivosGerados(0, 10);
      },
    },
    cleanButton: {
      cleanButton: () => {
        setFormArquivosGerados(initialFormArquivosGerados);
        setDataGridStateArquivosGerados({
          rows: [],
          page: 0,
          pageSize: 10,
          loading: false,
          rowCount: 0,
        });
        listArquivosGerados(0, 10);
      },
    },
  };

  const listArquivosGerados = (page, pageSize) => {
    setDataGridStateArquivosGerados(prev => ({
      ...prev,
      page: page,
      pageSize: pageSize,
      loading: true,
    }));
    api_multipague_cobranca
      .get(
        `/boletos-protesto?page=${page}&size=${pageSize}&dataInicial=${formArquivosGerados.dataInicial}&dataFinal=${formArquivosGerados.dataFinal}&situacao=${formArquivosGerados.situacao}`,
        {
          headers: {
            ApplicationContext: window.btoa(contextContentString),
          },
        },
      )
      .then(response => {
        setDataGridStateArquivosGerados(prev => ({
          ...prev,
          rows: response?.data?.content,
          rowCount: response?.data?.totalElements,
          loading: false,
        }));
      })
      .catch(error => {
        toastComponentError(error?.message, theme);
        setDataGridStateArquivosGerados(prev => ({
          ...prev,
          rows: [],
          rowCount: 0,
          loading: false,
        }));
      });
  };

  useEffect(() => {
    listArquivosGerados(0, 10);
  }, []);

  return (
    <DataGridWrapper
      title={'Arquivos Gerados'}
      formData={filterInputs}
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        {
          link: '/backoffice/painel-vencidos',
          name: 'Painel de Vencidos',
        },
      ]}
      rows={dataGridState.rows}
      key={dataGridState.rows?.map(item => item?.id)}
      columns={columns}
      loading={dataGridState.loading}
      pageSize={dataGridState.pageSize}
      minHeight="600px"
      pagination="server"
      page={dataGridState.page}
      styleFormBackground={{ display: 'flex', justifyContent: 'space-between' }}
      componentsProps={{
        pagination: { classes: null },
      }}
      onPageSizeChangeFunction={newPageSize => {
        listArquivosGerados(0, newPageSize);
      }}
      onPageChange={newPage => {
        listArquivosGerados(newPage, dataGridState.pageSize);
      }}
      paginationMode="server"
      rowsPerPageOptions={[10, 20, 50, 100]}
      rowCount={dataGridState.rowCount}
    />
  );
};

export default ArquivosGerados;
