import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
  inputBloq: {
    display: 'none',
  },
  inputFree: {
    display: 'block',
  },
  boxContainer: {
    borderRadius: '10px',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
  },
}));
