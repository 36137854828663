import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../../components/Wrapper';
import DataGrid from '../../../components/DataGrid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FaSearch } from 'react-icons/fa';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import Box from '../../../components/Box';
import { api_multipague_conta } from '../../../services/api';
import { useTheme } from '@material-ui/core/styles';
import { MdClear, MdRemoveRedEye } from 'react-icons/md';
import DataGridWrapper from '../../../components/DataGrid/DataGridWrapper';
import { toastComponentError } from '../../../components/Toast';
import { Button } from '@material-ui/core';

export default function AssociacaoContasListar() {
  const history = useHistory();
  const theme = useTheme();
  const columns = [
    { field: 'cnpj', headerName: 'CNPJ', flex: 1, minWidth: 110 },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      flex: 3,
      minWidth: 200,
    },
    { field: 'banco', headerName: 'Banco', flex: 2, minWidth: 150 },
    { field: 'tipoControle', headerName: 'Controle', minWidth: 150 },
    { field: 'agencia', headerName: 'Agencia', flex: 1, minWidth: 100 },
    { field: 'conta', headerName: 'Conta', flex: 1, minWidth: 100 },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Visualizar',
      type: 'text',
      width: 110,
      align: 'center',
      headerAlign: 'center',

      renderCell: event => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleEditar(event.row);
            }}

            style={{ borderRadius: '20px' }}
          >
            <MdRemoveRedEye
              style={{ cursor: 'pointer' }}
              color={theme.palette.primary.main}
              size={20}
            />
          </Button>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [loadButton, setLoadButton] = useState(false);
  const [cnpj, setCnpj] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');

  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/conta/filtro?cnpjCpf=&nome=`;

  const cleanFilters = () => {
    setCnpj('');
    setRazaoSocial('');
    pesquisar(URL);
  };

  useEffect(() => {
    setLoadButton(true);
    api_multipague_conta
      .get(URL, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;
        console.log(monitorFilter);

        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.cliente.id,
            idRoot: data.id,
            razaoSocial: data.cliente.nome,
            cnpj: data.cliente.cnpjCpf,
            banco: data.banco.nome,
            tipoControle: data.tipoControle,
            agencia: data.agencia == null ? ' ' : data.agencia,
            conta:
              (data.conta == null ? ' ' : data.conta + '-') +
              (data.digitoConta == 'null' ? ' ' : data.digitoConta),
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }, []);

  function pesquisar(
    URLfilter = `/conta/filtro?cnpjCpf=${cnpj}&nome=${encodeURIComponent(
      razaoSocial,
    )}`,
  ) {
    setLoadButton(true);
    api_multipague_conta
      .get(URLfilter, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        const monitorFilterSeacrh = response.data;

        if (monitorFilterSeacrh.length) {
          const aquiTableSearch = monitorFilterSeacrh.map(data => ({
            ...data,
            id: data.id == null ? ' ' : data.id,
            clienteId: data.cliente.id == null ? ' ' : data.cliente.id,
            idRoot: data.id == null ? ' ' : data.id,
            razaoSocial: data.cliente.nome == null ? ' ' : data.cliente.nome,
            cnpj: data.cliente.cnpjCpf == null ? ' ' : data.cliente.cnpjCpf,
            banco: data.banco.nome == null ? ' ' : data.banco.nome,
            agencia: data.agencia == null ? ' ' : data.agencia,
            conta:
              (data.conta == null ? ' ' : data.conta + '-') +
              (data.digitoConta == 'null' ? ' ' : data.digitoConta),
          }));
          setRows(aquiTableSearch);
        } else {
          toastComponentError('Nenhum dado disponível!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  const handleEditar = value => {
    console.log('Dados: ', value);
    localStorage.setItem('cnpj', value.cnpj);
    localStorage.setItem('evento', JSON.stringify(value.evento));
    localStorage.setItem('idConsulta', value.idRoot);
    localStorage.setItem('razaoSocial', value.razaoSocial);
    localStorage.setItem('utilizaEsteira', value.utilizaEsteira);
    localStorage.setItem('valorMaximo', value.valorMaximo);
    localStorage.setItem('valorMinimo', value.valorMinimo);
    localStorage.setItem('tipoControle', value.tipoControle);
    localStorage.setItem('conta', `${value.agencia} ${value.conta}`);
    history.push('/backoffice/associacao-contas-consulta');
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="CNPJ"
            id="outlined-size-small"
            value={cnpj}
            onChange={e => {
              setCnpj(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Razão Social"
            id="outlined-size-small"
            value={razaoSocial}
            onChange={e => {
              setRazaoSocial(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
    ],
    searchButton: {
      searchButton: () => pesquisar(),
    },
    cleanButton: {
      cleanButton: () => cleanFilters(),
    },
  };

  return (
    <DataGridWrapper
      rows={rows}
      columns={columns}
      loading={loadButton}
      pageSize={10}
      minHeight="600px"
      formData={filterInputs}
      title="Associação de Contas"
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        { name: 'Configurações' },
      ]}
    />
  );
}
