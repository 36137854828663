import React, { useEffect, useRef } from 'react';
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  alpha,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import { useState } from 'react';
import { MdCloudDownload } from 'react-icons/md';
import { useStyles } from './styles';

export const PDFCSVDownload = ({
  handlePDFCSV = {
    csvExcel: 'CSV',
    downloadPDF: () => {},
    downloadExcel: () => {},
    rotaArquivosGerados: () => {},
    carregaExcel: false,
    carregaIconExcel: false,
    carregaIconPDF: false,
    carregaPDF: false,
    title,
  },
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const modalRef = useRef();

  const [showListExport, setShowListExport] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowListExport(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <Grid
      item
      spacing={3}
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
      }}
    >
      <Grid spacing={3} style={{ position: 'relative' }}>
        <Tooltip title="Exportar" placement="top">
          <IconButton
            variant="contained"
            className={classes.hoverIconButton}
            color="primary"
            onClick={() => setShowListExport(!showListExport)}
          >
            <MdCloudDownload
              color={theme.palette.getContrastText(
                alpha(theme.palette.appBar[theme.palette.type].bgMenu, 0.4),
              )}
            />
          </IconButton>
        </Tooltip>
        {showListExport ? (
          <div ref={modalRef}>
            <List
              style={{
                border: `1px solid ${alpha(theme.palette.primary.dark, 0.2)}`,
                boxShadow: `0px 8px 45px -15px ${alpha(
                  theme.palette.primary.dark,
                  0.2,
                )}`,
                width: 'auto',
                borderRadius: '10px',
                backgroundColor: theme.palette.background.paper,
                position: 'absolute',
                zIndex: theme.zIndex.drawer + 8,
                top: '110%',
                flexDirection: 'row',
                right: 0,
                display: 'flex',
              }}
            >
              <ListItem>
                {!handlePDFCSV.carregaPDF && (
                  <IconButton
                    onClick={() => handlePDFCSV.downloadPDF()}
                    style={{
                      borderRadius: '2px',
                      marginRight: '30px',
                    }}
                  >
                    GERAR PDF
                  </IconButton>
                )}
                {handlePDFCSV.carregaPDF && (
                  <IconButton
                    style={{
                      borderRadius: '2px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    onClick={() =>
                      !handlePDFCSV.carregaIconExcel &&
                      handlePDFCSV.rotaArquivosGerados()
                    }
                  >
                    {!handlePDFCSV.carregaIconPDF ? (
                      <p>ARQUIVOS GERADOS</p>
                    ) : (
                      <CircularProgress
                        style={{ margin: '0 1.2rem 0' }}
                        size={20}
                      />
                    )}
                  </IconButton>
                )}
              </ListItem>
              <ListItem>
                {!handlePDFCSV.carregaExcel && (
                  <IconButton
                    onClick={() => handlePDFCSV.downloadExcel()}
                    style={{
                      borderRadius: '2px',
                      marginRight: '30px',
                    }}
                  >
                    GERAR{' '}
                    {!!handlePDFCSV.csvExcel ? handlePDFCSV.csvExcel : 'CSV'}
                  </IconButton>
                )}
                {handlePDFCSV.carregaExcel && (
                  <IconButton
                    onClick={() =>
                      !handlePDFCSV.carregaIconExcel &&
                      handlePDFCSV.rotaArquivosGerados()
                    }
                    style={{
                      borderRadius: '2px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {!handlePDFCSV.carregaIconExcel ? (
                      <p>ARQUIVOS GERADOS</p>
                    ) : (
                      <CircularProgress
                        style={{ margin: '0 1.2rem 0' }}
                        size={20}
                      />
                    )}
                  </IconButton>
                )}
              </ListItem>
            </List>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};
