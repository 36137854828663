import React from 'react';
import { MdArrowBack, MdSave } from 'react-icons/md';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';

export const ButtonsSaveReturn = ({
  loadButton,
  onclickconfirm,
  onclickback,
}) => {
  return (
    <>
      <LoadingBtn
        variant="contained"
        color="disabled"
        title={'Voltar'}
        style={{ marginRight: 10 }}
        onClick={onclickback}
        startIcon={<MdArrowBack color="background" />}
      />
      <LoadingBtn
        variant="contained"
        color="primary"
        title={'Salvar'}
        isLoading={loadButton}
        startIcon={!loadButton && <MdSave color="background" />}
        onClick={onclickconfirm}
      />
    </>
  );
};
