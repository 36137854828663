import React, { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';

import DefaultMenu from './Menu';
import { Tooltip, Box, useMediaQuery } from '@material-ui/core';
import { Brightness2, Brightness5 } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { changeModeDark } from '../store/modules/app';

const drawerWidth = 260;

const cookies = new Cookies();
const userName = cookies.get('name');
const useStyles = makeStyles(theme => ({
  appBar: {
    padding: '2px 0',
    position: 'fixed',
    borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.2)}`,
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.dark
        : theme.palette.background.default,
    color: theme.palette.appBar[theme.palette.type].text,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'auto',
    scrollbarColor: theme.palette.primary.main,
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      width: '8px',
      maxHeight: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '3px',
    },
  },
  menuButton: {
    marginLeft: -10,
    marginRight: 25,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    border: 'none',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7.9),
  },
  chevroletBackground: {
    boxShadow: 'none',
    marginLeft: 15,
    textTransform: 'initial',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  toolbarIcon: {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    height: '80px',
    width: 260,
    borderBottom: `1px solid ${alpha(theme.palette.background.paper, 0.2)}`,
    ...theme.mixins.toolbar,
  },
  logo: {
    width: '85%',
  },
}));

export default function TopBar({ onStateChange = () => {} }) {
  const theme = useTheme();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { modeTheme } = useSelector(state => state.app);
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const matchesPoint = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (matchesPoint) {
      setOpen(false);
    }
  }, [matchesPoint]);

  useEffect(() => {
    !!onStateChange(open) ? onStateChange(open) : null;
  }, [open]);

  return (
    <>
      <AppBar
        position="absolute"
        elevation={0}
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar style={{ paddingLeft: 0 }}>
          <Box
            display="flex"
            width={'100%'}
            margin={1}
            alignItems="center"
            height={open ? '60px' : 'auto'}
          >
            <Box flexGrow={1} justifyContent={'flex-start'}>
              <Typography
                variant="body1"
                style={{
                  color:
                    theme.palette.type === 'dark'
                      ? theme.palette.background.default
                      : theme.palette.appBar[theme.palette.type].bgMenu,
                  borderRadius: '10px',
                  fontSize: '1.2rem',
                  padding: open ? '18px' : '10px',
                  textAlign: 'left',
                  height: open ? '60px' : 'auto',
                }}
                component="h2"
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx(
                    classes.menuButton,
                    open && classes.menuButtonHidden,
                  )}
                >
                  <MenuIcon />
                </IconButton>
                Backoffice {useTheme()?.config?.clienteName}
              </Typography>
            </Box>
            <Box>
              <Tooltip
                title={
                  modeTheme === 'dark'
                    ? 'Desativar tema escuro'
                    : 'Ativar tema escuro'
                }
              >
                <IconButton
                  onClick={() =>
                    dispatch(
                      changeModeDark(modeTheme === 'dark' ? false : true),
                    )
                  }
                >
                  {modeTheme === 'dark' ? (
                    <Brightness5
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  ) : (
                    <Brightness2
                      style={{
                        color: theme.palette.appBar[theme.palette.type].bgMenu,
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
        <div
          style={{
            backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
            borderTopRightRadius: '0',
          }}
        ></div>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={theme.config.menuLogo} className={classes.logo} />
          <IconButton
            size="small"
            onClick={handleDrawerClose}
            className={classes.chevroletBackground}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <DefaultMenu tooltip={!open} />
      </Drawer>
    </>
  );
}
