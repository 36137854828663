import React, { useEffect } from 'react';
import { Button, Dialog, Tooltip, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import { Colorize } from '@material-ui/icons';

const Container = styled.div`
  padding: 1rem;
  div.chrome-picker {
    box-shadow: none !important;
    border: solid 1px #ccc;
    border-radius: 5px;
  }
`;

const DialogColorPicker = ({ color, onClose, onChange }) => {
  const [defaultColor, setDefaultColor] = useState('#fff');
  useEffect(() => {
    setDefaultColor(color);
  }, [color]);

  return (
    <Dialog open={true} onClose={onClose}>
      <Container>
        <ChromePicker
          disableAlpha
          onChange={color => {
            setDefaultColor(color);
          }}
          color={defaultColor}
        />

        <Button
          onClick={() => {
            !!onChange && onChange(defaultColor.hex);
            onClose();
          }}
          style={{ marginTop: '1rem' }}
          variant="outlined"
          color="default"
          fullWidth
          size={'small'}
        >
          Selecionar
        </Button>
      </Container>
    </Dialog>
  );
};

const BoxColor = ({ onChange, bg, color, valueForm, text = '' }) => {
  const [openPicker, setOpenPicker] = useState(false);
  return (
    <Tooltip title={`Alterar cor ${valueForm}`}>
      <div>
        <Button
          style={{
            backgroundColor: bg,
            color: color || '#fff',
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
            height: '38px',
            marginLeft: '-20px',
          }}
          startIcon={<Colorize />}
          onClick={() => {
            setOpenPicker(true);
          }}
        >
          {text}{' '}
        </Button>
        {openPicker && (
          <DialogColorPicker
            color={color}
            onChange={onChange}
            onClose={() => setOpenPicker(false)}
          />
        )}
      </div>
    </Tooltip>
  );
};
export default BoxColor;
