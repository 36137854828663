import React, { createContext, useCallback, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { getTransacoes, actionUnit, actionLote } from './service';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const MonitorContext = createContext();

export const MonitorEsccrowProvider = ({ children, theme }) => {
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  const initialModal = {
    open: false,
    action: '',
    transacao: null,
  };
  const initialModalLote = {
    open: false,
    action: '',
  };

  const initialFilter = {
    situacao: 'PENDENTE',
    nome: '',
    dataInicio: '',
    dataFim: '',
    cnpjCpf: '',
  };
  const initialPassword = {
    open: false,
    callback: null,
  };

  const [modal, setModal] = useState(initialModal);
  const [modalLote, setModalLote] = useState(initialModalLote);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filter, setFilter] = useState(initialFilter);

  const [popPupPassword, setPopPupPassword] = useState(initialPassword);

  const onFilter = async params => {
    setFilter(params);
    const values = Object.keys(params);
    values.forEach(attr => {
      if (params[attr] == '') {
        delete params[attr];
      }
    });
    await fetchData(params);
  };

  const fetchData = useCallback(async (params = {}, page = 0) => {
    try {
      setLoadingList(true);
      const { transacoes, totalElements } = await getTransacoes(params, page);
      setRows(transacoes);
      setRowCount(totalElements);
      setLoadingList(false);
    } catch (error) {
      toastComponentError(error, theme);
      setLoadingList(false);
    }
  }, []);

  const changePage = page => {
    fetchData(filter, page);
  };

  const openModalActionUnit = (action, id) => {
    setAnchorEl(null);
    const dataFilter = rows.find(item => item.id === parseInt(id));
    const dataModal = {
      open: true,
      transacao: dataFilter,
      action,
    };
    setModal(dataModal);
  };

  const openModalActionLote = action => {
    const dataModal = {
      open: true,
      action,
    };
    setModalLote(dataModal);
  };

  const closeModalActionUnit = () => {
    setModal(initialModal);
  };
  const closeModalActionLote = () => {
    setModalLote(initialModalLote);
  };

  const openModalPasswordUnit = () => {
    setPopPupPassword({
      open: true,
      callback: onConfirmActionUnit,
      modal,
    });
    setModal(old => ({ ...old, open: false }));
  };

  const openModalPasswordLote = () => {
    setPopPupPassword({
      open: true,
      callback: onConfirmActionLote,
      modal: selectionModel,
    });
    setModalLote(old => ({ ...old, open: false }));
  };

  const onConfirmActionUnit = async password => {
    setPopPupPassword(initialPassword);
    try {
      setLoading(true);
      if (modal.action === 'Aprovar') {
        await actionUnit('aprovar', modal?.transacao?.id, password);
      } else {
        await actionUnit('negar', modal?.transacao?.id, password);
      }
      toastComponentSuccess(
        `Transação ${
          modal.action === 'Aprovar' ? 'aprovada' : 'reprovada'
        } com sucesso!`,
        theme,
      );
      upListUi([modal?.transacao?.id]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastComponentError(error, theme);
    }
  };

  const onConfirmActionLote = async password => {
    setPopPupPassword(initialPassword);
    try {
      setLoading(true);
      if (modalLote.action === 'Aprovar') {
        await actionLote('aprovar', selectionModel, password);
      } else {
        await actionLote('negar', selectionModel, password);
      }
      toastComponentSuccess(
        `Transações ${
          modalLote.action === 'Aprovar' ? 'aprovadas' : 'reprovadas'
        } com sucesso!`,
        theme,
      );
      upListUi(selectionModel);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastComponentError(error, theme);
    }
  };

  const upListUi = ids => {
    setSelectionModel([]);
    const items = rows.filter(t => !ids.includes(t.id));
    setRows(items);
    setRowCount(items.length);
  };

  const onClosePassword = () => {
    setPopPupPassword(initialPassword);
  };

  return (
    <MonitorContext.Provider
      value={{
        fetchData,
        onFilter,
        filter,
        loading,
        loadingList,
        selectionModel,
        setSelectionModel,
        changePage,
        closeModalActionUnit,
        openModalActionUnit,
        openModalPasswordUnit,
        openModalActionLote,
        closeModalActionLote,
        openModalPasswordLote,
        modal,
        rows,
        modalLote,
        rowCount,
        anchorEl,
        setAnchorEl,
        popPupPassword,
        onClosePassword,
      }}
    >
      {children}
    </MonitorContext.Provider>
  );
};

const useMonitor = () => {
  return useContext(MonitorContext);
};

export default useMonitor;
