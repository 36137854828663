import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Typography,
  Box as MuiBox,
  AccordionSummary,
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { MdAdd, MdExpandLess, MdExpandMore, MdSearch } from 'react-icons/md';
import EditIcon from '@material-ui/icons/Edit';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';

import { useStyles } from '../styles';
import {
  deleteAllTermAccounst,
  deleteAllTermService,
  deleteTermContas,
  deleteTermService,
  getTermsContas,
  getTermsServices,
} from '../services';
import PlusButton from '../../../components/Buttons/PlusButton';
import VincularServicos from './VinculaServicos';
import VincularContas from './VinculaContas';
import { TextEditor } from '../../../components/Inputs/TextEditor';
import { CheckCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalConfirm from '../../../components/ModalConfirm';
import { AccordionLists } from './Accordion/service';
import { ButtonsSaveReturn } from '../ButtonsSaveReturn';
import {
  featchDeleteTermConta,
  featchDeleteTermService,
  fetchContas,
  handleDeleteAllTermAccounts,
  handleDeleteAllTermService,
  handleSalvarTermo,
  handleServices,
  initialOpenModal,
  initialLoadingDatagrid,
} from './handlers';
import { IoMdTrash } from 'react-icons/io';
import moment from 'moment';

export default function CadastroTermos() {
  const history = useHistory();
  const theme = useTheme();
  const isEditTerm = history.location.state?.value;
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [showServicoeTermos, setShowServicoeTermos] = useState(false);
  const [showContaeTermos, setShowContaeTermos] = useState(false);
  const [openShowVincularServicos, setOpenShowVincularServicos] =
    useState(false);
  const [openShowVincularContas, setOpenShowVincularContas] = useState(false);
  const [loadingDatagrid, setLoadingDatagrid] = useState(
    initialLoadingDatagrid,
  );
  const [dadosTermo, setDadosTermo] = useState({
    id: isEditTerm?.id,
    assunto: isEditTerm?.assunto,
    observacao: isEditTerm?.observacao,
    template: isEditTerm?.template,
    servicos: isEditTerm?.servicos,
    contas: isEditTerm?.contas,
  });
  const [dataVigencia, setDataVigencia] = useState({
    inicio: '',
    fim: '',
  });
  const [rows, setRows] = useState([]);
  const [rowsContas, setRowsContas] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [expandedContas, setExpandedContas] = useState(false);
  const [error, setError] = useState({ inicio: false, fim: false });
  const [openModalDelete, setOpenModalDelete] = useState(initialOpenModal);
  const [filtroServicos, setFiltroServicos] = useState();
  const [filtroContas, setFiltroContas] = useState();
  const [sendFiltrosServicos, setSendFiltrosServicos] = useState();
  const [sendFiltrosContas, setSendFiltrosContas] = useState();

  const columnsServices = [
    { field: 'id', headerName: 'Código', flex: 1, minWidth: 100 },
    { field: 'descricao', headerName: 'Descrição', flex: 3, minWidth: 200 },
    {
      field: 'ativo',
      headerName: 'Ativo',
      flex: 1,
      minWidth: 100,
      renderCell: event => {
        return !!event.row.ativo ? (
          <CheckCircle color="primary" />
        ) : (
          <CheckCircle color="disabled" />
        );
      },
    },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Ações',
      type: 'text',
      flex: 1,
      renderCell: event => {
        return (
          <MuiBox display={'flex'} justifyContent={'space-between'}>
            <Button
              variant="outlined"
              style={{ marginRight: 5, borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                localStorage.setItem('servicos', JSON.stringify(event.row));
                history.push('/backoffice/servicos-editar', {
                  cadastroTermo: dadosTermo,
                });
              }}
            >
              <EditIcon color={'primary'} size={20} />
            </Button>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                setOpenModalDelete({
                  open: true,
                  id: event.row.id,
                  term: dadosTermo.assunto,
                  service: event.row.descricao,
                  conta: '',
                });
              }}
            >
              <DeleteIcon color={'primary'} size={18} />
            </Button>
          </MuiBox>
        );
      },
    },
  ];

  const columnsContas = [
    { field: 'id', headerName: 'Código', flex: 1, minWidth: 100 },
    { field: 'nome', headerName: 'Nome', flex: 3, minWidth: 200 },
    { field: 'cnpjCpf', headerName: 'CNPJ/CPF', minWidth: 150 },
    { field: 'conta', headerName: 'Conta', minWidth: 150 },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Ações',
      type: 'text',
      renderCell: event => {
        return (
          <MuiBox display={'flex'} justifyContent={'space-between'}>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                setOpenModalDelete({
                  open: true,
                  id: event.row.id,
                  term: dadosTermo.assunto,
                  conta: event.row.nome,
                  service: '',
                });
              }}
            >
              <DeleteIcon color={'primary'} size={18} />
            </Button>
          </MuiBox>
        );
      },
    },
  ];

  useEffect(() => {
    if (!!openShowVincularServicos) {
      setExpanded(true);
    }
    if (!!openShowVincularContas) {
      setExpandedContas(true);
    }
  }, [openShowVincularServicos, openShowVincularContas]);

  return (
    <>
      <ModalConfirm
        confirmLabel="Deletar"
        descricao={
          <>
            Deseja deletar {openModalDelete.service ? 'o serviço' : 'a conta'}{' '}
            <b>
              {openModalDelete.service
                ? openModalDelete.service
                : openModalDelete.conta}
            </b>{' '}
            do termo <b>{openModalDelete.term}?</b>
          </>
        }
        open={!!openModalDelete.open}
        onClose={() => setOpenModalDelete(initialOpenModal)}
        onConfirm={() =>
          openModalDelete.service
            ? featchDeleteTermService(
                () => deleteTermService(dadosTermo.id, openModalDelete.id),
                setOpenModalDelete,
                () =>
                  handleServices(
                    setLoadingDatagrid,
                    () => getTermsServices(dadosTermo.id),
                    setDadosTermo,
                    setRows,
                  ),
                theme,
              )
            : featchDeleteTermConta(
                () => deleteTermContas(dadosTermo.id, openModalDelete.id),
                setOpenModalDelete,
                () =>
                  fetchContas(
                    setLoadingDatagrid,
                    setDadosTermo,
                    () => getTermsContas(dadosTermo.id),
                    setRowsContas,
                    theme,
                  ),
                theme,
              )
        }
      />
      <Wrapper
        title={dadosTermo?.id ? 'Edição Termo' : 'Cadastro Termo'}
        crumb={[{ link: '/backoffice', name: 'Home' }, { name: 'Termos' }]}
      >
        <Box className={classes.boxRadius}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                label="Assunto"
                id="outlined-size-small"
                value={dadosTermo?.assunto}
                onChange={event =>
                  setDadosTermo(prev => ({
                    ...prev,
                    assunto: event?.target?.value,
                  }))
                }
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                label="Observação"
                id="outlined-size-small"
                multiline
                minRows={6}
                value={dadosTermo?.observacao}
                onChange={event =>
                  setDadosTermo(prev => ({
                    ...prev,
                    observacao: event?.target?.value,
                  }))
                }
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextEditor
                name="template"
                label="Template"
                value={dadosTermo.template}
                onChange={content => {
                  setDadosTermo(prev => ({
                    ...prev,
                    template: content,
                  }));
                }}
              />
            </Grid>
            <Grid item md={12} className={classes.flexEnd}>
              <ButtonsSaveReturn
                loadButton={loadButton}
                onclickback={() => {
                  history.push('/backoffice/termos');
                }}
                onclickconfirm={() => {
                  !loadButton &&
                    handleSalvarTermo(
                      setLoadButton,
                      dadosTermo,
                      setDadosTermo,
                      setShowServicoeTermos,
                      setShowContaeTermos,
                      theme,
                    );
                }}
              />
            </Grid>
          </Grid>
          <AccordionLists
            expanded={
              !!dadosTermo?.id ? expanded : !!showServicoeTermos && expanded
            }
            onchange={() => {
              setExpanded(!expanded);
              handleServices(
                setLoadingDatagrid,
                () => getTermsServices(dadosTermo.id),
                setDadosTermo,
                setRows,
              );
            }}
            filtro={filtroServicos}
            onChangeFiltro={e => {
              setFiltroServicos(e.target.value);
            }}
            onFiltroSend={() => {
              setSendFiltrosServicos(filtroServicos);
            }}
            disabledPlusButton={!dadosTermo?.id && !showServicoeTermos}
            onPlusButton={() => {
              setExpanded(!expanded);
              setOpenShowVincularServicos(true);
            }}
            vinculo={
              <VincularServicos
                idTermo={dadosTermo?.id}
                checkedServices={dadosTermo?.servicos}
                filtro={sendFiltrosServicos}
                voltar={() => {
                  handleServices(
                    setLoadingDatagrid,
                    () => getTermsServices(dadosTermo.id),
                    setDadosTermo,
                    setRows,
                  );
                  setOpenShowVincularServicos(false);
                }}
              />
            }
            nome={'Serviços'}
            openShowVincularServicos={openShowVincularServicos}
            loadingDatagrid={loadingDatagrid.servico}
            rows={rows}
            columnsServices={columnsServices}
            deleteAll={() =>
              handleDeleteAllTermService(
                () => deleteAllTermService(dadosTermo?.id),
                () =>
                  handleServices(
                    setLoadingDatagrid,
                    () => getTermsServices(dadosTermo.id),
                    setDadosTermo,
                    setRows,
                  ),
                theme,
              )
            }
          />

          <AccordionLists
            expanded={
              !!dadosTermo?.id
                ? expandedContas
                : !!showContaeTermos && expandedContas
            }
            disabledPlusButton={!dadosTermo?.id && !showContaeTermos}
            onPlusButton={() => {
              setExpandedContas(!expandedContas);
              setOpenShowVincularContas(true);
              fetchContas(
                setLoadingDatagrid,
                setDadosTermo,
                () => getTermsContas(dadosTermo.id),
                setRowsContas,
                theme,
              );
            }}
            accordionProp={
              <AccordionSummary
                expandIcon={
                  <IconButton
                    disabled={!dadosTermo?.id && !showContaeTermos}
                    onClick={() => {
                      setExpandedContas(!expandedContas);
                      fetchContas(
                        setLoadingDatagrid,
                        setDadosTermo,
                        () => getTermsContas(dadosTermo.id),
                        setRowsContas,
                        theme,
                      );
                    }}
                  >
                    {expandedContas ? (
                      <MdExpandLess size={20} />
                    ) : (
                      <MdExpandMore size={20} />
                    )}
                  </IconButton>
                }
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Grid
                  container
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Grid
                    item
                    md={7}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography
                      variant="body1"
                      component="h3"
                      className={classes.marginRight10}
                    >
                      Contas
                    </Typography>
                    <PlusButton
                      title={'Adicionar Contas'}
                      startIcon={<MdAdd color="background" />}
                      disabled={!dadosTermo?.id && !showContaeTermos}
                      onClick={() => {
                        fetchContas(
                          setLoadingDatagrid,
                          setDadosTermo,
                          () => getTermsContas(dadosTermo.id),
                          setRowsContas,
                          theme,
                        );
                        setExpandedContas(!expandedContas);
                        setOpenShowVincularContas(true);
                      }}
                    />
                    <Tooltip
                      style={{ marginLeft: 10 }}
                      placement="top"
                      title={'Desvincular todos os Serviços'}
                    >
                      <IconButton
                        disabled={!dadosTermo?.id && !showContaeTermos}
                        onClick={() =>
                          handleDeleteAllTermAccounts(
                            () => deleteAllTermAccounst(dadosTermo?.id),
                            () =>
                              fetchContas(
                                setLoadingDatagrid,
                                setDadosTermo,
                                () => getTermsContas(dadosTermo.id),
                                setRowsContas,
                                theme,
                              ),
                            theme,
                          )
                        }
                      >
                        <IoMdTrash
                          color={
                            !dadosTermo?.id && !showContaeTermos
                              ? theme.palette.error['light']
                              : theme.palette.error['main']
                          }
                          size={18}
                          spacing={2}
                        />
                      </IconButton>
                    </Tooltip>
                    <Grid item md={5} xs={12}>
                      <TextField
                        value={filtroContas}
                        disabled={!openShowVincularContas}
                        onChange={e => {
                          setFiltroContas(e.target.value);
                        }}
                        variant="outlined"
                        style={{ margin: '0px 50px 0px 10px' }}
                        fullWidth
                        label="Buscar conta"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        InputProps={{
                          style: { borderRadius: '20px' },
                          endAdornment: (
                            <IconButton
                              style={{ padding: '5px' }}
                              disabled={!openShowVincularContas}
                              onClick={() => setSendFiltrosContas(filtroContas)}
                            >
                              <MdSearch
                                style={{ cursor: 'pointer' }}
                                color="inherit"
                                size={20}
                              />
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className={classes.textFieldSpace}
                    md={5}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <TextField
                      fullWidth
                      style={{ marginRight: 5 }}
                      type="date"
                      required
                      label="Data Início Vigência"
                      error={error.inicio}
                      id="outlined-size-small"
                      value={dataVigencia.inicio}
                      helperText={error.inicio && 'Valor inválido'}
                      onChange={event => {
                        setDataVigencia(prev => ({
                          ...prev,
                          inicio: event?.target?.value,
                        }));
                        const selectedDate = moment(event?.target?.value);
                        const isDateInvalid =
                          !selectedDate.isValid() ||
                          selectedDate.isBefore(moment(), 'day');

                        setError(prev => ({
                          ...prev,
                          inicio: isDateInvalid,
                        }));
                      }}
                      inputProps={{
                        min: moment().format('YYYY-MM-DD'),
                      }}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      required
                      type="date"
                      label="Data Fim Vigência"
                      error={error.fim}
                      helperText={error.fim && 'Valor inválido'}
                      id="outlined-size-small"
                      value={dataVigencia.fim}
                      onChange={event => {
                        setDataVigencia(prev => ({
                          ...prev,
                          fim: event?.target?.value,
                        }));

                        const isDateInvalid = !moment(
                          event?.target?.value,
                        ).isValid();

                        setError(prev => ({
                          ...prev,
                          fim: isDateInvalid,
                        }));
                      }}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
            }
            nome={'Contas'}
            vinculo={
              <VincularContas
                datas={dataVigencia}
                setError={setError}
                idTermo={dadosTermo.id}
                filtros={sendFiltrosContas}
                checkedContas={dadosTermo.contas}
                voltar={() => {
                  fetchContas(
                    setLoadingDatagrid,
                    setDadosTermo,
                    () => getTermsContas(dadosTermo.id),
                    setRowsContas,
                    theme,
                  );
                  setOpenShowVincularContas(false);
                }}
              />
            }
            openShowVincularServicos={openShowVincularContas}
            loadingDatagrid={loadingDatagrid.conta}
            rows={rowsContas}
            columnsServices={columnsContas}
          />
        </Box>
      </Wrapper>
    </>
  );
}
