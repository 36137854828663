import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => {
  return {
    btnAbrirConta: {
      marginTop: theme.spacing(2),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
      textAlign: 'center',
      alignItems: 'center',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    inputColor: {
      backgroundColor: theme.palette.background.paper,
    },
    botoesTopo: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    botoesBuscar: {
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    botoesExcelPDF: {
      width: '100%',
      display: 'flex',
      gap: '1rem',
      paddingTop: '1rem',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    buttonProgress: {
      color:
        theme.palette.type === 'dark'
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
      marginRight: '5px',
    },
    btnAbrirConta: {
      marginTop: theme.spacing(2),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
      textAlign: 'center',
      alignItems: 'center',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    rowNomeStyle: {
      lineHeight: '150%',
      height: '50px',
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'break-spaces',
    },
    rowApelidoStyle: {
      lineHeight: '150%',
      height: '50px',
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'break-spaces',
      paddingLeft: 10,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  };
});
